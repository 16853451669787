import React from 'react';
import styled from 'styled-components';
import { FormSection, reduxForm } from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import { dealerConstants } from '../../../../constants/form/formDealerConstants';
import RenderSyncErrors from '../../../common/RenderSyncErrors';
import { scrollContentWrapperToTop } from '../../../../utils/utils';
import ResponsibilityItemsSection from './sections/info/ResponsibilityItemsSection';
import formMessages from '../../../../intl/rbox/formMessages';
import { intlFieldMapping } from '../../../../constants/fieldLabelMapping';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const Form = (props) => {
    const { form, userRights, reqFields, readOnly, handleSubmit } = props;
    return (
        <StyledDiv className="p-4">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={intlFieldMapping}/>
            <FormSection name={dealerConstants.FORM_DEALER_RESPONSIBILITY_INFO_SECTION}>
                <ResponsibilityItemsSection form={form} userRights={userRights} reqFields={reqFields}
                                            readOnly={readOnly}/>
            </FormSection>
            <SubmitButtons form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}
                           onSubmit={handleSubmit} onSubmitFail={scrollContentWrapperToTop}/>
        </StyledDiv>
    )
}

Form.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

export default reduxForm({})(Form);
