import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../../Loader';
import {
    FORM_BUTTON_EDIT,
    FORM_BUTTON_REOPEN,
    RBOX_TICKET_FORM,
    RBOX_TICKET_FORM_VIEW
} from '../../../../constants/formConstants';

import TicketForm from './forms/TicketForm';
import { Redirect, withRouter } from 'react-router-dom';
import { fetchTicket, resetTicket, updateTicket } from '../../../../actions/rbox/actionTicket';
import PropTypes from 'prop-types';
import { initLoadedTicket, transformUpdateTicketForSave } from '../Utils';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import formMessages from '../../../../intl/rbox/formMessages';
import { lockTicket, resetRedirectAfterLockTicket } from '../../../../actions/rbox/actionLockTicket';
import { countryFlow, editTicketRights } from '../../../../constants/ticketRights';
import { moduleRoles } from '../../../../utils/roles';

class ViewTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToEdit: false,
            ticketSubmitted: false,
            storedTicketData: {},
        };
    }

    componentDidMount() {
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchTicket(this.props.match.params.ticketUuid);
        }
    }

    componentWillUnmount() {
        this.props.resetTicket();
        this.props.resetRedirectAfterLockTicket();    }

    handleSubmit = (values) => {
        const { submitBy } = values;

        this.props.lockTicket(this.props.match.params.ticketUuid);
        this.setState({ticketSubmitted: true, storedTicketData: transformUpdateTicketForSave(values)})

        if ([FORM_BUTTON_REOPEN, FORM_BUTTON_EDIT].includes(submitBy)) {
            this.setState({ redirectToEdit: true });
        }
    };

    render() {
        const {
            ticketData,
            userRoles,
        } = this.props;

        let formType = false;
        if (get(ticketData, 'ticket.status', { default: false })) {
            formType = RBOX_TICKET_FORM_VIEW;
        }

        if (!this.props.lockTicketData.isTicketLocking && !this.props.lockTicketData.redirect && this.state.ticketSubmitted && this.state.redirectToEdit) {
            this.props.updateTicket(this.state.storedTicketData);
            return <Redirect to={{
                pathname: `/ticket/edit/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        } else if (!this.props.lockTicketData.isTicketLocking && !this.props.lockTicketData.redirect && this.state.ticketSubmitted) {
            this.props.updateTicket(this.state.storedTicketData);
            this.setState({ticketSubmitted: false, storedTicketData: {}});
        }

        const initializationValue = initLoadedTicket(ticketData);

        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !formType
        ) {
            return <Loader/>;
        }

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TITLE}/>{' - '}{ticketData.ticket.ticketNumber}
                    </>}
                />
                <div className="container-fluid">
                    <div className="col-12">
                        {(formType === RBOX_TICKET_FORM || formType === RBOX_TICKET_FORM_VIEW) &&
                        <TicketForm form={formType}
                                    viewType={'VIEW'}
                                    initialValues={initializationValue}
                                    userRights={editTicketRights[countryFlow(ticketData.ticket.country)][moduleRoles.isEditor(userRoles) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                    enableReinitialize={true}
                                    reqFields={[]}
                                    readOnly
                                    handleSubmit={this.handleSubmit}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userRoles: state.profile.userDetail.roles,
    ticketData: state.ticket,
    lockTicketData: state.lockTicketCheck
});

ViewTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    updateTicket: PropTypes.func.isRequired,
    resetTicket: PropTypes.func.isRequired,
    resetRedirectAfterLockTicket: PropTypes.func.isRequired,
    lockTicket: PropTypes.func.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchTicket,
    clearAlerts,
    resetTicket,
    updateTicket,
    resetRedirectAfterLockTicket,
    lockTicket
})(injectIntl(ViewTicketPage))), ['RBOX_DEALER', 'RBOX_EDITOR', 'RBOX_EDITOR_CREATOR']);

