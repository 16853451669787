/**
 * All available countries in RBOX
 *
 * @type {string[]}
 */
export const availableCountries = ['AT', 'CH', 'FR', 'GB', 'DE', 'IE', 'CY', 'MT', 'BE', 'NL', 'LU', 'CZ', 'IT', 'PL', 'HU', 'SE', 'DK', 'SK', 'ES', 'PT'];

/**
 * Country groups
 *
 * @type {{DE: string, BE: string, CY: string, MT: string, LU: string, GB: string, FR: string, IE: string, NL: string}}
 */
export const countries = {
    //AR: 'AR',
    AT: 'AT',
    //BA: 'BA',
    BE: 'BE',
    CH: 'CH',
    CZ: 'CZ',
    DE: 'DE',
    DK: 'DK',
    //EE: 'EE',
    ES: 'ES',
    //FI: 'FI',
    FR: 'FR',
    //HR: 'HR',
    HU: 'HU',
    IE: 'IE',
    IT: 'IT',
    //LT: 'LT',
    LU: 'LU',
    //LV: 'LV',
    //MA: 'MA',
    //ME: 'ME',
    //MK: 'MK',
    MT: 'MT',
    NL: 'NL',
    //NO: 'NO',
    PL: 'PL',
    PT: 'PT',
    //RO: 'RO',
    //RS: 'RS',
    SE: 'SE',
    //SI: 'SI',
    SK: 'SK',
    GB: 'GB',
    //XK: 'XK'
    CY: 'CY'
};

export const countryToGroups = {
    AT: 'AT',
    CZ: 'CZ',
    CH: 'CH',
    DE: 'DE',
    BE: 'BE',
    NL: 'NL',
    FR: 'FR',
    IE: 'IE',
    GB: 'UK',
    PL: 'PL',
    DK: 'DK',
    SE: 'SE',
    SK: 'SK',
    HU: 'HU',
    ES: 'ES',
    PT: 'PT',
    MT: 'IMP',
    CY: 'IMP',
    LU: 'IMP',
};

/**
 * Countries with DEALER - EDITOR - GLPI flow
 *
 * @type {string[]}
 */
export const degFlowCountries = [
];

/**
 * Countries with DEALER - GLPI flow
 *
 * @type {string[]}
 */
export const dgFlowCountries = [
    'FR'
];

/**
 * Countries with EDITOR - GLPI flow
 *
 * @type {*[]}
 */
export const egFlowCountries = [
    'AT',
    'CH',
    'CZ',
    'GB',
    'DE',
    'CY',
    'MT',
    'IE',
    'IT',
    'NL',
    'BE',
    'LU',
    'PL',
    'HU',
    'SE',
    'SK',
    'DK',
    'ES',
    'PT'
];
