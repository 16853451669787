export const rboxTicketWebsocketCommands = {
    //RBOX Notificaiton List
    RBOX_NOTIFICATION_LIST_RECEIVE: 'getNotificationList',

    RBOX_NOTIFICATION_LIST_PENDING: 'RBOX_NOTIFICATION_LIST_PENDING',
    RBOX_NOTIFICATION_LIST_SEND: 'RBOX_TICKET_NOTIFICATION_LIST_SEND',
    RBOX_NOTIFICATION_LIST_SEND_ERROR: 'RBOX_TICKET_NOTIFICATION_LIST_SEND_ERROR',

    RBOX_NOTIFICATION_LIST_FILTER_SET_CLIENT: 'RBOX_NOTIFICATION_LIST_FILTER_SET_CLIENT',
    RBOX_NOTIFICATION_LIST_FILTER_SET_FULLTEXT: 'RBOX_NOTIFICATION_LIST_FILTER_SET_FULLTEXT',
    RBOX_NOTIFICATION_LIST_SET_FILTERING: 'RBOX_NOTIFICATION_LIST_SET_FILTERING',
    RBOX_NOTIFICATION_LIST_SET_DEFAULT_PROPERTIES: 'RBOX_NOTIFICATION_LIST_SET_DEFAULT_PROPERTIES',

    RBOX_NOTIFICATION_LIST_RESET: 'RBOX_NOTIFICATION_LIST_RESET',

    //RBOX Ticket List
    RBOX_LIST_RECEIVE: 'getList',
    RBOX_HISTORY_RECEIVE: 'getHistory',

    RBOX_TICKET_LIST_PENDING: 'RBOX_TICKET_LIST_PENDING',
    RBOX_TICKET_LIST_SEND: 'RBOX_TICKET_LIST_SEND',
    RBOX_TICKET_LIST_SEND_ERROR: 'RBOX_TICKET_LIST_SEND_ERROR',

    RBOX_TICKET_HISTORY_PENDING: 'RBOX_TICKET_HISTORY_PENDING',
    RBOX_TICKET_HISTORY_SEND: 'RBOX_TICKET_HISTORY_SEND',
    RBOX_TICKET_HISTORY_SEND_ERROR: 'RBOX_TICKET_HISTORY_SEND_ERROR',

    RBOX_TICKET_LIST_FILTER_SET_CLIENT: 'RBOX_TICKET_LIST_FILTER_SET_CLIENT',
    RBOX_TICKET_LIST_FILTER_SET_FULLTEXT: 'RBOX_TICKET_LIST_FILTER_SET_FULLTEXT',
    RBOX_TICKET_LIST_SET_FILTERING: 'RBOX_TICKET_LIST_SET_FILTERING',
    RBOX_TICKET_LIST_SET_DEFAULT_PROPERTIES: 'RBOX_TICKET_LIST_SET_DEFAULT_PROPERTIES',

    //RBOX Ticket
    RBOX_UPDATE_TICKET: 'updateTicket',
    RBOX_CREATE_TICKET: 'createTicket',
    RBOX_GET_TICKET: 'getTicket',

    RBOX_TICKET_LOAD_DATA: 'RBOX_TICKET_LOAD_DATA',
    RBOX_TICKET_LIST_RESET: 'RBOX_TICKET_LIST_RESET',
    RBOX_TICKET_HISTORY_RESET: 'RBOX_TICKET_HISTORY_RESET',

    RBOX_TICKET_DETAIL_PENDING: 'RBOX_TICKET_DETAIL_PENDING',
    RBOX_TICKET_DETAIL_SEND: 'RBOX_TICKET_DETAIL_SEND',
    RBOX_TICKET_DETAIL_SEND_ERROR: 'RBOX_TICKET_DETAIL_SEND_ERROR',
    RBOX_TICKET_SEND: 'RBOX_TICKET_SEND',
    RBOX_TICKET_SEND_ERROR: 'RBOX_TICKET_SEND_ERROR',

    RBOX_TICKET_CREATE_PENDING: 'RBOX_TICKET_CREATE_PENDING',
    RBOX_TICKET_CREATED: 'RBOX_TICKET_CREATED',
    RBOX_TICKET_CREATE_SEND: 'RBOX_TICKET_CREATE_SEND',
    RBOX_TICKET_CREATE_SEND_ERROR: 'RBOX_TICKET_CREATE_SEND_ERROR',
    RBOX_TICKET_CREATE_ERROR: 'RBOX_TICKET_CREATE_ERROR',
    RBOX_TICKET_CREATE_FATAL: 'RBOX_TICKET_CREATE_FATAL',

    RBOX_TICKET_UPDATE_PENDING: 'RBOX_TICKET_UPDATE_PENDING',
    RBOX_TICKET_UPDATED: 'RBOX_TICKET_UPDATED',
    RBOX_TICKET_UPDATE_ERROR: 'RBOX_TICKET_UPDATE_ERROR',
    RBOX_TICKET_UPDATE_SEND: 'RBOX_TICKET_UPDATE_SEND',
    RBOX_TICKET_UPDATE_SEND_ERROR: 'RBOX_TICKET_UPDATE_SEND_ERROR',
    RBOX_TICKET_UPDATE_FATAL: 'RBOX_TICKET_UPDATE_FATAL',

    RBOX_TICKET_RESET: 'RBOX_TICKET_RESET',
    TICKET_SAVE_NEW_ERROR: 'TICKET_SAVE_NEW_ERROR',
    TICKET_SAVE_UPDATE_ERROR: 'TICKET_SAVE_UPDATE_ERROR',

    //Attachment
    RBOX_CREATE_ATTACHMENTS: 'createAttachments',
    RBOX_DOWNLOAD_PRESIGNED_URL: 'attachmentDownloadPreSignedUrl',
    RBOX_GENERATED_PRESIGNED_URLS: 'RBOX_GENERATED_PRESIGNED_URLS',
    RBOX_ATTACHMENTS_SAVED: 'RBOX_TICKET_ATTACHMENTS_SAVED',
    RBOX_ATTACHMENTS_SAVED_ERROR: 'RBOX_TICKET_ATTACHMENTS_SAVED_ERROR',

    RBOX_SET_LOCK: 'setLockTicket',
    RBOX_SET_UNLOCK: 'setUnlockTicket',

    RBOX_TICKET_LOCKED: 'RBOX_TICKET_LOCKED',
    RBOX_TICKET_LOCKED_ERROR: 'RBOX_TICKET_LOCKED_ERROR',
    RBOX_TICKET_LOCKED_FATAL: 'RBOX_TICKET_LOCKED_FATAL',

    RBOX_GET_COUNTRY_SETTINGS: 'getCountrySettings',
    RBOX_SETTINGS_COUNTRY_PENDING: 'RBOX_SETTINGS_COUNTRY_PENDING',
    RBOX_SETTINGS_COUNTRY_SEND: 'RBOX_SETTINGS_COUNTRY_SEND',
    RBOX_SETTINGS_COUNTRY_SEND_ERROR: 'RBOX_SETTINGS_COUNTRY_SEND_ERROR',

    // EXPORT
    RBOX_EXPORT_GET: 'getExport',
    RBOX_EXPORT_CREATE: 'createTicketExport',
    RBOX_EXPORT_DOWNLOAD_URL_GET: 'exportDownloadPreSignedUrl',
    RBOX_EXPORT_SEND: 'RBOX_EXPORT_FETCH_SUCCESS',
    RBOX_EXPORT_SEND_ERROR: 'RBOX_EXPORT_FETCH_ERROR',
    RBOX_EXPORT_STATUS: 'RBOX_EXPORT_STATUS',
    RBOX_EXPORT_ERROR: 'RBOX_EXPORT_ERROR',
    RBOX_EXPORT_DOWNLOAD: 'RBOX_EXPORT_DOWNLOAD',
};
