import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_TICKET_NUMBER: {
        id: 'rbox.list.table.ticketNo',
        defaultMessage: 'Ticket No.'
    },
    TABLE_COUNTRY: {
        id: 'rbox.list.table.country',
        defaultMessage: 'Country'
    },
    TABLE_LAST_EDITOR: {
        id: 'rbox.list.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TABLE_LAST_ASSIGNED_TO: {
        id: 'rbox.list.table.assignedTo',
        defaultMessage: 'Assigned To'
    },
    TABLE_LAST_UPDATE: {
        id: 'rbox.list.table.lastUpdate',
        defaultMessage: 'Last Modify'
    },
    TABLE_IMPACT: {
        id: 'rbox.list.table.impact',
        defaultMessage: 'Impact'
    },
    TABLE_URGENCY: {
        id: 'rbox.list.table.urgency',
        defaultMessage: 'Urgency'
    },
    TABLE_PRIORITY: {
        id: 'rbox.list.table.priority',
        defaultMessage: 'Priority'
    },
    TABLE_ASSIGNEE: {
        id: 'rbox.list.table.assignee',
        defaultMessage: 'Assignee'
    },
    TABLE_ASSOCIATED_ITEMS: {
        id: 'rbox.list.table.associatedItems',
        defaultMessage: 'Associated Items'
    },
    TABLE_CREATED_BY: {
        id: 'rbox.list.table.createdBy',
        defaultMessage: 'Created by'
    },
    TABLE_CREATION_TIME: {
        id: 'rbox.list.table.rboxCreationTime',
        defaultMessage: 'Creation Time'
    },
    TITLE: {
        id: 'rbox.list.title',
        defaultMessage: 'Ticket list'
    },
    TITLE_HISTORY: {
        id: 'rbox.history.title',
        defaultMessage: 'Ticket history'
    },
    NOTIFICATION_TITLE: {
        id: 'rbox.list.notification.title',
        defaultMessage: 'Notification list'
    },
    VISIBLE_COLUMNS: {
        id: 'rbox.list.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },
    TABLE_STATUS: {
        id: 'rbox.list.table.status',
        defaultMessage: 'Status'
    },
    TABLE_SUBJECT: {
        id: 'rbox.list.table.subject',
        defaultMessage: 'Subject'
    },
    ALL: {
        id: 'common.rbox.status.all',
        defaultMessage: 'All'
    },
});

export default messages;
