import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import get from 'get-value';
import PropTypes from 'prop-types';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { clearAlerts } from '../../../../actions/alertsActions';
import ListTable from './NotificationTable';
import notificationListMessages from '../../../../intl/rbox/listMessages';
import Loader from '../../../Loader';
import PageHeader from '../../../common/PageHeader';
import DatePickerTableComponent from '../../../common/datePicker/DatePickerTableComponent';
import { getAllFormStates, getImpact, getPriority, getUrgency } from '../../../../constants/Utils';
import countryGroupMessages from '../../../../intl/common/countryGroupMessages';
import formStatesMessages from '../../../../intl/common/formStatesMessages';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import { getPreferences, updateUserNotificationListPreference } from '../../../../actions/rbox/actionPreferences';
import { getUserRole } from '../Utils';
import formTicketValuesMessages from '../../../../intl/common/formTicketValuesMessages';
import { moduleRoles } from '../../../../utils/roles';
import Creatable from 'react-select/creatable';

const StyledPicky = styled(Picky)`
    button:first-child{
        border-radius: 0.3em;
    };
`;

const styles = {
    multiValue: (base, state) => {
        return { ...base };
    },
    multiValueLabel: (base, state) => {
        return { ...base, paddingRight: 6 };
    },
    multiValueRemove: (base, state) => {
        return { ...base, display: 'none' };
    },
};

class NotificationListPage extends Component {
    constructor(props) {
        super(props);
        const { intl } = props;
        this.state = {
            ratchet: false,
            ticketId: '',
            columnLibrary: {
                EDITOR: [
                    {
                        id: 'country',
                        accessor: 'country',
                        message: notificationListMessages.TABLE_COUNTRY,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(notificationListMessages.ALL)}</option>
                                {moduleRoles.availableCountriesEditor(this.props.userRoles).map((key, index) =>
                                    <option key={index} value={key}>
                                        {countryGroupMessages[key] ? intl.formatMessage(countryGroupMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{countryGroupMessages[e.value] ? intl.formatMessage(countryGroupMessages[e.value]) : e.value}</div>,
                    },
                    {
                        id: 'creatorName',
                        accessor: 'creatorName',
                        message: notificationListMessages.TABLE_CREATED_BY,
                    },
                    {
                        id: 'associatedItems',
                        accessor: row =>
                            <Creatable
                                value={this.formatAssociatedItemsLabel(row.associatedItems || [])}
                                components={{DropdownIndicator: null}}
                                isMulti
                                isDisabled
                                isSearchable={false}
                                isClearable={false}
                                placeholder={false}
                                styles={styles}
                                name="colors"
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />,
                        message: notificationListMessages.TABLE_ASSOCIATED_ITEMS,
                        sortable: false,
                        width: 260
                    },
                    {
                        id: 'subject',
                        accessor: 'subject',
                        message: notificationListMessages.TABLE_SUBJECT,
                    },
                    {
                        id: 'openingDateTime',
                        accessor: 'openingDateTime',
                        message: notificationListMessages.TABLE_CREATION_TIME,
                        Filter: ({ filter, onChange }) => {
                            let value = filter && filter.value;
                            if (typeof value === 'string') {
                                value = moment(value).toDate();
                            }
                            return <DatePickerTableComponent
                                value={value || undefined}
                                onChange={onChange}
                                filter={filter}/>;
                        },
                        Cell: e => <><FormattedDate value={new Date(e.value)}/> <FormattedTime
                            value={new Date(e.value)}/></>,
                    },
                    {
                        id: 'lastUpdate',
                        accessor: 'lastUpdate',
                        message: notificationListMessages.TABLE_LAST_UPDATE,
                        Filter: ({ filter, onChange }) => {
                            let value = filter && filter.value;
                            if (typeof value === 'string') {
                                value = moment(value).toDate();
                            }
                            return <DatePickerTableComponent
                                value={value || undefined}
                                onChange={onChange}
                                filter={filter}/>;
                        },
                        Cell: e => <><FormattedDate value={new Date(e.value)}/> <FormattedTime
                            value={new Date(e.value)}/></>,
                    },
                    {
                        id: 'impact',
                        accessor: 'impact',
                        message: notificationListMessages.TABLE_IMPACT,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(notificationListMessages.ALL)}</option>
                                {getImpact.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formTicketValuesMessages[key] ? intl.formatMessage(formTicketValuesMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formTicketValuesMessages[e.value] ? intl.formatMessage(formTicketValuesMessages[e.value]) : e.value}</div>,
                    },
                    {
                        id: 'urgency',
                        accessor: 'urgency',
                        message: notificationListMessages.TABLE_URGENCY,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(notificationListMessages.ALL)}</option>
                                {getUrgency.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formTicketValuesMessages[key] ? intl.formatMessage(formTicketValuesMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formTicketValuesMessages[e.value] ? intl.formatMessage(formTicketValuesMessages[e.value]) : e.value}</div>,
                    },
                    {
                        id: 'priority',
                        accessor: 'priority',
                        message: notificationListMessages.TABLE_PRIORITY,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(notificationListMessages.ALL)}</option>
                                {getPriority.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formTicketValuesMessages[key] ? intl.formatMessage(formTicketValuesMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formTicketValuesMessages[e.value] ? intl.formatMessage(formTicketValuesMessages[e.value]) : e.value}</div>,
                    },
                    {
                        id: 'status',
                        accessor: 'status',
                        message: notificationListMessages.TABLE_STATUS,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(notificationListMessages.ALL)}</option>
                                {getAllFormStates.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                    },
                ],
                DEALER: [
                    {
                        id: 'associatedItems',
                        accessor: row =>
                            <Creatable
                                value={this.formatAssociatedItemsLabel(row.associatedItems || [])}
                                components={{DropdownIndicator: null}}
                                isMulti
                                isDisabled
                                isSearchable={false}
                                isClearable={false}
                                placeholder={false}
                                styles={styles}
                                name="colors"
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />,
                        message: notificationListMessages.TABLE_ASSOCIATED_ITEMS,
                        sortable: false,
                        width: 260
                    },
                    {
                        id: 'subject',
                        accessor: 'subject',
                        message: notificationListMessages.TABLE_SUBJECT,
                    },
                    {
                        id: 'openingDateTime',
                        accessor: 'openingDateTime',
                        message: notificationListMessages.TABLE_CREATION_TIME,
                        Filter: ({ filter, onChange }) => {
                            let value = filter && filter.value;
                            if (typeof value === 'string') {
                                value = moment(value).toDate();
                            }
                            return <DatePickerTableComponent
                                value={value || undefined}
                                onChange={onChange}
                                filter={filter}/>;
                        },
                        Cell: e => <><FormattedDate value={new Date(e.value)}/> <FormattedTime
                            value={new Date(e.value)}/></>,
                    },
                    {
                        id: 'impact',
                        accessor: 'impact',
                        message: notificationListMessages.TABLE_IMPACT,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(notificationListMessages.ALL)}</option>
                                {getImpact.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formTicketValuesMessages[key] ? intl.formatMessage(formTicketValuesMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formTicketValuesMessages[e.value] ? intl.formatMessage(formTicketValuesMessages[e.value]) : e.value}</div>,
                    },
                    {
                        id: 'urgency',
                        accessor: 'urgency',
                        message: notificationListMessages.TABLE_URGENCY,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(notificationListMessages.ALL)}</option>
                                {getUrgency.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formTicketValuesMessages[key] ? intl.formatMessage(formTicketValuesMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formTicketValuesMessages[e.value] ? intl.formatMessage(formTicketValuesMessages[e.value]) : e.value}</div>,
                    },
                    {
                        id: 'priority',
                        accessor: 'priority',
                        message: notificationListMessages.TABLE_PRIORITY,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(notificationListMessages.ALL)}</option>
                                {getPriority.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formTicketValuesMessages[key] ? intl.formatMessage(formTicketValuesMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formTicketValuesMessages[e.value] ? intl.formatMessage(formTicketValuesMessages[e.value]) : e.value}</div>,
                    },
                    {
                        id: 'status',
                        accessor: 'status',
                        message: notificationListMessages.TABLE_STATUS,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(notificationListMessages.ALL)}</option>
                                {getAllFormStates.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                    },
                ]
            }
        };
    }

    formatAssociatedItemsLabel = (values = [], intl) => {
        return values.map(value => ({ label: value.name, value: value.name }));
    };

    componentDidMount() {
        this.props.getPreferences(this.props.loggedUser.ipn);
    }

    handleEditClick = ({ id }) => {
        this.setState({ ratchet: true, ticketId: id });
    };

    selectMultipleOption = (value) => {
        this.props.updateUserNotificationListPreference(this.props.loggedUser.ipn,
            this.state.columnLibrary[getUserRole(this.props.loggedUser.roles)].filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    render() {
        const { intl, preferences } = this.props;
        const { columnLibrary } = this.state;

        if (preferences.isLoading) {
            return <Loader/>
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...notificationListMessages.NOTIFICATION_TITLE}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span
                            className="font-weight-bold"><FormattedMessage {...notificationListMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columnLibrary[getUserRole(this.props.loggedUser.roles)].filter(item => !get(preferences, 'menuNotificationList', { default: [] }).includes(item.id)).map(col => {
                                return { id: col.id, message: intl.formatMessage(col.message) }
                            })}
                            options={columnLibrary[getUserRole(this.props.loggedUser.roles)].map(col => {
                                return { id: col.id, message: intl.formatMessage(col.message) }
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <ListTable
                    columns={columnLibrary[getUserRole(this.props.loggedUser.roles)].filter(item => !get(preferences, 'menuNotificationList', { default: [] }).includes(item.id))}
                    handleEditClick={this.handleEditClick}
                    />
            </div>
        );
    }
}

NotificationListPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    getPreferences: PropTypes.func.isRequired,
    updateUserNotificationListPreference: PropTypes.func.isRequired,
    domain: PropTypes.string.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    preferences: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    preferences: state.preferences,
    userRoles: state.profile.userDetail.roles,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateUserNotificationListPreference,
    getPreferences,
})(injectIntl(NotificationListPage))), ['RBOX_EDITOR', 'RBOX_DEALER']);

