import {
    RBOX_FETCH_PREFERENCES_PENDING,
    RBOX_UPDATE_USER_LIST_PREFERENCE,
    RBOX_UPDATE_USER_NOTIFICATION_LIST_PREFERENCE,
    RBOX_USER_NOTIFICATION_PREFERENCE_FETCH,
    RBOX_USER_NOTIFICATION_PREFERENCE_UPDATE,
} from '../actions/rbox/actionPreferences';
import { rboxWebsocketCommands } from '../constants/rboxWebsocketCommands';
import get from 'get-value';

export const preferences = (state = {
    isLoading: true,
    menuList: [],
    menuNotificationList: [],
    statuses: [],
    actions: []
}, action) => {
    switch (action.type) {
        case RBOX_UPDATE_USER_LIST_PREFERENCE: {
            return Object.assign({}, state, { menuList: action.payload.preferences.hiddenColumns });
        }
        case RBOX_UPDATE_USER_NOTIFICATION_LIST_PREFERENCE: {
            return Object.assign({}, state, { menuNotificationList: action.payload.preferences.hiddenColumns });
        }
        case rboxWebsocketCommands.RBOX_PREFERENCES_SEND: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                ...action.payload.preferences,
                isLoading: false,
                correlationId: action.payload.correlationId
            });
        }
        case rboxWebsocketCommands.RBOX_PREFERENCES_SEND_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, correlationId: action.payload.correlationId });
        }
        case rboxWebsocketCommands.RBOX_PREFERENCES_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, correlationId: action.payload.correlationId });
        }
        case RBOX_FETCH_PREFERENCES_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_USER_NOTIFICATION_PREFERENCE_FETCH: {
            return Object.assign({}, state, { isLoading: true, statuses: [], actions: [] });
        }
        case RBOX_USER_NOTIFICATION_PREFERENCE_UPDATE: {
            return Object.assign({}, state, { isLoading: true, statuses: [], actions: [] })
        }
        case rboxWebsocketCommands.RBOX_NOTIFICATIONS_PREFERENCES_SEND: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                statuses: get(action, 'payload.statuses', { default: [] }),
                actions: get(action, 'payload.actions', { default: [] }),
                correlationId
            })
        }
        case rboxWebsocketCommands.RBOX_NOTIFICATIONS_PREFERENCES_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                statuses: [],
                actions: [],
                correlationId
            });
        }
        default:
            return state;
    }
};
