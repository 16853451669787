import React from 'react';
import { getFormInitialValues, reduxForm } from 'redux-form'
import {FormattedMessage, injectIntl} from 'react-intl';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
    BUTTON_LABEL_CLOSE,
    BUTTON_LABEL_FORWARD_TO_DEALER,
    BUTTON_LABEL_FORWARD_TO_EDITOR,
    BUTTON_LABEL_FORWARD_TO_GLPI,
    BUTTON_LABEL_READ_BY_EDITOR,
    BUTTON_LABEL_REOPEN,
    BUTTON_LABEL_SAVE,
    BUTTON_LABEL_SOLVE, BUTTON_LABEL_TAKE_BACK,
    FORM_BUTTON_CLOSE,
    FORM_BUTTON_FORWARD_TO_DEALER,
    FORM_BUTTON_FORWARD_TO_EDITOR,
    FORM_BUTTON_FORWARD_TO_GLPI,
    FORM_BUTTON_READ_BY_EDITOR,
    FORM_BUTTON_REOPEN,
    FORM_BUTTON_SAVE,
    FORM_BUTTON_SOLVE, FORM_SELECT_FORWARD_TO_EDITOR
} from '../../../../../../constants/formConstants';
import { PrimaryButton } from '../../../../../common/Button';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import get from 'get-value';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import { throttle } from 'throttle-debounce';

import {
    RBOX_TICKET_STATUS_CLOSED,
    RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
    RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
    RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
    RBOX_TICKET_STATUS_READ_BY_EDITOR,
    RBOX_TICKET_STATUS_REOPENED,
    RBOX_TICKET_STATUS_SOLVED
} from '../../../../../../constants/form/statusConstants';
import { moduleRoles } from '../../../../../../utils/roles';
import {StyledDiv} from '../../../../../common/StyledComponents';
import teamRoleMessages from '../../../../../../intl/admin/teamRoleMessages';


const SubmitButtons = (props) => {
    const { change, handleSubmit, submitting, initialValues, readOnly, userRights, availableStatus, dirty, roles, intl: {formatMessage}, availableTeam } = props;

    const renderBackButton = () => {
        return (
            <PrimaryButton onClick={(e) => {
                e.preventDefault();
                props.history.goBack();
            }} className="btn ml-2 float-right">
                <FormattedMessage {...buttonMessages.BACK}/>
            </PrimaryButton>
        )
    };

    const renderButton = (button, message, changeTo, assignTeam, teamSelector) => {
        return (
            <PrimaryButton onClick={throttle(1000, async (e) => {
                e.preventDefault();
                //Unfortunately haven't found any more elegant way to omit validation on MARK_AS_READ button click, this value is checked in REQUIRED validation
                if (button === FORM_BUTTON_READ_BY_EDITOR) {
                    change('omitValidation', true);
                    await (interval => new Promise(resolve => setTimeout(resolve, interval)))(75);
                }
                if(teamSelector) {
                    assignTeam = document.getElementById(teamSelector).value
                }
                if(assignTeam){
                    handleSubmit(values =>
                        props.onSubmit({
                            newValues: {...values, ticketSection: {...values.ticketSection, status: changeTo, assignedTo: assignTeam}},
                            oldValues: initialValues,
                            submitBy: button,
                        }))()
                } else {
                    handleSubmit(values =>
                        props.onSubmit({
                            newValues: {...values, ticketSection: {...values.ticketSection, status: changeTo}},
                            oldValues: initialValues,
                            submitBy: button,
                        }))()
                }
            })
            } className="btn ml-2 float-right" disabled={submitting || (!dirty && button === FORM_BUTTON_SAVE)}>
                <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
            </PrimaryButton>
        )
    };



    const renderSelectButton = (button, message, changeTo, availableOptions, select) => {
        return (
            <>
                <div className="input-group-prepend">
                    {renderButton(button, message, changeTo, null, select)}
                </div>
                <select className="custom-select" id={select}>
                    {availableOptions.map((team) => <option value={team}>{teamRoleMessages[team] ? formatMessage(teamRoleMessages[team]) : team}</option>)}
                </select>
            </>
        )
    };

    if (readOnly) {
        return (
            <div className="d-flex justify-content-end mt-3">
                <form className="form-group">
                    {renderBackButton()}
                    {userRights.canReopen && initialValues.ticketSection.status === RBOX_TICKET_STATUS_CLOSED && renderButton(FORM_BUTTON_REOPEN, BUTTON_LABEL_REOPEN, RBOX_TICKET_STATUS_REOPENED)}
                </form>
            </div>
        )
    }

    return (
        <StyledDiv className="d-flex justify-content-end mt-3 ">
            <form className="">
                <div className="input-group">
                {get(initialValues, 'ticketSection.uuid') && renderBackButton()}
                {availableStatus && (availableStatus.includes('READ_BY_EDITOR') && renderButton(FORM_BUTTON_READ_BY_EDITOR, BUTTON_LABEL_READ_BY_EDITOR, RBOX_TICKET_STATUS_READ_BY_EDITOR, (moduleRoles.isTeamEditor(roles) ? 'CENTRAL_TEAM' : null)))}
                {availableStatus && (availableStatus.includes('FORWARDED_TO_DEALER') && renderButton(FORM_BUTTON_FORWARD_TO_DEALER, BUTTON_LABEL_FORWARD_TO_DEALER, RBOX_TICKET_STATUS_FORWARDED_TO_DEALER))}
                {/*{availableStatus && (availableStatus.includes('FORWARDED_TO_EDITOR') && moduleRoles.isEditor(roles) && !moduleRoles.isTeamEditor(roles) && renderButton(FORM_BUTTON_FORWARD_TO_EDITOR, BUTTON_LABEL_TAKE_BACK, RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR))}*/}
                {availableStatus && (availableStatus.includes('FORWARDED_TO_EDITOR') && (moduleRoles.isDealer(roles) || (moduleRoles.isEditor(roles))) && renderSelectButton(FORM_BUTTON_FORWARD_TO_EDITOR, BUTTON_LABEL_FORWARD_TO_EDITOR, RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR, availableTeam, FORM_SELECT_FORWARD_TO_EDITOR))}
                {availableStatus && (availableStatus.includes('FORWARDED_TO_GLPI') && renderButton(FORM_BUTTON_FORWARD_TO_GLPI, BUTTON_LABEL_FORWARD_TO_GLPI, RBOX_TICKET_STATUS_FORWARDED_TO_GLPI))}
                {availableStatus && (availableStatus.includes('CLOSED') &&renderButton(FORM_BUTTON_CLOSE, BUTTON_LABEL_CLOSE, RBOX_TICKET_STATUS_CLOSED))}
                {availableStatus && (availableStatus.includes('SOLVED') &&renderButton(FORM_BUTTON_SOLVE, BUTTON_LABEL_SOLVE, RBOX_TICKET_STATUS_SOLVED))}
                {userRights.canSave && renderButton(FORM_BUTTON_SAVE, BUTTON_LABEL_SAVE, get(initialValues, 'ticketSection.status'))}
                </div>
            </form>
        </StyledDiv>
    )
};

SubmitButtons.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    readOnly: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    userRights: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
};

const mapStateToProps = (state, initialProps) => ({
    roles: state.profile.userDetail.roles,
    initialValues: getFormInitialValues(initialProps.form)(state),
});

export default reduxForm({})(withRouter(connect(mapStateToProps, {})(injectIntl(SubmitButtons))));
