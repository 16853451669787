import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    clearDealerRightsManagementPageData,
    clearEditorActionRightsManagementPageData,
    clearEditorRightsManagementPageData, clearEditorTeamRolesManagementPageData,
    fetchDealerRightsManagementPageData,
    fetchEditorActionRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    fetchEditorTeamRolesManagementPageData,
    resetManageUser,
    saveEditorRightsManagementPageData,
    setEditorActionChanged,
    setInitialEditorActionOptions,
    setManagedUser,
    setSelectedEditorActionOptions
} from '../../../actions/admin/actionAdminRightsManagement';
import SelectUser from './SelectUser';
import Loader from '../../Loader';
import { editorActionRoles } from '../../../constants/Utils';
import { clearAlerts } from '../../../actions/alertsActions';
import { handleFetchUsersError } from '../../../actions/actionRole';
import { handleUserSearch } from '../../../utils/userUtils';
import commonMessages from '../../../intl/common/commonMessages';
import PageHeader from '../../common/PageHeader';
import errorMessages from '../../../intl/common/errorMessages';
import SelectEditorActions from './SelectEditorActions';
import adminMessages from '../../../intl/admin/adminMessages';

const Panel = styled.h1`
  font-size: 1.5em;
  text-align: center;
`;

class EditorActionRightsManagementPage extends Component {
    componentDidMount() {
        this.props.clearEditorRightsManagementPageData();
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setEditorActionChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleActionSelectionChange = newOptions => {
        this.setSelectedOptionsChanged(newOptions);
        this.props.setSelectedEditorActionOptions(newOptions);
    };

    handleAllActionsClick = () => {
        const selectedActions = {};

        Object.keys(this.props.editorData || {}).forEach(
            groupIso => selectedActions[groupIso] = true
        );

        const selectedOptions = this.setEditorDataAsActionOptions(selectedActions);
        this.setSelectedOptionsChanged(selectedOptions);
    };

    handleSaveClick = () => {
        const selectedActions = {};
        Object.keys(editorActionRoles).forEach(
            groupKey => selectedActions[editorActionRoles[groupKey]] = false
        );
        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({ value }) => {
                    selectedActions[value] = true;
                }
            )
        }
        this.props.saveEditorRightsManagementPageData(this.props.managedUser, this.props.domain, null, selectedActions);
    };

    handleCancelClick = () => {
        this.props.setSelectedEditorActionOptions(cloneDeep(this.props.initialOptions));
        this.props.setEditorActionChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearEditorRightsManagementPageData();
        this.props.clearEditorActionRightsManagementPageData();
        this.props.clearDealerRightsManagementPageData();
        this.props.clearEditorTeamRolesManagementPageData();
        this.props.fetchEditorActionRightsManagementPageData(user, this.props.domain);
        this.props.fetchEditorRightsManagementPageData(user, this.props.domain);
        this.props.fetchDealerRightsManagementPageData(user, this.props.domain);
        this.props.fetchEditorTeamRolesManagementPageData(user, this.props.domain);
    };

    setEditorDataAsActionOptions = editorData => {
        const result = Object.keys(editorData || {}).map(groupIso => ({ value: groupIso }));
        this.props.setSelectedEditorActionOptions(cloneDeep(result));
        return result;
    };

    setSelectedOptionsChanged = newOptions => this.props.setEditorActionChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                //.sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(newOptions
            //.sort((option1, option2) => option1.value.localeCompare(option2.value))
        )
    );

    render() {
        const { isLoading, managedUser, editorData, editorTeamData, isDealer, domain } = this.props;
        if (isLoading) {
            return <Loader/>;
        }

        return (
            <div>
                <PageHeader title={
                    <FormattedMessage {...adminMessages.EDITOR_ACTION}
                                      values={{
                                          domain: <FormattedMessage {...commonMessages[domain]}/>
                                      }}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {isDealer &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.DEALER_ROLE_SET}/>
                </Panel>
                }
                {Object.values(editorTeamData).includes(true) &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.EDITOR_HAS_TEAM}/>
                </Panel>
                }
                {Object.keys(editorData).length > 0 && managedUser && managedUser.ipn && !isDealer && !Object.values(editorTeamData).includes(true) &&
                    <SelectEditorActions handleActionSelectionChange={this.handleActionSelectionChange}
                                        handleAllActionsClick={this.handleAllActionsClick}
                                        handleSaveClick={this.handleSaveClick}
                                        handleCancelClick={this.handleCancelClick}/>
                }
            </div>
        );
    }
}

EditorActionRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    setSelectedEditorActionOptions: PropTypes.func.isRequired,
    editorData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    saveEditorRightsManagementPageData: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    setEditorActionChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearEditorRightsManagementPageData: PropTypes.func.isRequired,
    clearEditorActionRightsManagementPageData: PropTypes.func.isRequired,
    clearDealerRightsManagementPageData: PropTypes.func.isRequired,
    fetchEditorActionRightsManagementPageData: PropTypes.func.isRequired,
    fetchEditorRightsManagementPageData: PropTypes.func.isRequired,
    fetchDealerRightsManagementPageData: PropTypes.func.isRequired,
    setInitialEditorActionOptions: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    isDealer: PropTypes.bool.isRequired,
    fetchEditorTeamRolesManagementPageData: PropTypes.func.isRequired,
    clearEditorTeamRolesManagementPageData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    editorData: state.adminRightsManagement.editorActionData,
    editorTeamData: state.adminRightsManagement.editorTeamRoleData,
    isDealer: Object.values(state.adminRightsManagement.dealerData.groupPermission || {}).some(value => value),
    initialOptions: state.adminRightsManagement.initialEditorActionOptions,
    selectedOptions: state.adminRightsManagement.selectedEditorActionOptions
});

export default connect(mapStateToProps, {
    setManagedUser,
    fetchEditorActionRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    setSelectedEditorActionOptions,
    setEditorActionChanged,
    setInitialEditorActionOptions,
    saveEditorRightsManagementPageData,
    clearEditorRightsManagementPageData,
    clearDealerRightsManagementPageData,
    clearEditorActionRightsManagementPageData,
    clearAlerts,
    handleFetchUsersError,
    resetManageUser,
    fetchEditorTeamRolesManagementPageData,
    clearEditorTeamRolesManagementPageData,
})(injectIntl(EditorActionRightsManagementPage));
