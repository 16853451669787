import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {BoldPrimaryButton, SecondaryButton} from '../../common/Button';
import Select from 'react-select';

import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import teamRoleMessages from '../../../intl/admin/teamRoleMessages';
import authManagementMessages from '../../../intl/admin/authManagementMessages';
import buttonMessages from "../../../intl/common/buttonMessages";

const Label = styled.label`
    font-weight: bold;
`;


const SelectEditorTeamRole = props => {
    const {
        editorData, intl: {formatMessage}, selectedOptions, actionsChanged, handleActionSelectionChange, handleSaveClick, handleCancelClick
    } = props;

    const options = Object.keys(editorData || {})
        .map(action => ({
            value: action,
            label: teamRoleMessages[action] ? formatMessage(teamRoleMessages[action]) : action
        }));

    let formattedInitialOptions = (selectedOptions && selectedOptions[0]) || actionsChanged ? (selectedOptions && selectedOptions[0] ? {
        value: selectedOptions[0].value,
        label: teamRoleMessages[selectedOptions[0].value] ? formatMessage(teamRoleMessages[selectedOptions[0].value]) : selectedOptions[0].value
    } : undefined) : null;

    return (
        <div className="container-fluid">
            <div className="row mb-sm-2">
                <div className="col-sm-12">
                    <Label>
                        <FormattedMessage {...authManagementMessages.ADMIN_SELECT_TEAM}/>
                    </Label>
                </div>
            </div>
            <div className="row mb-sm-3">
                <div className="col">
                    <Select isSearchable
                            value={formattedInitialOptions}
                            options={options}
                            placeholder={formatMessage(authManagementMessages.ADMIN_PLACEHOLDER_SELECT_TEAM)}
                            onChange={handleActionSelectionChange}
                            isClearable={true}/>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <SecondaryButton type="button"
                                     disabled={!actionsChanged}
                                     className="btn float-right"
                                     onClick={handleCancelClick}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </SecondaryButton>
                </div>
                <div className="col-sm-6">
                    <BoldPrimaryButton type="button" className="btn float-left"
                                       disabled={!actionsChanged}
                                       onClick={handleSaveClick}>
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                </div>
            </div>
        </div>
    );
};

SelectEditorTeamRole.propTypes = {
    editorData: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    actionsChanged: PropTypes.bool.isRequired,
    handleActionSelectionChange: PropTypes.func.isRequired,
    handleSaveClick: PropTypes.func.isRequired,
    handleCancelClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    editorData: state.adminRightsManagement.editorTeamRoleData,
    selectedOptions: state.adminRightsManagement.selectedEditorTeamRoleOptions,
    actionsChanged: state.adminRightsManagement.editorTeamRoleChanged,
});

export default connect(mapStateToProps, {})(injectIntl(SelectEditorTeamRole))
