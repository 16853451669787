import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import tableMessages from '../../../intl/common/tableMessages';
import { StyledReactTable } from '../../../theme/styledTable'
import TableHeader from './TableHeader';
import buttonMessages from '../../../intl/common/buttonMessages';
import Pagination from './Pagination';
import { countryFlow, editListButtonVisibility } from '../../../constants/ticketRights';

const ButtonDiv = styled.div`
  display: table-cell;
`;

class TicketTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
        };
        this.setActivePage = this.setActivePage.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        const {defaultSorting = []} = this.props;
        this.setState({
            sorted: defaultSorting
        });
    }

    setActivePage(page) {
        this.setState({activePage: page})
    };

    hasData = () => !this.props.isLoading && this.props.data && this.props.data.length !== 0;

    fetchData = (state) => {
        this.props.handleFetchData(state);
        const page = state.page === 0 ? 1 : state.page;
        this.setState({activePage: page});
    };

    render() {
        const {
            intl, isLoading = false, withPagination = true, columns, data, toolButtons = [], isNextPage, isNextFromPivot,
            handleOnFilteredChange, pages, handlerOnSortChange, allowTooltipsInHeader = false, defaultPageSize = 10
        } = this.props;
        const decoratedColumns = columns.map(
            column =>
                Object.assign({}, column,
                    {
                        Header: () => <TableHeader id={column.id} message={column.message}
                                                   filterable={column.filterable}
                                                   sorted={this.state.sorted ? this.state.sorted : []}/>,

                        filterMethod: (filter, row) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined ?
                                String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                                : true;
                        }
                    })
        );

        if (toolButtons.length > 0) {
            decoratedColumns.unshift({
                id: 'tools',
                accessor: 'tools',
                sortable: false,
                filterable: false,
                maxWidth: toolButtons.length * 41,
                Cell: row => {
                    return <React.Fragment>
                        {
                            toolButtons.map((toolButton, index) => {
                                let toolButtonObject = toolButton;
                                if (toolButtonObject instanceof Function) {
                                    toolButtonObject = toolButtonObject(row);
                                }

                                let visibility = toolButtonObject.visibility;
                                if (toolButtonObject.buttonType === 'edit') {
                                    if (toolButtonObject.userRole !== 'DEALER'
                                        && toolButtonObject.userRole
                                        !== 'EDITOR') {
                                        // if(row.original.assignedTo === toolButtonObject.userRole[0].group) {
                                        visibility = editListButtonVisibility[countryFlow(
                                            row.original.ticketBase
                                                ? row.original.ticketBase.country
                                                : (row.original.ticket
                                                    ? row.original.ticket.country
                                                    : row.original.country))][toolButtonObject.userRole[0].group];
                                        // }
                                    } else {
                                        visibility = editListButtonVisibility[countryFlow(
                                            row.original.ticketBase
                                                ? row.original.ticketBase.country
                                                : (row.original.ticket
                                                    ? row.original.ticket.country
                                                    : row.original.country))][toolButtonObject.userRole];
                                    }
                                }

                                if (visibility && !visibility.includes(
                                    row.original.ticketBase
                                        ? row.original.ticketBase.status
                                        : (row.original.ticket
                                            ? row.original.ticket.status
                                            : row.original.status))) {
                                    return <div key={index}/>;
                                }
                                if (toolButton.path) {
                                    return <Route key={`${row.original.id}route${index}`}
                                                  render={({history, history: {location: {pathname}}}) => (
                                                      <ButtonDiv key={`${row.original.id}button${index}`}
                                                                 onClick={() => history.push(
                                                                     `${pathname}/${toolButton.path.base}/${row.original[toolButton.path.rowField]}`
                                                                 )}>
                                                          {toolButtonObject.component}
                                                      </ButtonDiv>
                                                  )}/>;
                                } else {
                                    return <ButtonDiv key={`${row.original.id}button${index}`}
                                                      onClick={() => toolButtonObject.handleClick(row.original, toolButtonObject.onClickCallback)}>
                                        {toolButtonObject.component}
                                    </ButtonDiv>;
                                }
                            })
                        }
                    </React.Fragment>;
                }
            });
        }

        return (
            <StyledReactTable
                columns={decoratedColumns}
                manual
                loading={isLoading}
                data={data}
                pages={pages}
                filterable
                showPagination={withPagination && this.hasData()}
                minRows={this.hasData() ? 1 : 4}
                firstText={<FormattedMessage {...buttonMessages.FIRST}/>}
                previousText={<FormattedMessage {...buttonMessages.PREVIOUS}/>}
                nextText={<FormattedMessage {...buttonMessages.NEXT}/>}
                lastText={<FormattedMessage {...buttonMessages.LAST}/>}
                noDataText={<FormattedMessage {...tableMessages.TABLE_NO_DATA}/>}
                pageText={<FormattedMessage {...tableMessages.TABLE_PAGE}/>}
                ofText={<FormattedMessage {...tableMessages.TABLE_OF}/>}
                rowsText={intl.formatMessage(tableMessages.TABLE_ROWS)}
                className="-highlight"
                onFetchData={(state, instance) => {this.fetchData(state, instance);}}
                onSortedChange={handlerOnSortChange === undefined ? (sorted => this.setState({sorted})) : handlerOnSortChange}
                onFilteredChange={handleOnFilteredChange === undefined ? () => null : handleOnFilteredChange}
                defaultFiltered={this.props.defaultFiltered === undefined ? [] : this.props.defaultFiltered}
                defaultSorted={this.props.defaultSorting === undefined ? [] : this.props.defaultSorting}
                defaultPageSize={defaultPageSize}
                getTheadFilterThProps={allowTooltipsInHeader ? () => ({style: {overflow: "inherit"}}) : () => ({})}
                PaginationComponent={Pagination}
                getPaginationProps={(state, rowInfo, column, instance) => {
                    return {
                        isNextPage,
                        isNextFromPivot,
                        activePage: this.state.activePage > pages ? pages : this.state.activePage,
                    }
                }}
            />
        );
    }
}

TicketTable.propTypes = {
    intl: PropTypes.any.isRequired,
    isLoading: PropTypes.bool,
    withPagination: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    handleFetchData: PropTypes.func,
    toolButtons: PropTypes.array,
    defaultSorting: PropTypes.array,
    allowTooltipsInHeader: PropTypes.bool,
};

export default injectIntl(TicketTable);
