import {rboxTicketWebsocketCommands} from '../../constants/rboxTicketWebsocketCommands';

export const createExport = (exportFilter) => (dispatch) => {
    dispatch({
        type: rboxTicketWebsocketCommands.RBOX_EXPORT_CREATE,
        rboxWebsocket: true,
        command: rboxTicketWebsocketCommands.RBOX_EXPORT_CREATE,
        payload: {exportFilter},
    });
};

export const fetchExport = () => (dispatch) => {
    dispatch({
        type: rboxTicketWebsocketCommands.RBOX_EXPORT_GET,
        rboxWebsocket: true,
        command: rboxTicketWebsocketCommands.RBOX_EXPORT_GET,
        payload: {},
    });
};

export const downloadExport = (exportRecord) => (dispatch) => {
    dispatch({
        type: rboxTicketWebsocketCommands.RBOX_EXPORT_DOWNLOAD_URL_GET,
        rboxWebsocket: true,
        command: rboxTicketWebsocketCommands.RBOX_EXPORT_DOWNLOAD_URL_GET,
        payload: {exportRecord},
    });
};
