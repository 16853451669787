import React, { Component } from 'react';
import { dealerConstants } from '../../../../../../constants/form/formDealerConstants';
import { RedButton, } from '../../../../../common/StyledComponents';
import { formValueSelector, getFormInitialValues } from 'redux-form';
import { InputFieldWithValidation } from '../../../../../common/InputFieldWithValidation';
import { positiveNumber, validEmail } from '../../../../../common/validationCommons';
import get from 'get-value';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

class ResponsibilityTableBody extends Component {
    constructor(props) {
        super(props)
        this.customRefs = {
            [dealerConstants.FORM_FIELD_DEALER_NUMBER]: React.createRef(),
            [dealerConstants.FORM_FIELD_EMAIL]: React.createRef(),
        };
    }

    changeFocus = (value, maxLength, fieldName) => {
        if (get(this, `customRefs.${fieldName}.current`) && maxLength && value.length === maxLength) {
            this.customRefs[fieldName].current.focus();
        }
    };

    render() {
        const {
            userRights,
            readOnly,
            record,
            handleRemoveResponsibility,
            reqFields
        } = this.props;
        return (
            <>
                <tr className="border-bottom">
                    <td style={{ maxWidth: '130px', minWidth: '110px' }}>
                        <InputFieldWithValidation field={`${record}.${dealerConstants.FORM_FIELD_DEALER_NUMBER}`}
                                                  maxLength={8}
                                                  validations={[positiveNumber]}
                                                  isDisabled={!userRights.canEditItems || readOnly}
                                                  isRequired={reqFields.includes(dealerConstants.FORM_FIELD_DEALER_NUMBER) && !readOnly}
                                                  isSmall
                                                  setRef={this.customRefs[dealerConstants.FORM_FIELD_DEALER_NUMBER]}
                                                  handleKeyUp={(value) => this.changeFocus(value, 8, dealerConstants.FORM_FIELD_EMAIL)}
                        />
                    </td>
                    <td style={{ maxWidth: '130px', minWidth: '100px' }}>
                        <InputFieldWithValidation field={`${record}.${dealerConstants.FORM_FIELD_EMAIL}`}
                                                  maxLength={100}
                                                  validations={[validEmail]}
                                                  isDisabled={!userRights.canEditItems || readOnly}
                                                  isRequired={reqFields.includes(dealerConstants.FORM_FIELD_EMAIL) && !readOnly}
                                                  isSmall
                                                  setRef={this.customRefs[dealerConstants.FORM_FIELD_EMAIL]}
                        />
                    </td>

                    {userRights.canEditItems && !readOnly ?
                        <td>
                            <RedButton type="button"
                                       title="Remove order"
                                       onClick={handleRemoveResponsibility}
                                       className="form-control form-control-sm">
                                <FontAwesomeIcon icon="trash-alt"/>
                            </RedButton>
                        </td> : null
                    }
                </tr>
            </>
        )
    };
}

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
    records: formValueSelector(props.form)(state, `${dealerConstants.FORM_DEALER_RESPONSIBILITY_INFO_SECTION}.${dealerConstants.FORM_FIELDS_RESPONSIBILITY_ITEMS}`),
});

export default connect(mapStateToProps, {})(ResponsibilityTableBody);
