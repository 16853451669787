import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    clearDealerRightsManagementPageData,
    clearEditorActionRightsManagementPageData,
    clearEditorRightsManagementPageData,
    clearEditorTeamRolesManagementPageData,
    fetchDealerRightsManagementPageData,
    fetchEditorActionRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    fetchEditorTeamRolesManagementPageData,
    resetManageUser,
    saveEditorRightsManagementPageData,
    setEditorTeamRolesChanged,
    setInitialEditorTeamRolesOptions,
    setManagedUser,
    setSelectedEditorTeamRolesOptions
} from '../../../actions/admin/actionAdminRightsManagement';
import SelectUser from './SelectUser';
import Loader from '../../Loader';
import {editorTeamRoles} from '../../../constants/Utils';
import { clearAlerts } from '../../../actions/alertsActions';
import { handleFetchUsersError } from '../../../actions/actionRole';
import { handleUserSearch } from '../../../utils/userUtils';
import commonMessages from '../../../intl/common/commonMessages';
import PageHeader from '../../common/PageHeader';
import errorMessages from '../../../intl/common/errorMessages';
import adminMessages from '../../../intl/admin/adminMessages';
import SelectEditorTeamRole from './SelectEditorTeamRole';

const Panel = styled.h1`
  font-size: 1.5em;
  text-align: center;
`;

class EditorTeamRoleManagementPage extends Component {
    componentDidMount() {
        this.props.clearEditorRightsManagementPageData();
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setEditorTeamRolesChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleActionSelectionChange = newOptions => {
        this.setSelectedOptionsChanged(newOptions);
        this.props.setSelectedEditorTeamRolesOptions(newOptions);
    };

    handleSaveClick = () => {
        const selectedTeam = {};
        Object.keys(editorTeamRoles).forEach(
        groupKey => selectedTeam[editorTeamRoles[groupKey]] = false
    )

        console.log(this.props.selectedOptions)
        if(this.props.selectedOptions !== null) {
            Object.keys(selectedTeam).forEach(
                key => {
                    if(key===this.props.selectedOptions.value){
                        selectedTeam[key] = true
                    }
                }
            )
        }

        this.props.saveEditorRightsManagementPageData(this.props.managedUser, this.props.domain, null, null, selectedTeam);
    };

    handleCancelClick = () => {
        this.props.setSelectedEditorTeamRolesOptions(cloneDeep(this.props.initialOptions));
        this.props.setEditorTeamRolesChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearEditorRightsManagementPageData();
        this.props.clearEditorActionRightsManagementPageData();
        this.props.clearDealerRightsManagementPageData();
        this.props.clearEditorTeamRolesManagementPageData();
        this.props.fetchEditorActionRightsManagementPageData(user, this.props.domain);
        this.props.fetchEditorRightsManagementPageData(user, this.props.domain);
        this.props.fetchDealerRightsManagementPageData(user, this.props.domain);
        this.props.fetchEditorTeamRolesManagementPageData(user, this.props.domain);
    };

    setSelectedOptionsChanged = newOptions => this.props.setEditorTeamRolesChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                //.sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(newOptions
            //.sort((option1, option2) => option1.value.localeCompare(option2.value))
        )
    );

    render() {
        const { isLoading, managedUser, editorData, isDealer, domain, isEditor, actionData } = this.props;
        if (isLoading) {
            return <Loader/>;
        }

        return (
            <div>
                <PageHeader title={
                    <FormattedMessage {...adminMessages.EDITOR_TEAM}
                                      values={{
                                          domain: <FormattedMessage {...commonMessages[domain]}/>
                                      }}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {isDealer &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.DEALER_ROLE_SET}/>
                </Panel>
                }
                {Object.keys(editorData).length > 0 && !isEditor &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.EDITOR_ROLE_MISS}/>
                </Panel>
                }
                {Object.values(actionData).includes(true) &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.EDITOR_HAS_ACTION}/>
                </Panel>
                }
                {Object.keys(editorData).length > 0 && managedUser && managedUser.ipn && !isDealer && isEditor && !Object.values(actionData).includes(true) &&
                    <SelectEditorTeamRole handleActionSelectionChange={this.handleActionSelectionChange}
                                        handleSaveClick={this.handleSaveClick}
                                        handleCancelClick={this.handleCancelClick}/>
                }
            </div>
        );
    }
}

EditorTeamRoleManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    setSelectedEditorTeamRolesOptions: PropTypes.func.isRequired,
    editorData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    saveEditorRightsManagementPageData: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    setEditorTeamRolesChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearEditorRightsManagementPageData: PropTypes.func.isRequired,
    clearEditorActionRightsManagementPageData: PropTypes.func.isRequired,
    clearDealerRightsManagementPageData: PropTypes.func.isRequired,
    fetchEditorActionRightsManagementPageData: PropTypes.func.isRequired,
    fetchEditorRightsManagementPageData: PropTypes.func.isRequired,
    fetchDealerRightsManagementPageData: PropTypes.func.isRequired,
    setInitialEditorTeamRolesOptions: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    isDealer: PropTypes.bool.isRequired,

    fetchEditorTeamRolesManagementPageData: PropTypes.func.isRequired,
    clearEditorTeamRolesManagementPageData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    editorData: state.adminRightsManagement.editorTeamRoleData,
    actionData: state.adminRightsManagement.editorActionData,
    isDealer: Object.values(state.adminRightsManagement.dealerData.groupPermission || {}).some(value => value),
    isEditor: Object.values(state.adminRightsManagement.editorData|| {}).some(value => value === true),
    initialOptions: state.adminRightsManagement.initialEditorTeamRoleOptions,
    selectedOptions: state.adminRightsManagement.selectedEditorTeamRoleOptions
});

export default connect(mapStateToProps, {
    setManagedUser,
    fetchEditorActionRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    setSelectedEditorTeamRolesOptions,
    setEditorTeamRolesChanged,
    setInitialEditorTeamRolesOptions,
    saveEditorRightsManagementPageData,
    clearEditorRightsManagementPageData,
    clearDealerRightsManagementPageData,
    clearEditorActionRightsManagementPageData,
    clearAlerts,
    handleFetchUsersError,
    resetManageUser,
    fetchEditorTeamRolesManagementPageData,
    clearEditorTeamRolesManagementPageData,
})(injectIntl(EditorTeamRoleManagementPage));
