import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    ADMINISTRATION_RBOX_NOTIFICATION_PATH,
    ADMINISTRATION_RIGHTS_ADMIN_PATH,
    ADMINISTRATION_RIGHTS_DEALER_ROLES_PATH,
    ADMINISTRATION_RIGHTS_DEALER_RESPONSIBILITY_PATH,
    ADMINISTRATION_RIGHTS_EDITOR_ACTIONS_PATH,
    ADMINISTRATION_RIGHTS_EDITOR_ROLES_PATH,
    ADMINISTRATION_RIGHTS_CENTRAL_PATH,
    ADMINISTRATION_RIGHTS_EDITOR_TEAM_ROLES_PATH,
} from '../../../../routes/paths';
import { moduleRoles } from '../../../../utils/roles';
import menuMessages from '../../../../intl/layout/menuMessages';

export const getAdministrationMenuAdmin = roles => {
    let result = [];

    if (moduleRoles.isAdmin(roles) || moduleRoles.isPowerUser(roles)) {
        let content = [];
        if (moduleRoles.isAdmin(roles)) {
            content = [];
        }

        const authorizationManagementSubmenu = buildAuthorizationManagementSubmenu(roles);
        if (authorizationManagementSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_RBOX_AUTH_MANAGEMENT} />,
                    content: authorizationManagementSubmenu
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_NOTIFICATION} />,
                    to: ADMINISTRATION_RBOX_NOTIFICATION_PATH
                },
            );
        }
        result = content;
    }
    return result;
};

export const getEditorRightsSubMenuAdmin = roles => {
    let result = [];

    if (moduleRoles.isAdmin(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_RBOX_AUTH_MANAGEMENT_EDITOR_ACTION} />,
                to: ADMINISTRATION_RIGHTS_EDITOR_ACTIONS_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_RBOX_AUTH_MANAGEMENT_EDITOR_ROLE} />,
                to: ADMINISTRATION_RIGHTS_EDITOR_ROLES_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_RBOX_AUTH_MANAGEMENT_EDITOR_TEAM_ROLE} />,
                to: ADMINISTRATION_RIGHTS_EDITOR_TEAM_ROLES_PATH
            }
        );
    }
    return result;
}

export const getDealerRightsSubMenuAdmin = roles => {
    let result = [];

    if (moduleRoles.isAdmin(roles) && process.env.REACT_APP_LDAP_DEALER_ROLE === 'false') {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_RBOX_AUTH_MANAGEMENT_DEALER_ROLE} />,
                to: ADMINISTRATION_RIGHTS_DEALER_ROLES_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_RBOX_AUTH_MANAGEMENT_DEALER_RESPONSIBILITY} />,
                to: ADMINISTRATION_RIGHTS_DEALER_RESPONSIBILITY_PATH
            }
        );
    }
    return result;
}

const buildAuthorizationManagementSubmenu = roles => {
    const result = [];

    if (moduleRoles.isPowerUser(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_RBOX_AUTH_MANAGEMENT_ADMIN} />,
                to: ADMINISTRATION_RIGHTS_ADMIN_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles)) {

        let authorizationManagementSubmenu = getEditorRightsSubMenuAdmin(roles);
        if (authorizationManagementSubmenu.length > 0) {
            result.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_RBOX_AUTH_MANAGEMENT_EDITOR} />,
                    content: authorizationManagementSubmenu
                },
            );
        }

        // result.push(
        //     {
        //         label: <FormattedMessage {...menuMessages.ADMIN_RBOX_AUTH_MANAGEMENT_CENTRAL} />,
        //         to: ADMINISTRATION_RIGHTS_CENTRAL_PATH
        //     }
        // );

        authorizationManagementSubmenu = getDealerRightsSubMenuAdmin(roles);
        if (authorizationManagementSubmenu.length > 0) {
            result.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_RBOX_AUTH_MANAGEMENT_DEALER} />,
                    content: authorizationManagementSubmenu
                },
            );
        }
    }
    return result;
};
