import {modules} from '../constants/Utils';

export const PING_WEBSOCKET = 'ping';
export const CONNECT_RBOX_WEBSOCKET_PENDING = 'CONNECT_RBOX_WEBSOCKET_PENDING';
export const CONNECT_RBOX_WEBSOCKET_SUCCESS = 'CONNECT_RBOX_WEBSOCKET_SUCCESS';
export const CONNECT_RBOX_WEBSOCKET_ERROR = 'CONNECT_RBOX_WEBSOCKET_ERROR';

export function connectWebsockets(token) {
    return dispatch => {
        dispatch({ type: CONNECT_RBOX_WEBSOCKET_PENDING, payload: { token } });
    };
}
export function pingWebsocket(module) {
    let moduleSocket = '';

    switch (module) {
        case modules: {
            moduleSocket = 'rboxWebsocket';
            break;
        }
        default: {
            moduleSocket = 'default';
            break;
        }
    }

    return dispatch => {
        dispatch({
            type: PING_WEBSOCKET,
            command: PING_WEBSOCKET,
            [moduleSocket]: true
        });
    };
}
