import {
    RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
    RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
    RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
    RBOX_TICKET_STATUS_NEW,
    RBOX_TICKET_STATUS_READ_BY_EDITOR,
    RBOX_TICKET_STATUS_SOLVED,
} from './form/statusConstants';
import { FLOW_DEALER_EDITOR_GLPI, FLOW_DEALER_GLPI, FLOW_EDITOR_GLPI } from './flow';
import { degFlowCountries, dgFlowCountries, egFlowCountries } from './countries';


export const editTicketRights = {
    FLOW_DEALER_EDITOR_GLPI: {
        DEALER: {
            NOT_TODO: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            NEW: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: true,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: true,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            READ_BY_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_DEALER: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: true,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_GLPI: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            SOLVED: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            CLOSED: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
        },
        EDITOR: {
            NOT_TODO: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            NEW: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: true,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: true,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            READ_BY_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: true,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_DEALER: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_GLPI: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            SOLVED: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            CLOSED: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
        },
        CENTRAL_TEAM: {
            NOT_TODO: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            NEW: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            READ_BY_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_DEALER: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_GLPI: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            SOLVED: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            CLOSED: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
        },
    },
    FLOW_EDITOR_GLPI: {
        EDITOR: {
            NOT_TODO: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            NEW: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: true,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: true,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            READ_BY_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: true,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_DEALER: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_GLPI: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            SOLVED: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            CLOSED: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
        },
        CENTRAL_TEAM: {
            NOT_TODO: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            NEW: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            READ_BY_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_DEALER: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_GLPI: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            SOLVED: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            CLOSED: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
        },
    },
    FLOW_DEALER_GLPI: {
        DEALER: {
            NOT_TODO: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            NEW: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: true,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: true,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            READ_BY_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_DEALER: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: true,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_GLPI: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            SOLVED: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            CLOSED: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
        },
        EDITOR: {
            NOT_TODO: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            NEW: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_EDITOR: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            READ_BY_EDITOR: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_DEALER: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_GLPI: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            SOLVED: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            CLOSED: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
        },
        CENTRAL_TEAM: {
            NOT_TODO: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
            NEW: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            READ_BY_EDITOR: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_DEALER: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            FORWARDED_TO_GLPI: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            SOLVED: {
                canAddAttach: true,
                canAddNotes: true,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: true,
                canCreateNew: false,
                canEdit: false,
            },
            CLOSED: {
                canAddAttach: false,
                canAddNotes: false,
                canEditHeader: false,
                canChangeToReadBySubs: false,
                canReopen: false,
                canSave: false,
                canCreateNew: false,
                canEdit: false,
            },
        },
    },
}

export const createRights = {
    canAddAttach: true,
    canAddNotes: false,
    canEditHeader: true,
    canChangeToReadBySubs: false,
    canReopen: false,
    canSave: true,
    canCreateNew: true,
    canEdit: false,
};
export const noRights = {
    canAddAttach: false,
    canAddNotes: false,
    canEditHeader: false,
    canChangeToReadBySubs: false,
    canReopen: false,
    canSave: false,
    canCreateNew: false,
    canEdit: false,
};

export const editListButtonVisibility = {
    FLOW_DEALER_EDITOR_GLPI: {
        DEALER: [
            RBOX_TICKET_STATUS_NEW,
            RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
            RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
            RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
            RBOX_TICKET_STATUS_READ_BY_EDITOR,
            RBOX_TICKET_STATUS_SOLVED
        ],
        EDITOR: [
            RBOX_TICKET_STATUS_NEW,
            RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
            RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
            RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
            RBOX_TICKET_STATUS_READ_BY_EDITOR,
            RBOX_TICKET_STATUS_SOLVED
        ],
        CENTRAL_TEAM: [
            RBOX_TICKET_STATUS_NEW,
            RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
            RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
            RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
            RBOX_TICKET_STATUS_READ_BY_EDITOR,
            RBOX_TICKET_STATUS_SOLVED
        ]
    },
    FLOW_DEALER_GLPI: {
        DEALER: [
            RBOX_TICKET_STATUS_NEW,
            RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
            RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
            RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
            RBOX_TICKET_STATUS_READ_BY_EDITOR,
            RBOX_TICKET_STATUS_SOLVED
        ],
        EDITOR: [],
        CENTRAL_TEAM: [
            RBOX_TICKET_STATUS_NEW,
            RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
            RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
            RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
            RBOX_TICKET_STATUS_READ_BY_EDITOR,
            RBOX_TICKET_STATUS_SOLVED
        ]
    },
    FLOW_EDITOR_GLPI: {
        DEALER: [],
        EDITOR: [
            RBOX_TICKET_STATUS_NEW,
            RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
            RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
            RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
            RBOX_TICKET_STATUS_READ_BY_EDITOR,
            RBOX_TICKET_STATUS_SOLVED
        ],
        CENTRAL_TEAM: [
            RBOX_TICKET_STATUS_NEW,
            RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
            RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
            RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
            RBOX_TICKET_STATUS_READ_BY_EDITOR,
            RBOX_TICKET_STATUS_SOLVED
        ]
    },
    'NO_RIGHTS': {
        DEALER: [],
        EDITOR: [],
        CENTRAL_TEAM: []
    },
};

export const updateListButtonVisibility = {
    DEALER: [
        RBOX_TICKET_STATUS_NEW,
        RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
        RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
        RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
        RBOX_TICKET_STATUS_READ_BY_EDITOR,
        RBOX_TICKET_STATUS_SOLVED
    ],
    EDITOR: [
        RBOX_TICKET_STATUS_NEW,
        RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
        RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
        RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
        RBOX_TICKET_STATUS_READ_BY_EDITOR,
        RBOX_TICKET_STATUS_SOLVED
    ]
};

export const countryFlow = (country) => {
    if (degFlowCountries.includes(country)) {
        return FLOW_DEALER_EDITOR_GLPI;
    } else if (dgFlowCountries.includes(country)) {
        return FLOW_DEALER_GLPI
    } else if (egFlowCountries.includes(country)) {
        return FLOW_EDITOR_GLPI;
    } else {
        return 'NO_RIGHTS';
    }
}




