import { connect } from 'react-redux';
import { fetchListPageData } from '../../../../actions/rbox/actionListPage';
import TicketTable from '../shared/TicketTable';

const mapStateToProps = (state) => ({
    isLoading: state.listTicket.isTableLoading,
    data: state.listTicket.listData,
    isNextPage: state.listTicket.isNextPage,
    isNextFromPivot: state.listTicket.isNextFromPivot,
    pages: state.listTicket.pages,
    userRoles: state.profile.userDetail.roles,
    loggedUser: state.profile.userDetail,
    keyToMatch: 'listTicket'
});

export default connect(mapStateToProps, {
    fetchData: fetchListPageData,
})(TicketTable);
