import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    TOKEN_EXPIRED: {
        id: 'alert.token.expired',
        defaultMessage: 'Authorization token expired. Refresh?'
    },
    RBOX_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.rbox',
        defaultMessage: 'Cannot reach RBOX backend'
    },
    RBOX_WS_ERROR: {
        id: 'alert.error.ws.rbox',
        defaultMessage: 'Unexpected RBOX WebSocket error'
    },
    RBOX_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.rbox',
        defaultMessage: 'Command structure error for RBOX'
    },
    FILE_DOWNLOAD_ERROR: {
        id: 'alert.error.file.download',
        defaultMessage: 'Cannot download file'
    },
    FILE_UPLOAD_ERROR: {
        id: 'alert.error.file.upload',
        defaultMessage: 'Cannot upload file'
    },
    RIGHTS_ADMIN_FETCH_ERROR: {
        id: 'alert.error.rights.admin.fetch',
        defaultMessage: 'Cannot fetch Rights Management admin data'
    },
    RIGHTS_ADMIN_RETRIEVED_ERROR: {
        id: 'alert.error.rights.admin.retrieved',
        defaultMessage: 'Retrieved invalid admin rights from backend'
    },
    RIGHTS_ADMIN_SEND_ERROR: {
        id: 'alert.error.rights.admin.send',
        defaultMessage: 'Cannot send invalid admin rights to backend'
    },
    RIGHTS_DEALER_FETCH_ERROR: {
        id: 'alert.error.rights.dealer.fetch',
        defaultMessage: 'Cannot fetch Rights Management dealer data'
    },
    RIGHTS_DEALER_RETRIEVED_ERROR: {
        id: 'alert.error.rights.dealer.retrieved',
        defaultMessage: 'Retrieved invalid dealer rights from backend'
    },
    RIGHTS_DEALER_SEND_ERROR: {
        id: 'alert.error.rights.dealer.send',
        defaultMessage: 'Cannot send invalid dealer rights to backend'
    },
    RIGHTS_EDITOR_FETCH_ERROR: {
        id: 'alert.error.rights.editor.fetch',
        defaultMessage: 'Cannot fetch Rights Management editor data'
    },
    RIGHTS_EDITOR_RETRIEVED_ERROR: {
        id: 'alert.error.rights.editor.retrieved',
        defaultMessage: 'Retrieved invalid editor rights from backend'
    },
    RIGHTS_EDITOR_SEND_ERROR: {
        id: 'alert.error.rights.editor.send',
        defaultMessage: 'Cannot send invalid editor rights to backend'
    },
    RIGHTS_CENTRAL_RETRIEVED_ERROR: {
        id: 'alert.error.rights.central.retrieved',
        defaultMessage: 'Retrieved invalid central editor rights from backend'
    },
    RIGHTS_CENTRAL_FETCH_ERROR: {
        id: 'alert.error.rights.central.fetch',
        defaultMessage: 'Cannot fetch Rights Management central editor data'
    },
    RIGHTS_CENTRAL_SEND_ERROR: {
        id: 'alert.error.rights.central.send',
        defaultMessage: 'Cannot send invalid central editor rights to backend'
    },
    USERS_DETAILS_FETCH_ERROR: {
        id: 'alert.error.users.details.fetch',
        defaultMessage: "Cannot fetch user's detail"
    },
    USERS_FETCH_ERROR: {
        id: 'alert.error.users.fetch',
        defaultMessage: 'Cannot fetch users'
    },
    ITEMS_FETCH_ERROR: {
        id: 'alert.error.items.fetch',
        defaultMessage: 'Cannot fetch items'
    },
    USERS_RETRIEVED_ERROR: {
        id: 'alert.error.users.retrieved',
        defaultMessage: 'Retrieved invalid user from backend'
    },
    USERS_SAVE_ERROR: {
        id: 'alert.error.users.save',
        defaultMessage: 'Cannot save a user'
    },
    DETAILS_FOR_SUPPORT: {
        id: 'alert.link.detailsForSupport',
        defaultMessage: 'Details for Support'
    },
    TICKET_NEW_SEND_ERROR: {
        id: 'alert.error.ticket.new.send',
        defaultMessage: 'Cannot send invalid new Ticket to backend'
    },
    TICKET_UPDATE_SEND_ERROR: {
        id: 'alert.error.ticket.update.send',
        defaultMessage: 'Cannot send invalid updated Ticket to backend'
    },
    TICKET_FETCH_SEND_ERROR: {
        id: 'alert.error.ticket.fetch.send',
        defaultMessage: 'Retrieved invalid ticket from backend'
    },
    EDITOR_NEW_SEND_ERROR: {
        id: 'alert.error.editor.new.send',
        defaultMessage: 'Cannot send invalid new editor to backend'
    },
    EDITOR_UPDATE_SEND_ERROR: {
        id: 'alert.error.editor.update.send',
        defaultMessage: 'Cannot send invalid updated editor to backend'
    },
    EDITOR_FETCH_SEND_ERROR: {
        id: 'alert.error.editor.fetch.send',
        defaultMessage: 'Retrieved invalid editor from backend'
    }
});

export default messages;
