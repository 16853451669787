import {defineMessages} from 'react-intl';

const messages = defineMessages({
    BASIC_DEALER: {
        id: 'group.dealer.basic',
        defaultMessage: 'Basic dealer'
    },
});

export default messages;
