export const RBOX_TICKET_FORM = 'BASE_TICKET';
export const RBOX_TICKET_FORM_VIEW = 'BASE_TICKET_VIEW';
export const RBOX_TICKET_FORM_EDIT = 'BASE_TICKET_EDIT';

//Status history
export const FORM_STATUS_HISTORY_SECTION = 'statusHistory';

//Button mappings
export const FORM_BUTTON_CLOSE = 'customCloseButton';
export const FORM_BUTTON_SOLVE = 'solveButton';
export const FORM_BUTTON_SAVE = 'saveButton';
export const FORM_BUTTON_CANCEL = 'cancelButton';
export const FORM_BUTTON_REOPEN = 'reopenButton';
export const FORM_BUTTON_BACK = 'backButton';
export const FORM_BUTTON_EDIT = 'editButton';
export const FORM_BUTTON_READ_BY_EDITOR = 'readByEditor';
export const FORM_BUTTON_FORWARD_TO_EDITOR = 'forwardToEditor';
export const FORM_BUTTON_FORWARD_TO_DEALER = 'forwardToDealer';
export const FORM_BUTTON_FORWARD_TO_GLPI = 'forwardToGLPI';

export const FORM_SELECT_FORWARD_TO_EDITOR = 'selectForwardToEditor';

export const BUTTON_LABEL_SAVE = 'SAVE';
export const BUTTON_LABEL_CANCEL = 'CANCEL';
export const BUTTON_LABEL_READ_BY_EDITOR= 'READ_BY_EDITOR';
export const BUTTON_LABEL_FORWARD_TO_EDITOR = 'FORWARDED_TO_EDITOR';
export const BUTTON_LABEL_TAKE_BACK = 'TAKE_BACK';
export const BUTTON_LABEL_FORWARD_TO_DEALER = 'FORWARDED_TO_DEALER';
export const BUTTON_LABEL_FORWARD_TO_GLPI = 'FORWARDED_TO_GLPI';
export const BUTTON_LABEL_CLOSE = 'CLOSE';
export const BUTTON_LABEL_SOLVE = 'SOLVE';
export const BUTTON_LABEL_SOLVING = 'SOLVING';
export const BUTTON_LABEL_CLOSING = 'CLOSING';
export const BUTTON_LABEL_REOPEN = 'REOPEN';
export const BUTTON_LABEL_DOWNLOAD = 'DOWNLOAD';
export const BUTTON_LABEL_EXPORT = 'EXPORT';
