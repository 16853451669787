import { defineMessages } from 'react-intl';
import { ACTION_ATTACHMENT_ADDED, 
    ACTION_CREATE_TICKET, 
    ACTION_FOLLOWUP_CREATED, 
    ACTION_STATUS_CHANGED, 
    ACTION_UPDATE_TICKET } from '../../constants/form/actionsConstants'

export const messages = defineMessages({
    MESSAGE_ADDED: {
        id: 'form.action.newMessage',
        defaultMessage: 'New Message'
    },
    ATTACHMENT_ADDED: {
        id: 'form.action.newAttachment',
        defaultMessage: 'New Attachment'
    },
    [ACTION_CREATE_TICKET]: {
        id: 'form.action.createTicket',
        defaultMessage: 'Ticket created'
    },
    [ACTION_UPDATE_TICKET]: {
        id: 'form.action.updateTicket',
        defaultMessage: 'Ticket updated'
    },
    [ACTION_FOLLOWUP_CREATED]: {
        id: 'form.action.followupCreated',
        defaultMessage: 'Followup created'
    },
    [ACTION_ATTACHMENT_ADDED]: {
        id: 'form.action.attachmentAdded',
        defaultMessage: 'Attachment added'
    },
    [ACTION_STATUS_CHANGED]: {
        id: 'form.action.statusChanged',
        defaultMessage: 'Status changed'
    },


});

export default messages;
