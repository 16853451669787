import get from 'get-value';
import {dealerRoles, editorTeamRoles} from '../constants/Utils';
import { countries } from '../constants/countries';

function hasAtLeastOneRoleSet(roles, allowedValues) {
    return Object.keys(roles)
        .filter(role => roles[role] === true && allowedValues.includes(role))
        .length > 0;
}

export const getRoles = (roles) => {
    return Object.keys(roles)
        .map(role => ({group: role, value: roles[role]}))
        .filter(item => item.value === true)
        .sort((option1, option2) => option1.group.localeCompare(option2.group));
};

export const moduleRoles = {
    isPowerUser(roles) {
        return get(roles, `powerUser`, { default: false });
    },
    isAdmin(roles) {
        return hasAtLeastOneRoleSet(
            get(roles, `admin.groupPermission`, { default: {} }),
            Object.values(countries)
        );
    },
    isDealer(roles) {
        return hasAtLeastOneRoleSet(
            get(roles, `dealer.groupPermission`, { default: {} }),
            Object.values(dealerRoles)
        );
    },
    isEditor(roles) {
        return hasAtLeastOneRoleSet(
            get(roles, `editor.groupPermission`, { default: {} }),
            Object.values(countries)
        );
    },
    isTeamEditor(roles) {
        return hasAtLeastOneRoleSet(
            get(roles, `editor.teamPermission`, { default: {} }),
            Object.values(editorTeamRoles)
        );
    },
    hasEditorActionRole(roles, action) {
        const role = get(roles, 'editor.actionPermission', { default: {} });

        return Object.keys(role)
            .filter(item => role[item] === true && item === action)
            .length > 0;

    },
    availableCountriesEditor(roles) {
        const countriesArray = [];
        if (this.isEditor(roles)) {
            const editorRoles = get(roles, 'editor.groupPermission', { default: {} });
            Object.keys(editorRoles)
                .filter(item => editorRoles[item] === true)
                .map(item => countriesArray.push(item));
        }
        return countriesArray;
    },
    getAdminGroups(roles) {
        return getRoles(get(roles, `admin.groupPermission`, {default: {}}));
    },
    getDealerForms(roles) {
        return getRoles(get(roles, `dealer.formsPermission`, {default: {}}));
    },
    getEditorGroups(roles) {
        return getRoles(get(roles, `editor.groupPermission`, {default: {}}));
    },
    getEditorTeam(roles) {
        return getRoles(get(roles, `editor.teamPermission`, {default: {}}));
    }
};
