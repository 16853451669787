import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    NOT_TODO: {
        id: 'form.status.notTodo',
        defaultMessage: 'No actions'
    },
    NEW: {
        id: 'form.status.new',
        defaultMessage: 'New'
    },
    OPENED: {
        id: 'form.status.opened',
        defaultMessage: 'Opened'
    },
    CLOSED: {
        id: 'form.status.closed',
        defaultMessage: 'Closed'
    },
    SOLVED: {
        id: 'form.status.solved',
        defaultMessage: 'Solved'
    },
    FORWARDED_TO_DEALER: {
        id: 'form.status.forwardedToDealer',
        defaultMessage: 'In progress - Dealer'
    },
    FORWARDED_TO_EDITOR: {
        id: 'form.status.forwardedToEditor',
        defaultMessage: 'In progress - Editor'
    },
    FORWARDED_TO_GLPI: {
        id: 'form.status.forwardedToGLPI',
        defaultMessage: 'At Econocom'
    },
    READ_BY_EDITOR: {
        id: 'form.status.readByEditor',
        defaultMessage: 'Read by Editor'
    },
    STATUS_HISTORY: {
        id: 'form.header.statusHistory',
        defaultMessage: 'History of Changes'
    }
});

export default messages;
