import React from 'react';
import styled from 'styled-components';
import { FormSection, reduxForm } from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import RenderSyncErrors from '../../../../common/RenderSyncErrors';
import { scrollContentWrapperToTop } from '../../../../../utils/utils';
import formMessages from '../../../../../intl/rbox/formMessages';
import { intlFieldMapping } from '../../../../../constants/fieldLabelMapping';
import TicketSection from './sections/ticket/TicketSection';
import { ticketConstants } from '../../../../../constants/form/formTicketConstants';
import AttachmentSection from './sections/attachments/AttachmentSection';
import MessagesSection from './sections/notes/MessagesSection';
import TicketStatusHistory from '../../../../common/TicketStatusHistory';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const TicketForm = (props) => {
    const { form, userRights, reqFields, viewType, readOnly, handleSubmit, availableStatus, handleLoadItems, availableTeam } = props;

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={intlFieldMapping}/>
            <FormSection name={ticketConstants.FORM_TICKET_SECTION}>
                <TicketSection form={form} viewType={viewType} userRights={userRights} reqFields={reqFields} readOnly={readOnly} handleLoadItems={handleLoadItems}/>
            </FormSection>

            <FormSection name={ticketConstants.FORM_NOTES_SECTION}>
                <MessagesSection readOnly={readOnly} form={form} userRights={userRights} reqFields={reqFields} />
            </FormSection>

            <FormSection name={ticketConstants.FORM_ATTACHMENTS_SECTION}>
                <AttachmentSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <TicketStatusHistory form={form}/>
            <SubmitButtons form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}
                           onSubmit={handleSubmit} onSubmitFail={scrollContentWrapperToTop} availableStatus={availableStatus} availableTeam={availableTeam}/>
        </StyledDiv>
    )
};

export default reduxForm({})(TicketForm);
