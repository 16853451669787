import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    clearDealerRightsManagementPageData,
    clearEditorActionRightsManagementPageData,
    clearEditorRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    fetchEditorActionRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    resetManageUser,
    saveEditorRightsManagementPageData,
    setEditorGroupsChanged,
    setInitialEditorGroupOptions,
    setManagedUser,
    setSelectedEditorGroupOptions
} from '../../../actions/admin/actionAdminRightsManagement';
import SelectUser from './SelectUser';
import Loader from '../../Loader';
import { clearAlerts } from '../../../actions/alertsActions';
import { handleFetchUsersError } from '../../../actions/actionRole';
import { handleUserSearch } from '../../../utils/userUtils';
import commonMessages from '../../../intl/common/commonMessages';
import PageHeader from '../../common/PageHeader';
import SelectEditorGroups from './SelectEditorGroups';
import errorMessages from '../../../intl/common/errorMessages';
import adminMessages from '../../../intl/admin/adminMessages';
import { countries } from '../../../constants/countries';

const Panel = styled.h1`
  font-size: 1.5em;
  text-align: center;
`;

class EditorRightsManagementPage extends Component {
    componentDidMount() {
        this.props.clearEditorRightsManagementPageData();
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setEditorGroupsChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleGroupSelectionChange = newOptions => {
        this.setSelectedOptionsChanged(newOptions);
        this.props.setSelectedEditorGroupOptions(newOptions);
    };

    handleAllGroupsClick = () => {
        const selectedGroups = {};

        Object.keys(this.props.editorData || {}).forEach(
            groupIso => selectedGroups[groupIso] = true
        );

        const selectedOptions = this.setEditorDataAsGroupOptions(selectedGroups);
        this.setSelectedOptionsChanged(selectedOptions);
    };

    handleSaveClick = () => {
        const selectedGroups = {};
        Object.keys(countries).forEach(
            groupKey => selectedGroups[countries[groupKey]] = false
        );
        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({ value }) => {
                    selectedGroups[value] = true;
                }
            )
        }
        this.props.saveEditorRightsManagementPageData(this.props.managedUser, this.props.domain, selectedGroups);
    };

    handleCancelClick = () => {
        this.props.setSelectedEditorGroupOptions(cloneDeep(this.props.initialOptions));
        this.props.setEditorGroupsChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearEditorRightsManagementPageData();
        this.props.clearEditorActionRightsManagementPageData();
        this.props.clearDealerRightsManagementPageData();
        this.props.fetchEditorActionRightsManagementPageData(user, this.props.domain);
        this.props.fetchEditorRightsManagementPageData(user, this.props.domain);
        this.props.fetchDealerRightsManagementPageData(user, this.props.domain);
    };

    setEditorDataAsGroupOptions = editorData => {
        const result = Object.keys(editorData || {}).map(groupIso => ({ value: groupIso }));
        this.props.setSelectedEditorGroupOptions(cloneDeep(result));
        return result;
    };

    setSelectedOptionsChanged = newOptions => this.props.setEditorGroupsChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                .sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(newOptions.sort((option1, option2) => option1.value.localeCompare(option2.value)))
    );

    render() {
        const { isLoading, managedUser, editorData, isDealer, domain } = this.props;
        if (isLoading) {
            return <Loader/>;
        }

        return (
            <div>
                <PageHeader title={
                    <FormattedMessage {...adminMessages.EDITOR_ROLE}
                                      values={{
                                          domain: <FormattedMessage {...commonMessages[domain]}/>
                                      }}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {isDealer &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.DEALER_ROLE_SET}/>
                </Panel>
                }
                {Object.keys(editorData).length > 0 && managedUser && managedUser.ipn && !isDealer &&
                    <SelectEditorGroups handleGroupSelectionChange={this.handleGroupSelectionChange}
                                        handleAllGroupsClick={this.handleAllGroupsClick}
                                        handleSaveClick={this.handleSaveClick}
                                        handleCancelClick={this.handleCancelClick}/>
                }
            </div>
        );
    }
}

EditorRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    setSelectedEditorGroupOptions: PropTypes.func.isRequired,
    editorData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    saveEditorRightsManagementPageData: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    setEditorGroupsChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearEditorRightsManagementPageData: PropTypes.func.isRequired,
    clearEditorActionRightsManagementPageData: PropTypes.func.isRequired,
    clearDealerRightsManagementPageData: PropTypes.func.isRequired,
    fetchEditorActionRightsManagementPageData: PropTypes.func.isRequired,
    fetchEditorRightsManagementPageData: PropTypes.func.isRequired,
    fetchDealerRightsManagementPageData: PropTypes.func.isRequired,
    setInitialEditorGroupOptions: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    isDealer: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    editorData: state.adminRightsManagement.editorData,
    isDealer: Object.values(state.adminRightsManagement.dealerData.groupPermission || {}).some(value => value),
    initialOptions: state.adminRightsManagement.initialEditorGroupOptions,
    selectedOptions: state.adminRightsManagement.selectedEditorGroupOptions
});

export default connect(mapStateToProps, {
    setManagedUser,
    fetchEditorRightsManagementPageData,
    fetchEditorActionRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    setSelectedEditorGroupOptions,
    setEditorGroupsChanged,
    setInitialEditorGroupOptions,
    saveEditorRightsManagementPageData,
    clearEditorRightsManagementPageData,
    clearEditorActionRightsManagementPageData,
    clearDealerRightsManagementPageData,
    clearAlerts,
    handleFetchUsersError,
    resetManageUser
})(injectIntl(EditorRightsManagementPage));
