import React from 'react';
import { getFormInitialValues, getFormValues, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
    BUTTON_LABEL_CANCEL,
    BUTTON_LABEL_SAVE,
    FORM_BUTTON_CANCEL,
    FORM_BUTTON_SAVE,
} from '../../../../../constants/formConstants';
import { PrimaryButton } from '../../../../common/Button';
import buttonMessages from '../../../../../intl/common/buttonMessages';
import { withRouter } from 'react-router-dom';
import { RequiredSpan } from '../../../../common/StyledComponents';
import ValidFormattedMessage from '../../../../common/ValidFormattedMessage';
import { throttle } from 'throttle-debounce';

const SubmitButtons = (props) => {
    const {
        dirty,
        handleSubmit,
        submitting,
        initialValues,
        userRights,
        processingDic,
        reset
    } = props;

    const renderButton = (button, message) => {

        if (button === FORM_BUTTON_CANCEL) {
            return (
                <PrimaryButton onClick={reset} className="btn mr-2 float-right"
                               disabled={submitting || (!dirty && button === FORM_BUTTON_CANCEL)}>
                    <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
                </PrimaryButton>
            )
        } else {
            return (
                <PrimaryButton onClick={throttle(1000, async (e) => {
                    e.preventDefault();

                    handleSubmit(values =>
                        props.onSubmit({
                            newValues: { ...values, responsibilitySection: { ...values.responsibilitySection } },
                            oldValues: initialValues,
                            submitBy: button,
                        }))()
                })
                } className="btn mr-2 float-right"
                               disabled={submitting || (!dirty && button === FORM_BUTTON_SAVE) || processingDic}>
                    <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
                </PrimaryButton>
            )
        }
    };

    return (
        <div className="d-flex justify-content-between mt-3">
            <div><RequiredSpan>{'*\u00A0'}</RequiredSpan>is mandatory</div>
            <form className="form-group">
                {userRights.canSave && renderButton(FORM_BUTTON_SAVE, BUTTON_LABEL_SAVE)}
                {userRights.canSave && renderButton(FORM_BUTTON_CANCEL, BUTTON_LABEL_CANCEL)}
            </form>
        </div>
    )
};

SubmitButtons.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    form: PropTypes.string.isRequired,
    formValues: PropTypes.object,
    pristine: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    userRights: PropTypes.object.isRequired,
};

const mapStateToProps = (state, initialProps) => ({
    formValues: getFormValues(initialProps.form)(state),
    initialValues: getFormInitialValues(initialProps.form)(state),
});


export default reduxForm({})(withRouter(connect(mapStateToProps, {})(SubmitButtons)));
