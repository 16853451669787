import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import SideMenu from './SideMenu';
import PropTypes from 'prop-types';

class SideBar extends Component {
    render() {
        const { store } = this.props;

        return (
            <Switch>
                <Route path="/administration" render={() => (<SideMenu store={store} administration/>)}/>
                <Route path="/ticket" render={() => (<SideMenu store={store} rboxTicket/>)}/>
            </Switch>
        );
    }
}

SideBar.propTypes = {
    store: PropTypes.any,
};

export default (SideBar);
