export const styles = {
    multiValue: (base, state) => {
        return { ...base };
    },
    multiValueLabel: (base, state) => {
        return { ...base };
    },
    multiValueRemove: (base, state) => {
        return { ...base, display: 'none' };
    },
};
