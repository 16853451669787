import { rboxTicketWebsocketCommands } from '../../constants/rboxTicketWebsocketCommands';


export const fetchListPageData = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: rboxTicketWebsocketCommands.RBOX_NOTIFICATION_LIST_PENDING,
        rboxWebsocket: true,
        command: rboxTicketWebsocketCommands.RBOX_NOTIFICATION_LIST_RECEIVE,
        payload: { pageSize: pageSize, sorted: sorted, cursor, isNextFromPivot, filtered: filtered}
    });
};
