import { rboxTicketWebsocketCommands, } from '../../constants/rboxTicketWebsocketCommands';
import { RBOX_ATTACHMENTS_SAVED, RBOX_ATTACHMENTS_SAVED_ERROR } from '../../constants/websocketCommands';
import { RBOX_RESET_REDIRECT, RBOX_SET_LOCK_TICKET } from '../../actions/rbox/actionLockTicket';


export const ticket = (state = {
    correlationId: null,
    isTicketLoading: false,
    isTicketCreating: false,
    created: false,
    ticket: {},
    messages: [],
    attachments: [],
    newAttachments: [],
    logs: [],
    editor: [],
}, action) => {
    switch (action.type) {
        case rboxTicketWebsocketCommands.RBOX_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLoading: false,
                isTicketCreating: false,
                created: false,
                ticket: {},
                messages: [],
                logs: [],
                attachments: [],
                newAttachments: [],
                editor: []
            });
        }
        case rboxTicketWebsocketCommands.RBOX_TICKET_LOAD_DATA: {
            return Object.assign({}, state, {});
        }
        case rboxTicketWebsocketCommands.RBOX_TICKET_UPDATE_PENDING:
        case rboxTicketWebsocketCommands.RBOX_TICKET_CREATE_PENDING: {
            const { newAttachments } = action.payload.payload;
            return Object.assign({}, state, {
                newAttachments,
                isTicketCreating: true,
                isTicketLoading: true,
                messages: [],
                attachments: [],
                logs: [],
            });
        }

        case rboxTicketWebsocketCommands.RBOX_TICKET_CREATED:
        case rboxTicketWebsocketCommands.RBOX_TICKET_UPDATED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketCreating: false,
                created: true,
                isTicketLoading: true,
                correlationId
            });
        }

        case rboxTicketWebsocketCommands.RBOX_TICKET_CREATE_SEND:
        case rboxTicketWebsocketCommands.RBOX_TICKET_UPDATE_SEND:
        case rboxTicketWebsocketCommands.RBOX_TICKET_DETAIL_SEND: {
            const { correlationId, payload, error } = action.payload;
            const { ticket, attachments, logs, messages, editor = [] } = payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isTicketLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isTicketLoading: false,
                correlationId,
                ticket,
                messages: messages && messages.length > 0 ? messages.reverse() : [],
                attachments,
                logs,
                editor
            });
        }
        case rboxTicketWebsocketCommands.RBOX_TICKET_CREATE_SEND_ERROR:
        case rboxTicketWebsocketCommands.RBOX_TICKET_UPDATE_SEND_ERROR:
        case rboxTicketWebsocketCommands.RBOX_TICKET_DETAIL_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketCreating: false,
                isTicketLoading: false,
                correlationId,
            });
        }

        case rboxTicketWebsocketCommands.RBOX_TICKET_CREATE_ERROR:
        case rboxTicketWebsocketCommands.RBOX_TICKET_UPDATE_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketCreating: false,
                created: false,
                isTicketLoading: false,
                correlationId,
            });
        }

        case rboxTicketWebsocketCommands.RBOX_TICKET_DETAIL_PENDING: {
            return Object.assign({}, state, { isTicketLoading: true });
        }

        case rboxTicketWebsocketCommands.RBOX_TICKET_SEND: {
            const { payload, error, correlationId } = action.payload;
            const { ticket, attachments, messages, logs, editor = [] } = payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isTicketLoading: false, correlationId });
            }
            return Object.assign({}, state, {
                isTicketLoading: false,
                correlationId,
                ticket,
                attachments,
                logs,
                messages: messages && messages.length > 0 ? messages.reverse() : [],
                editor
            });
        }
        case rboxTicketWebsocketCommands.RBOX_TICKET_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isTicketLoading: false, correlationId });

        }
        case RBOX_ATTACHMENTS_SAVED_ERROR: {
            return Object.assign({}, state, {
                newAttachments: [],
            });
        }
        case RBOX_ATTACHMENTS_SAVED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketLoading: true,
                correlationId,
                newAttachments: [],
            });
        }
        case rboxTicketWebsocketCommands.TICKET_SAVE_NEW_ERROR:
        case rboxTicketWebsocketCommands.TICKET_SAVE_UPDATE_ERROR: {
            return Object.assign({}, state, { isLoading: false });
        }
        default:
            return state;
    }
}

export const lockTicketCheck = (state = {
    correlationId: null,
    isTicketLocking: false,
    redirect: false
}, action) => {
    switch (action.type) {
        case rboxTicketWebsocketCommands.RBOX_TICKET_LOCKED_ERROR:
        case rboxTicketWebsocketCommands.RBOX_TICKET_LOCKED_FATAL: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketLocking: false,
                redirect: true,
            });
        }
        case RBOX_RESET_REDIRECT: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case rboxTicketWebsocketCommands.RBOX_TICKET_LOCKED: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case RBOX_SET_LOCK_TICKET: {
            const { uuid } = action.payload.payload;
            return Object.assign({}, state, {
                isTicketLocking: true,
                redirect: false,
                uuid,
            });
        }
        default:
            return state;
    }
};

export const countrySettings = (state = {
    correlationId: null,
    isLoading: false,
    flow: null
}, action) => {
    switch (action.type) {
        case rboxTicketWebsocketCommands.RBOX_SETTINGS_COUNTRY_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case rboxTicketWebsocketCommands.RBOX_SETTINGS_COUNTRY_SEND: {
            const { correlationId, flow } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                flow,
                isLoading: false,
                correlationId: correlationId
            });
        }
        case rboxTicketWebsocketCommands.RBOX_SETTINGS_COUNTRY_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isLoading: false,
            });
        }
        default:
            return state;
    }
};
