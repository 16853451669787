export const rboxWebsocketCommands = {
    RBOX_CHECK_DEALER_LDAP_ROLE: 'checkLdapDealerRole',
    RBOX_ADMIN_ROLE_GET: 'getAdminRole',
    RBOX_ADMIN_ROLE_SET: 'setAdminRole',
    RBOX_DEALER_ROLE_GET: 'getDealerRole',
    RBOX_DEALER_ROLE_SET: 'setDealerRole',
    RBOX_DEALER_RESPONSIBILITY_SET: 'setDealerResponsibility',
    RBOX_DEALER_RESPONSIBILITY_GET: 'getDealerRole',
    RBOX_EDITOR_ROLE_GET: 'getEditorRole',
    RBOX_EDITOR_ACTION_ROLE_GET: 'getEditorActionRole',
    RBOX_EDITOR_ROLE_SET: 'setEditorRole',
    RBOX_EDITOR_ACTION_ROLE_SET: 'setEditorActionRole',
    RBOX_USER_LIST_PREFERENCE_SET: 'setUserListPreference',
    RBOX_USER_NOTIFICATION_LIST_PREFERENCE_SET: 'setUserNotificationListPreference',
    RBOX_PREFERENCE_GET: 'getPreferences',
    RBOX_ROLES_ERROR: 'RBOX_ROLES_ERROR',
    RBOX_ADMIN_ROLE_SEND: 'RBOX_ADMIN_ROLE_SEND',
    RBOX_ADMIN_ROLE_SEND_ERROR: 'RBOX_ADMIN_ROLE_SEND_ERROR',
    RBOX_EDITOR_ROLE_SEND: 'RBOX_EDITOR_ROLE_SEND',
    RBOX_EDITOR_ROLE_SEND_ERROR: 'RBOX_EDITOR_ROLE_SEND_ERROR',
    RBOX_EDITOR_ACTION_ROLE_SEND: 'RBOX_EDITOR_ACTION_ROLE_SEND',
    RBOX_EDITOR_ACTION_ROLE_SEND_ERROR: 'RBOX_EDITOR_ACTION_ROLE_SEND_ERROR',
    RBOX_DEALER_ROLE_SEND: 'RBOX_DEALER_ROLE_SEND',
    RBOX_DEALER_ROLE_SEND_ERROR: 'RBOX_DEALER_ROLE_SEND_ERROR',

    RBOX_DEALER_RESPONSIBILITY_SEND: 'RBOX_DEALER_RESPONSIBILITY_SEND',
    RBOX_DEALER_ROLE_RESPONSIBILITY_ERROR: 'RBOX_DEALER_RESPONSIBILITY_SEND_ERROR',

    RBOX_PREFERENCES_ERROR: 'RBOX_PREFERENCES_ERROR',
    RBOX_PREFERENCES_SEND: 'RBOX_PREFERENCES_SEND',
    RBOX_PREFERENCES_SEND_ERROR: 'RBOX_PREFERENCES_SEND_ERROR',

    RBOX_ROLES_PENDING: 'RBOX_ROLES_PENDING',
    RBOX_ROLES_NOT_TODO: 'RBOX_ROLES_NOT_TODO',
    RBOX_DEALER_ROLE_CHECK_SEND: 'RBOX_DEALER_ROLE_CHECK_SEND',
    RBOX_DEALER_ROLE_CHECK_SEND_ERROR: 'RBOX_DEALER_ROLE_CHECK_SEND_ERROR',

    RBOX_USER_NOTIFICATION_PREFERENCE_GET: 'getUserNotificationPreference',
    RBOX_USER_NOTIFICATION_PREFERENCE_SET: 'setUserNotificationPreference',

    RBOX_NOTIFICATIONS_SET: 'setEmailNotifications',
    RBOX_NOTIFICATIONS_GET: 'getEmailNotifications',

    RBOX_NOTIFICATIONS_SEND: 'RBOX_NOTIFICATIONS_SEND',
    RBOX_NOTIFICATIONS_ERROR: 'RBOX_NOTIFICATIONS_ERROR',
    RBOX_NOTIFICATIONS_SEND_ERROR: 'RBOX_NOTIFICATIONS_SEND_ERROR',

    RBOX_NOTIFICATIONS_PREFERENCES_SEND: 'RBOX_NOTIFICATIONS_PREFERENCES_SEND',
    RBOX_NOTIFICATIONS_PREFERENCES_SEND_ERROR: 'RBOX_NOTIFICATIONS_PREFERENCES_SEND_ERROR',

    RBOX_CENTRAL_ROLE_GET: 'getCentralRole',
    RBOX_CENTRAL_ROLE_SET: 'setCentralRole',
    RBOX_CENTRAL_ROLE_SEND: 'RBOX_CENTRAL_ROLE_SEND',
    RBOX_CENTRAL_ROLE_SEND_ERROR: 'RBOX_CENTRAL_ROLE_SEND_ERROR',

    RBOX_EDITOR_TEAM_ROLE_GET: 'getEditorTeamRole',
    RBOX_EDITOR_TEAM_ROLE_SET: 'setEditorTeamRole',
    RBOX_EDITOR_TEAM_ROLE_SEND: 'RBOX_EDITOR_TEAM_ROLE_SEND',
    RBOX_EDITOR_TEAM_ROLE_SEND_ERROR: 'RBOX_EDITOR_TEAM_ROLE_SEND_ERROR',
};
