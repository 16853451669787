import {defineMessages} from 'react-intl';

const teamRoleMessages = defineMessages({
    CENTRAL_TEAM: {
        id: 'editor.teamRole.centralTeam',
        defaultMessage: 'Central Team'
    },
});

export default teamRoleMessages;
