import { errorAlert } from '../alertsActions';
import {
    validateAdminRoles, validateDealerResponsibility,
    validateDealerRoles,
    validateEditorActionRoles,
    validateEditorRoles, validateEditorTeamRoleData
} from '../../api/validation/role';
import { rboxWebsocketCommands } from '../../constants/rboxWebsocketCommands';

export const RBOX_MANAGED_USER = 'RBOX_MANAGED_USER';
export const RBOX_MANAGED_USER_RESET = 'RBOX_MANAGED_USER_RESET';

export const RBOX_INITIAL_ADMIN_GROUP_OPTIONS = 'RBOX_INITIAL_ADMIN_GROUP_OPTIONS';
export const RBOX_SELECTED_ADMIN_GROUP_OPTIONS = 'RBOX_SELECTED_ADMIN_GROUP_OPTIONS';
export const RBOX_ADMIN_GROUPS_CHANGED = 'RBOX_ADMIN_GROUPS_CHANGED';
export const RBOX_ADMIN_RIGHTS_FETCH_PENDING = 'RBOX_ADMIN_RIGHTS_FETCH_PENDING';
export const RBOX_ADMIN_RIGHTS_SAVE_PENDING = 'RBOX_ADMIN_RIGHTS_SAVE_PENDING';
export const RBOX_ADMIN_RIGHTS_CLEAR = 'RBOX_ADMIN_RIGHTS_CLEAR';
export const RBOX_ADMIN_RIGHTS_FETCH_SUCCESS = 'RBOX_ADMIN_RIGHTS_FETCH_SUCCESS';

export const RBOX_INITIAL_DEALER_RESPONSIBILITY_OPTIONS = 'RBOX_INITIAL_DEALER_RESPONSIBILITY_OPTIONS';

export const RBOX_INITIAL_DEALER_GROUP_OPTIONS = 'RBOX_INITIAL_DEALER_GROUP_OPTIONS';
export const RBOX_SELECTED_DEALER_GROUP_OPTIONS = 'RBOX_SELECTED_DEALER_GROUP_OPTIONS';
export const RBOX_DEALER_GROUPS_CHANGED = 'RBOX_DEALER_GROUPS_CHANGED';
export const RBOX_DEALER_RIGHTS_FETCH_PENDING = 'RBOX_DEALER_RIGHTS_FETCH_PENDING';
export const RBOX_DEALER_RIGHTS_SAVE_PENDING = 'RBOX_DEALER_RIGHTS_SAVE_PENDING';
export const RBOX_DEALER_RIGHTS_CLEAR = 'RBOX_DEALER_RIGHTS_CLEAR';
export const RBOX_DEALER_RIGHTS_FETCH_SUCCESS = 'RBOX_DEALER_RIGHTS_FETCH_SUCCESS';
export const RBOX_RIGHTS_ERROR = 'RBOX_RIGHTS_ERROR';

export const RBOX_INITIAL_EDITOR_GROUP_OPTIONS = 'RBOX_INITIAL_EDITOR_GROUP_OPTIONS';
export const RBOX_SELECTED_EDITOR_GROUP_OPTIONS = 'RBOX_SELECTED_EDITOR_GROUP_OPTIONS';
export const RBOX_EDITOR_GROUPS_CHANGED = 'RBOX_EDITOR_GROUPS_CHANGED';
export const RBOX_EDITOR_RIGHTS_FETCH_PENDING = 'RBOX_EDITOR_RIGHTS_FETCH_PENDING';
export const RBOX_EDITOR_RIGHTS_SAVE_PENDING = 'RBOX_EDITOR_RIGHTS_SAVE_PENDING';
export const RBOX_EDITOR_RIGHTS_CLEAR = 'RBOX_EDITOR_RIGHTS_CLEAR';
export const RBOX_EDITOR_RIGHTS_FETCH_SUCCESS = 'RBOX_EDITOR_RIGHTS_FETCH_SUCCESS';

export const RBOX_INITIAL_CENTRAL_GROUP_OPTIONS = 'RBOX_INITIAL_CENTRAL_GROUP_OPTIONS';
export const RBOX_SELECTED_CENTRAL_GROUP_OPTIONS = 'RBOX_SELECTED_CENTRAL_GROUP_OPTIONS';
export const RBOX_CENTRAL_GROUPS_CHANGED = 'RBOX_CENTRAL_GROUPS_CHANGED';
export const RBOX_CENTRAL_RIGHTS_FETCH_PENDING = 'RBOX_CENTRAL_RIGHTS_FETCH_PENDING';
export const RBOX_CENTRAL_RIGHTS_SAVE_PENDING = 'RBOX_CENTRAL_RIGHTS_SAVE_PENDING';
export const RBOX_CENTRAL_RIGHTS_CLEAR = 'RBOX_CENTRAL_RIGHTS_CLEAR';
export const RBOX_CENTRAL_RIGHTS_FETCH_SUCCESS = 'RBOX_CENTRAL_RIGHTS_FETCH_SUCCESS';

export const RBOX_INITIAL_EDITOR_ACTION_OPTIONS = 'RBOX_INITIAL_EDITOR_ACTION_OPTIONS';
export const RBOX_SELECTED_EDITOR_ACTION_OPTIONS = 'RBOX_SELECTED_EDITOR_ACTION_OPTIONS';
export const RBOX_EDITOR_ACTIONS_CHANGED = 'RBOX_EDITOR_ACTIONS_CHANGED';
export const RBOX_EDITOR_ACTION_RIGHTS_FETCH_PENDING = 'RBOX_EDITOR_ACTION_RIGHTS_FETCH_PENDING';
export const RBOX_EDITOR_ACTION_RIGHTS_SAVE_PENDING = 'RBOX_EDITOR_ACTION_RIGHTS_SAVE_PENDING';
export const RBOX_EDITOR_ACTION_RIGHTS_CLEAR = 'RBOX_EDITOR_ACTION_RIGHTS_CLEAR';
export const RBOX_EDITOR_ACTION_RIGHTS_FETCH_SUCCESS = 'RBOX_EDITOR_ACTION_RIGHTS_FETCH_SUCCESS';

export const RBOX_INITIAL_EDITOR_TEAM_ROLE_OPTIONS = 'RBOX_INITIAL_EDITOR_TEAM_ROLE_OPTIONS';
export const RBOX_SELECTED_EDITOR_TEAM_ROLE_OPTIONS = 'RBOX_SELECTED_EDITOR_TEAM_ROLE_OPTIONS';
export const RBOX_EDITOR_TEAM_ROLE_CHANGED = 'RBOX_EDITOR_TEAM_ROLE_CHANGED';
export const RBOX_EDITOR_TEAM_ROLE_FETCH_PENDING = 'RBOX_EDITOR_TEAM_ROLE_FETCH_PENDING';
export const RBOX_EDITOR_TEAM_ROLE_SAVE_PENDING = 'RBOX_EDITOR_TEAM_ROLE_SAVE_PENDING';
export const RBOX_EDITOR_TEAM_ROLE_CLEAR = 'RBOX_EDITOR_TEAM_ROLE_CLEAR';
export const RBOX_EDITOR_TEAM_ROLE_FETCH_SUCCESS = 'RBOX_EDITOR_TEAM_ROLE_FETCH_SUCCESS';

export const resetManageUser = () => dispatch => {
    return dispatch({
        type: RBOX_MANAGED_USER_RESET,
    });
};

export const setManagedUser = managedUser => ({
    type: RBOX_MANAGED_USER,
    payload: managedUser
});

export const setInitialAdminGroupOptions = groupOptions => ({
    type: RBOX_INITIAL_ADMIN_GROUP_OPTIONS,
    payload: groupOptions
});

export const setSelectedAdminGroupOptions = groupOptions => ({
    type: RBOX_SELECTED_ADMIN_GROUP_OPTIONS,
    payload: groupOptions
});

export const setAdminGroupsChanged = adminGroupsChanged => ({
    type: RBOX_ADMIN_GROUPS_CHANGED,
    payload: adminGroupsChanged
});

export const fetchAdminRightsManagementPageData = (user, domain) => dispatch => {
    return dispatch({
        type: RBOX_ADMIN_RIGHTS_FETCH_PENDING,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_ADMIN_ROLE_GET,
        payload: { payload: { ipn: user.ipn } }
    });
};

export const saveAdminRightsManagementPageData = (user, domain, adminData) => dispatch => {
    const { error } = validateAdminRoles(adminData);
    if (error) {
        dispatch({ type: RBOX_RIGHTS_ERROR });
        dispatch(errorAlert(`Cannot send invalid admin rights to backend`, [error.toString()]));
    } else {
        return dispatch({
            type: RBOX_ADMIN_RIGHTS_SAVE_PENDING,
            rboxWebsocket: true,
            command: rboxWebsocketCommands.RBOX_ADMIN_ROLE_SET,
            payload: { payload: { admin: { groupPermission: adminData }, id: user.ipn } }
        });

    }
};

export const clearAdminRightsManagementPageData = () => ({
    type: RBOX_ADMIN_RIGHTS_CLEAR
});

export const setInitialDealerGroupOptions = groupOptions => ({
    type: RBOX_INITIAL_DEALER_GROUP_OPTIONS,
    payload: groupOptions
});

export const setSelectedDealerGroupOptions = groupOptions => ({
    type: RBOX_SELECTED_DEALER_GROUP_OPTIONS,
    payload: groupOptions
});

export const setDealerGroupsChanged = dealerGroupsChanged => ({
    type: RBOX_DEALER_GROUPS_CHANGED,
    payload: dealerGroupsChanged
});

export const fetchDealerRightsManagementPageData = (user, domain) => dispatch => {
    return dispatch({
        type: RBOX_DEALER_RIGHTS_FETCH_PENDING,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_DEALER_ROLE_GET,
        payload: { payload: { ipn: user.ipn } }
    });
};

export const saveDealerRightsManagementPageData = (user, domain, dealerData) => dispatch => {
    const { error } = validateDealerRoles(dealerData);
    if (error) {
        dispatch({ type: RBOX_RIGHTS_ERROR });
        dispatch(errorAlert(`Cannot send invalid dealer rights to backend`, [error.toString()]));
    } else {
        return dispatch({
            type: RBOX_DEALER_RIGHTS_SAVE_PENDING,
            rboxWebsocket: true,
            command: rboxWebsocketCommands.RBOX_DEALER_ROLE_SET,
            payload: { payload: { dealer: { groupPermission: dealerData }, id: user.ipn } }
        });

    }
};

export const clearDealerRightsManagementPageData = () => ({
    type: RBOX_DEALER_RIGHTS_CLEAR
});

export const saveDealerResponsibilityManagementPageData = (user, domain, dealerData) => dispatch => {
    console.log(dealerData);
    const { error } = validateDealerResponsibility(dealerData);
    if (error) {
        dispatch({ type: RBOX_RIGHTS_ERROR });
        dispatch(errorAlert(`Cannot send invalid dealer responsibility to backend`, [error.toString()]));
    } else {
        return dispatch({
            type: RBOX_DEALER_RIGHTS_SAVE_PENDING,
            rboxWebsocket: true,
            command: rboxWebsocketCommands.RBOX_DEALER_RESPONSIBILITY_SET,
            payload: { payload: { ...dealerData, id: user.ipn } }
        });
    }
};

export const setInitialEditorGroupOptions = groupOptions => ({
    type: RBOX_INITIAL_EDITOR_GROUP_OPTIONS,
    payload: groupOptions
});

export const setSelectedEditorGroupOptions = groupOptions => ({
    type: RBOX_SELECTED_EDITOR_GROUP_OPTIONS,
    payload: groupOptions
});

export const setEditorGroupsChanged = editorGroupsChanged => ({
    type: RBOX_EDITOR_GROUPS_CHANGED,
    payload: editorGroupsChanged
});

export const setInitialEditorActionOptions = groupOptions => ({
    type: RBOX_INITIAL_EDITOR_ACTION_OPTIONS,
    payload: groupOptions
});

export const setSelectedEditorActionOptions = groupOptions => ({
    type: RBOX_SELECTED_EDITOR_ACTION_OPTIONS,
    payload: groupOptions
});

export const setEditorActionChanged = editorActionsChanged => ({
    type: RBOX_EDITOR_ACTIONS_CHANGED,
    payload: editorActionsChanged
});

export const fetchEditorRightsManagementPageData = (user, domain) => dispatch => {
    return dispatch({
        type: RBOX_EDITOR_RIGHTS_FETCH_PENDING,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_EDITOR_ROLE_GET,
        payload: { payload: { ipn: user.ipn } }
    });
};

export const fetchEditorActionRightsManagementPageData = (user, domain) => dispatch => {
    return dispatch({
        type: RBOX_EDITOR_ACTION_RIGHTS_FETCH_PENDING,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_EDITOR_ACTION_ROLE_GET,
        payload: { payload: { ipn: user.ipn } }
    });
};

export const saveEditorRightsManagementPageData = (user, domain, editorData, editorActionData, editorTeam) => dispatch => {
    if (editorData) {
        const { error } = validateEditorRoles(editorData);
        if (error) {
            dispatch({ type: RBOX_RIGHTS_ERROR });
            dispatch(errorAlert(`Cannot send invalid editor rights to backend`, [error.toString()]));
        } else {
            return dispatch({
                type: RBOX_EDITOR_RIGHTS_SAVE_PENDING,
                rboxWebsocket: true,
                command: rboxWebsocketCommands.RBOX_EDITOR_ROLE_SET,
                payload: { payload: { editor: { groupPermission: editorData }, id: user.ipn } }
            });

        }
    } else if (editorActionData) {
        const { error } = validateEditorActionRoles(editorActionData);
        if (error) {
            dispatch({ type: RBOX_RIGHTS_ERROR });
            dispatch(errorAlert(`Cannot send invalid editor rights to backend`, [error.toString()]));
        } else {
            return dispatch({
                type: RBOX_EDITOR_ACTION_RIGHTS_SAVE_PENDING,
                rboxWebsocket: true,
                command: rboxWebsocketCommands.RBOX_EDITOR_ACTION_ROLE_SET,
                payload: { payload: { editor: { actionPermission: editorActionData }, id: user.ipn } }
            });

        }
    } else if (editorTeam) {
        const { error } = validateEditorTeamRoleData(editorTeam);
        if (error) {
            dispatch({ type: RBOX_RIGHTS_ERROR });
            dispatch(errorAlert(`Cannot send invalid editor team to backend`, [error.toString()]));
        } else {
            return dispatch({
                type: RBOX_EDITOR_TEAM_ROLE_SAVE_PENDING,
                rboxWebsocket: true,
                command: rboxWebsocketCommands.RBOX_EDITOR_TEAM_ROLE_SET,
                payload: { payload: { editor: { teamPermission: editorTeam }, id: user.ipn } }
            });

        }
    }
};

export const clearEditorRightsManagementPageData = () => ({
    type: RBOX_EDITOR_RIGHTS_CLEAR
});
export const clearEditorActionRightsManagementPageData = () => ({
    type: RBOX_EDITOR_ACTION_RIGHTS_CLEAR
});


export const fetchCentralRightsManagementPageData = (user, domain) => dispatch => {
    return dispatch({
        type: RBOX_CENTRAL_RIGHTS_FETCH_PENDING,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_CENTRAL_ROLE_GET,
        payload: { payload: { ipn: user.ipn } }
    });
};

export const saveCentralRightsManagementPageData = (user, domain, centralData) => dispatch => {
    if (centralData) {
        const { error } = validateEditorRoles(centralData);
        if (error) {
            dispatch({ type: RBOX_RIGHTS_ERROR });
            dispatch(errorAlert(`Cannot send invalid central editor rights to backend`, [error.toString()]));
        } else {
            return dispatch({
                type: RBOX_CENTRAL_RIGHTS_SAVE_PENDING,
                rboxWebsocket: true,
                command: rboxWebsocketCommands.RBOX_CENTRAL_ROLE_SET,
                payload: { payload: { central: { groupPermission: centralData }, id: user.ipn } }
            });

        }
    }
};

export const setSelectedCentralGroupOptions = groupOptions => ({
    type: RBOX_SELECTED_CENTRAL_GROUP_OPTIONS,
    payload: groupOptions
});

export const setCentralGroupsChanged = centralGroupsChanged => ({
    type: RBOX_CENTRAL_GROUPS_CHANGED,
    payload: centralGroupsChanged
});

export const clearCentralRightsManagementPageData = () => ({
    type: RBOX_CENTRAL_RIGHTS_CLEAR
});

export const fetchEditorTeamRolesManagementPageData = (user, domain) => dispatch => {
    return dispatch({
        type: RBOX_EDITOR_TEAM_ROLE_FETCH_PENDING,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_EDITOR_TEAM_ROLE_GET,
        payload: { payload: { ipn: user.ipn } }
    });
};

export const clearEditorTeamRolesManagementPageData = () => ({
    type: RBOX_EDITOR_TEAM_ROLE_CLEAR
});

export const setEditorTeamRolesChanged = editorTeamRolesChanged => ({
    type: RBOX_EDITOR_TEAM_ROLE_CHANGED,
    payload: editorTeamRolesChanged
});

export const setInitialEditorTeamRolesOptions = groupOptions => ({
    type: RBOX_INITIAL_EDITOR_TEAM_ROLE_OPTIONS,
    payload: groupOptions
});

export const setSelectedEditorTeamRolesOptions = groupOptions => ({
    type: RBOX_SELECTED_EDITOR_TEAM_ROLE_OPTIONS,
    payload: groupOptions
});
