import { rboxWebsocketCommands } from '../../constants/rboxWebsocketCommands';
import {
    RBOX_ADMIN_GROUPS_CHANGED,
    RBOX_ADMIN_RIGHTS_CLEAR,
    RBOX_ADMIN_RIGHTS_FETCH_PENDING,
    RBOX_ADMIN_RIGHTS_FETCH_SUCCESS,
    RBOX_ADMIN_RIGHTS_SAVE_PENDING,
    RBOX_CENTRAL_GROUPS_CHANGED,
    RBOX_CENTRAL_RIGHTS_CLEAR,
    RBOX_CENTRAL_RIGHTS_FETCH_PENDING,
    RBOX_CENTRAL_RIGHTS_FETCH_SUCCESS,
    RBOX_CENTRAL_RIGHTS_SAVE_PENDING,
    RBOX_DEALER_GROUPS_CHANGED,
    RBOX_DEALER_RIGHTS_CLEAR,
    RBOX_DEALER_RIGHTS_FETCH_PENDING,
    RBOX_DEALER_RIGHTS_FETCH_SUCCESS,
    RBOX_DEALER_RIGHTS_SAVE_PENDING,
    RBOX_EDITOR_ACTION_RIGHTS_CLEAR,
    RBOX_EDITOR_ACTION_RIGHTS_FETCH_PENDING,
    RBOX_EDITOR_ACTION_RIGHTS_FETCH_SUCCESS,
    RBOX_EDITOR_ACTION_RIGHTS_SAVE_PENDING,
    RBOX_EDITOR_ACTIONS_CHANGED,
    RBOX_EDITOR_GROUPS_CHANGED,
    RBOX_EDITOR_RIGHTS_CLEAR,
    RBOX_EDITOR_RIGHTS_FETCH_PENDING,
    RBOX_EDITOR_RIGHTS_FETCH_SUCCESS,
    RBOX_EDITOR_RIGHTS_SAVE_PENDING,
    RBOX_EDITOR_TEAM_ROLE_CHANGED, RBOX_EDITOR_TEAM_ROLE_CLEAR,
    RBOX_EDITOR_TEAM_ROLE_FETCH_PENDING,
    RBOX_EDITOR_TEAM_ROLE_FETCH_SUCCESS, RBOX_EDITOR_TEAM_ROLE_SAVE_PENDING,
    RBOX_INITIAL_ADMIN_GROUP_OPTIONS,
    RBOX_INITIAL_CENTRAL_GROUP_OPTIONS,
    RBOX_INITIAL_DEALER_GROUP_OPTIONS,
    RBOX_INITIAL_DEALER_RESPONSIBILITY_OPTIONS,
    RBOX_INITIAL_EDITOR_ACTION_OPTIONS,
    RBOX_INITIAL_EDITOR_GROUP_OPTIONS,
    RBOX_INITIAL_EDITOR_TEAM_ROLE_OPTIONS,
    RBOX_MANAGED_USER,
    RBOX_MANAGED_USER_RESET,
    RBOX_RIGHTS_ERROR,
    RBOX_SELECTED_ADMIN_GROUP_OPTIONS,
    RBOX_SELECTED_CENTRAL_GROUP_OPTIONS,
    RBOX_SELECTED_DEALER_GROUP_OPTIONS,
    RBOX_SELECTED_EDITOR_ACTION_OPTIONS,
    RBOX_SELECTED_EDITOR_GROUP_OPTIONS,
    RBOX_SELECTED_EDITOR_TEAM_ROLE_OPTIONS,
} from '../../actions/admin/actionAdminRightsManagement';

export const adminRightsManagement = (state = {
    isLoading: false,
    managedUser: {},
    adminData: {},
    initialAdminOptions: [],
    selectedAdminOptions: null,
    adminGroupsChanged: false,
    dealerData: {
        groupPermission: {},
        responsibilityPermission: []
    },
    initialDealerOptions: [],
    initialDealerResponsibilityOptions: [],
    selectedDealerOptions: null,
    dealerGroupsChanged: false,
    editorData: {},
    initialEditorGroupOptions: [],
    selectedEditorGroupOptions: null,
    editorGroupsChanged: false,
    editorActionData: {},
    initialEditorActionOptions: [],
    selectedEditorActionOptions: null,
    editorActionsChanged: false,
    editorTeamRoleData: {},
    initialEditorTeamRoleOptions: [],
    selectedEditorTeamRoleOptions: null,
    editorTeamRoleChanged: false,
    centralData: {},
    initialCentralGroupOptions: [],
    selectedCentralGroupOptions: null,
    centralGroupsChanged: false,
}, action) => {
    switch (action.type) {
        case RBOX_MANAGED_USER: {
            return Object.assign({}, state, {
                adminGroupsChanged: false,
                dealerGroupsChanged: false,
                editorGroupsChanged: false,
                editorActionsChanged: false,
                editorTeamRoleChanged: false,
                managedUser: action.payload
            });
        }
        case RBOX_MANAGED_USER_RESET: {
            return Object.assign({}, state, {
                isLoading: false,
                managedUser: {},
                adminData: {},
                initialAdminOptions: [],
                selectedAdminOptions: null,
                adminGroupsChanged: false,
                dealerData: {
                    groupPermission: {},
                    responsibilityPermission: []
                },
                initialDealerOptions: [],
                initialDealerResponsibilityOptions: [],
                selectedDealerOptions: null,
                dealerGroupsChanged: false,
                editorData: {},
                initialEditorGroupOptions: [],
                selectedEditorGroupOptions: null,
                editorGroupsChanged: false,
                editorActionData: {},
                initialEditorActionOptions: [],
                selectedEditorActionOptions: null,
                editorActionsChanged: false,
                editorTeamRoleData: {},
                initialEditorTeamRoleOptions: [],
                selectedEditorTeamRoleOptions: null,
                editorTeamRoleChanged: false,
            });
        }
        case RBOX_ADMIN_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_ADMIN_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                adminData: action.payload.finalResult,
                correlationId: action.payload.correlationId
            });
        }
        case rboxWebsocketCommands.RBOX_ADMIN_ROLE_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, correlationId });
        }
        case RBOX_INITIAL_ADMIN_GROUP_OPTIONS: {
            return Object.assign({}, state, { initialAdminOptions: action.payload, })
        }
        case RBOX_SELECTED_ADMIN_GROUP_OPTIONS: {
            return Object.assign({}, state, { selectedAdminOptions: action.payload })
        }
        case RBOX_ADMIN_GROUPS_CHANGED: {
            return Object.assign({}, state, { adminGroupsChanged: action.payload })
        }
        case RBOX_ADMIN_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_ADMIN_RIGHTS_CLEAR: {
            return Object.assign({}, state, { adminData: {} });
        }
        case RBOX_DEALER_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_DEALER_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                dealerData: {
                    responsibilityPermission: action.payload.responsibilityPermission,
                    groupPermission: action.payload.groupPermission,
                },
                dealerCorrelationId: action.payload.correlationId
            });
        }
        case RBOX_EDITOR_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_EDITOR_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                editorData: action.payload.finalResult,
                editorCorrelationId: action.payload.correlationId
            });
        }
        case RBOX_EDITOR_ACTION_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_EDITOR_ACTION_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                editorActionData: action.payload.finalResult,
                editorActionCorrelationId: action.payload.correlationId
            });
        }
        case rboxWebsocketCommands.RBOX_DEALER_ROLE_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, dealerCorrelationId: correlationId });
        }
        case RBOX_INITIAL_DEALER_GROUP_OPTIONS: {
            return Object.assign({}, state, { initialDealerOptions: action.payload, })
        }
        case RBOX_INITIAL_DEALER_RESPONSIBILITY_OPTIONS: {
            return Object.assign({}, state, { initialDealerResponsibilityOptions: action.payload, })
        }
        case RBOX_SELECTED_DEALER_GROUP_OPTIONS: {
            return Object.assign({}, state, { selectedDealerOptions: action.payload })
        }
        case RBOX_DEALER_GROUPS_CHANGED: {
            return Object.assign({}, state, { dealerGroupsChanged: action.payload })
        }
        case RBOX_DEALER_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_DEALER_RIGHTS_CLEAR: {
            return Object.assign({}, state, {
                dealerData: {
                    groupPermission: {},
                    responsibilityPermission: []
                },
            });
        }

        case rboxWebsocketCommands.RBOX_EDITOR_ROLE_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                editorCorrelationId: correlationId
            });
        }
        case RBOX_INITIAL_EDITOR_GROUP_OPTIONS: {
            return Object.assign({}, state, { initialEditorGroupOptions: action.payload, })
        }
        case RBOX_SELECTED_EDITOR_GROUP_OPTIONS: {
            return Object.assign({}, state, { selectedEditorGroupOptions: action.payload })
        }
        case RBOX_EDITOR_GROUPS_CHANGED: {
            return Object.assign({}, state, { editorGroupsChanged: action.payload })
        }
        case RBOX_EDITOR_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_EDITOR_RIGHTS_CLEAR: {
            return Object.assign({}, state, { editorData: {} });
        }

        case rboxWebsocketCommands.RBOX_EDITOR_ACTION_ROLE_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                editorActionCorrelationId: correlationId
            });
        }
        case RBOX_INITIAL_EDITOR_ACTION_OPTIONS: {
            return Object.assign({}, state, { initialEditorActionOptions: action.payload, })
        }
        case RBOX_SELECTED_EDITOR_ACTION_OPTIONS: {
            return Object.assign({}, state, { selectedEditorActionOptions: action.payload })
        }
        case RBOX_EDITOR_ACTIONS_CHANGED: {
            return Object.assign({}, state, { editorActionsChanged: action.payload })
        }
        case RBOX_EDITOR_ACTION_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_EDITOR_TEAM_ROLE_CLEAR: {
            return Object.assign({}, state, { editorTeamRoleData: {} });
        }


        case RBOX_CENTRAL_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_CENTRAL_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                centralData: action.payload.finalResult,
                centralCorrelationId: action.payload.correlationId
            });
        }

        case rboxWebsocketCommands.RBOX_CENTRAL_ROLE_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                centralCorrelationId: correlationId
            });
        }

        case RBOX_INITIAL_CENTRAL_GROUP_OPTIONS: {
            return Object.assign({}, state, { initialCentralGroupOptions: action.payload, })
        }
        case RBOX_SELECTED_CENTRAL_GROUP_OPTIONS: {
            return Object.assign({}, state, { selectedCentralGroupOptions: action.payload })
        }
        case RBOX_CENTRAL_GROUPS_CHANGED: {
            return Object.assign({}, state, { centralGroupsChanged: action.payload })
        }
        case RBOX_CENTRAL_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_CENTRAL_RIGHTS_CLEAR: {
            return Object.assign({}, state, { centralData: {} });
        }

        case rboxWebsocketCommands.RBOX_ROLES_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, correlationId });
        }
        case RBOX_RIGHTS_ERROR: {
            const { correlationId } = action.payload;
            return Object.assign({}, state, { isLoading: false, correlationId });
        }
        case RBOX_EDITOR_TEAM_ROLE_FETCH_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_EDITOR_TEAM_ROLE_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                editorTeamRoleData: action.payload.finalResult,
                editorTeamRoleCorrelationId: action.payload.correlationId
            });
        }
        case RBOX_INITIAL_EDITOR_TEAM_ROLE_OPTIONS: {
            return Object.assign({}, state, { initialEditorTeamRoleOptions: action.payload, })
        }
        case RBOX_SELECTED_EDITOR_TEAM_ROLE_OPTIONS: {
            return Object.assign({}, state, { selectedEditorTeamRoleOptions: action.payload })
        }
        case RBOX_EDITOR_TEAM_ROLE_CHANGED: {
            return Object.assign({}, state, { editorTeamRoleChanged: action.payload })
        }
        case RBOX_EDITOR_TEAM_ROLE_SAVE_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case RBOX_EDITOR_ACTION_RIGHTS_CLEAR: {
            return Object.assign({}, state, { editorActionData: {} });
        }

        default: {
            return state;
        }
    }
};
