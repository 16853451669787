import {defineMessages} from 'react-intl';

const messages = defineMessages({
    CREATE_TICKET: {
        id: 'editor.role.createTicket',
        defaultMessage: 'Create ticket'
    },
});

export default messages;
