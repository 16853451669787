import { RBOX_TICKET_FORM, RBOX_TICKET_FORM_EDIT } from './formConstants';
import { ticketConstants } from './form/formTicketConstants';
import { dealerConstants } from './form/formDealerConstants';

export const dealerResponsibilityReqFields = {
    [dealerConstants.DEALER_RESPONSIBILITY_FORM]: [
        dealerConstants.FORM_FIELD_DEALER_NUMBER,
        dealerConstants.FORM_FIELD_EMAIL,
    ]
}

export const editReqFields = {
    'EDITOR': {
        [RBOX_TICKET_FORM_EDIT]: [
            ticketConstants.FORM_FIELD_IMPACT,
            ticketConstants.FORM_FIELD_URGENCY,
            ticketConstants.FORM_FIELD_PRIORITY,
            ticketConstants.FORM_FIELD_ASSOCIATED_ITEMS,
            ticketConstants.FORM_FIELD_DESCRIPTION,
            ticketConstants.FORM_FIELD_SUBJECT
        ]
    },
    'DEALER': {
        [RBOX_TICKET_FORM_EDIT]: [
            ticketConstants.FORM_FIELD_IMPACT,
            ticketConstants.FORM_FIELD_URGENCY,
            ticketConstants.FORM_FIELD_PRIORITY,
            ticketConstants.FORM_FIELD_ASSOCIATED_ITEMS,
            ticketConstants.FORM_FIELD_DESCRIPTION,
            ticketConstants.FORM_FIELD_SUBJECT
        ]
    }
};

export const createReqFields = {
    'DEALER': {
        [RBOX_TICKET_FORM]: [
            ticketConstants.FORM_FIELD_IMPACT,
            ticketConstants.FORM_FIELD_URGENCY,
            ticketConstants.FORM_FIELD_PRIORITY,
            ticketConstants.FORM_FIELD_ASSOCIATED_ITEMS,
            ticketConstants.FORM_FIELD_DESCRIPTION,
            ticketConstants.FORM_FIELD_SUBJECT
        ]
    },
    'EDITOR': {
        [RBOX_TICKET_FORM]: [
            ticketConstants.FORM_FIELD_COUNTRY,
            ticketConstants.FORM_FIELD_IMPACT,
            ticketConstants.FORM_FIELD_URGENCY,
            ticketConstants.FORM_FIELD_PRIORITY,
            ticketConstants.FORM_FIELD_ASSOCIATED_ITEMS,
            ticketConstants.FORM_FIELD_DESCRIPTION,
            ticketConstants.FORM_FIELD_SUBJECT
        ]
    }
};

