import {dealerRoles, editorActionRoles, editorTeamRoles} from '../../constants/Utils';
import Joi from 'joi';
import get from 'get-value';
import { countries } from '../../constants/countries';

const validateCountry = roles => {
    let newRoles = roles;
    if (roles) {
            if (roles.admin) {
                if (roles.admin.groupPermission) {
                    Object.keys(countries).map(e => Object.keys(roles.admin.groupPermission)
                        .filter(i => roles.admin.groupPermission[i] === true).includes(e) ?
                        newRoles.admin.groupPermission[countries[e]] = true :
                        newRoles.admin.groupPermission[countries[e]] = false)
                }
            }
            if (roles.editor) {
                if (roles.editor.groupPermission) {
                    Object.keys(countries).map(e => Object.keys(roles.editor.groupPermission)
                        .filter(i => roles.editor.groupPermission[i] === true).includes(e) ?
                        newRoles.editor.groupPermission[countries[e]] = true :
                        newRoles.editor.groupPermission[countries[e]] = false)
                }
                if (roles.editor.actionPermission) {
                    Object.keys(editorActionRoles).map(e => Object.keys(roles.editor.actionPermission)
                        .filter(i => roles.editor.actionPermission[i] === true).includes(e) ?
                        newRoles.editor.actionPermission[editorActionRoles[e]] = true :
                        newRoles.editor.actionPermission[editorActionRoles[e]] = false)
                }
            }
        }
    return newRoles;
}


export function validateUserDetail(userDetail) {
    if (userDetail) validateCountry(get(userDetail, 'roles', {}))
    return Joi.validate(
        userDetail,
        Joi.object().required().keys({
            ipn: Joi.string().required(),
            name: Joi.string().required(),
            country: Joi.any(),
            roles: getRolesSchema(),
            settings: Joi.object().keys(getSettingsSchema()),
            dealerName: Joi.any(),
            dealerNumber: Joi.any(),
            telNo: Joi.any(),
            group: Joi.any(),
            mail: Joi.any(),
            charSets: Joi.array().required()
        })
    )
}

export function validateAdminRoles(adminRoles) {
    return Joi.validate(
        adminRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}

export function validateEditorRoles(editorRoles) {
    return Joi.validate(
        editorRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}

export function validateEditorActionRoles(editorRoles) {
    return Joi.validate(
        editorRoles,
        Joi.object().required().keys(getEditorActionSchema())
    );
}

export function validateEditorData(editorRoles) {
    return Joi.validate(
        editorRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}
export function validateEditorActionData(editorRoles) {
    return Joi.validate(
        editorRoles,
        Joi.object().required().keys(getEditorActionSchema())
    );
}
export function validateEditorTeamRoleData(editorRoles) {
    return Joi.validate(
        editorRoles,
        Joi.object().required().keys(getEditorTeamSchema())
    );
}

export function validateCentralData(centralRoles) {
    return Joi.validate(
        centralRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}

export function validateDealerRoles(adminRoles) {
    return Joi.validate(
        adminRoles,
        Joi.object().required().keys(getDealerGroupsSchema())
    );
}

export function validateDealerResponsibility(adminRoles) {
    return Joi.validate(
        adminRoles,
        Joi.object().required()
    );
}

function getRolesSchema() {
    return Joi.object().keys({
        admin: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        editor: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema()),
            actionPermission: Joi.object().keys(getEditorActionSchema()),
            teamPermission: Joi.object().keys(getEditorTeamSchema())
        }),
        dealer: Joi.object().keys({
            groupPermission: Joi.object().keys(getDealerGroupsSchema()),
            responsibilityPermission: Joi.array()
        }),
        powerUser: Joi.object().max(0)
    });
}

function getCountryGroupsSchema() {
    const result = {};
    Object.keys(countries).forEach(countryGroup => result[countries[countryGroup]] = Joi.boolean());
    return result;
}

function getEditorActionSchema() {
    const result = {};
    Object.keys(editorActionRoles).forEach(group => result[editorActionRoles[group]] = Joi.boolean());
    return result;
}

function getEditorTeamSchema() {
    const result = {};
    Object.keys(editorTeamRoles).forEach(team => result[editorTeamRoles[team]] = Joi.boolean());
    return result;
}

function getDealerGroupsSchema() {
    const result = {};
    Object.keys(dealerRoles).forEach(group => result[dealerRoles[group]] = Joi.boolean().required());
    return result;
}

export function getSettingsSchema() {
    return {
        language: Joi.string(),
        locale: Joi.string(),
    };
}


