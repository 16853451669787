import React, {Component} from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import renaultCar from '../../images/car.png';
import mainPageMessages from '../../intl/home/mainPageMessages';
import {connect} from 'react-redux';
import {clearAlerts} from '../../actions/alertsActions';
import PropTypes from 'prop-types';

const Img = styled.img`
    height: 35%;
    width: 35%;
`;
Img.displayName = 'Img';

class HomePage extends Component {

    render() {


        return <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <h1 className="text-center"><FormattedMessage {...mainPageMessages.TITLE} /></h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="text-center">
                        <Img src={renaultCar}/>
                    </div>
                </div>
            </div>
        </div>
    }
}

HomePage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    group: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    group: state.profile.userDetail.group,
});

export default connect(mapStateToProps, {
    clearAlerts,
})(HomePage);
