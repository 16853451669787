import { getUserRole as restGetRole, getUsers as restGetUsers, saveUser as restPost, } from '../api/restRole';
import { extractErrorText } from './actionUtils';
import { errorAlert, warnAlert } from './alertsActions';
import mainPageMessages from '../intl/home/mainPageMessages';
import { validateUserDetail } from '../api/validation/role';
import alertMessages from '../intl/common/alertMessages';
import { dealerRoles } from '../constants/Utils';
import { rboxWebsocketCommands } from '../constants/rboxWebsocketCommands';
import { cloneDeep } from 'lodash';

export const USER_ROLE_FETCH_PENDING = 'USER_ROLE_FETCH_PENDING';
export const USER_ROLE_FETCH_SUCCESS = 'USER_ROLE_FETCH_SUCCESS';
export const USER_ROLE_FETCH_ERROR = 'USER_ROLE_FETCH_ERROR';
export const USER_ROLE_SAVE_PENDING = 'USER_ROLE_SAVE_PENDING';
export const USER_ROLE_SAVE_SUCCESS = 'USER_ROLE_SAVE_SUCCESS';
export const USER_ROLE_SAVE_ERROR = 'USER_ROLE_SAVE_ERROR';
export const USER_TO_BE_CREATED = 'USER_TO_BE_CREATED';

export function fetchUserRole(ipn, name, ldapRole, bir) {
    return async dispatch => {
        if (process.env.REACT_APP_LDAP_DEALER_ROLE === 'true') {
            if (bir) {
                dispatch({
                    type: rboxWebsocketCommands.RBOX_ROLES_PENDING,
                    rboxWebsocket: true,
                    command: rboxWebsocketCommands.RBOX_CHECK_DEALER_LDAP_ROLE,
                    payload: { payload: {} }
                })
            }
        }
        dispatch({type: USER_ROLE_FETCH_PENDING});
        try {
            const {data} = cloneDeep(await restGetRole(ipn));
            const {error} = validateUserDetail(data);
            if (error) {
                dispatch({type: USER_ROLE_FETCH_ERROR});
                dispatch(errorAlert(alertMessages.USERS_RETRIEVED_ERROR, [error.toString()]));
            } else {
                dispatch({type: USER_ROLE_FETCH_SUCCESS, payload: data});
                if (data && data.roles && Object.keys(data.roles).length === 0) {
                    dispatch(warnAlert(mainPageMessages.NO_RIGHTS));
                }
            }
        } catch (error) {
            checkDealerLdapRole();
            if (error.response && error.response.status === 404) {
                let roles = {};
                if (ldapRole && bir) {
                    roles = {
                        dealer: {
                            groupPermission: {
                                [dealerRoles.BASIC_DEALER]: true
                            }
                        }
                    }
                }

                dispatch({type: USER_TO_BE_CREATED});
                dispatch({
                    type: USER_ROLE_FETCH_SUCCESS,
                    payload: {ipn: ipn, name: name, roles}
                });
                dispatch(warnAlert(mainPageMessages.NO_RIGHTS));
            } else {
                dispatch({type: USER_ROLE_FETCH_ERROR});
                dispatch(errorAlert(...extractErrorText(error, alertMessages.USERS_DETAILS_FETCH_ERROR)));
            }
        }
    };
}

export function fetchUsersPromise(textSearch) {
    return restGetUsers(textSearch);
}

export function handleFetchUsersError(error) {
    return dispatch => {
        dispatch(errorAlert(...extractErrorText(error, alertMessages.USERS_FETCH_ERROR)));
    };
}

export function saveUserRole() {
    return dispatch => {
        dispatch({type: USER_ROLE_SAVE_PENDING});

        restPost().then(
            () => dispatch({type: USER_ROLE_SAVE_SUCCESS}),
            error => {
                dispatch({type: USER_ROLE_SAVE_ERROR});
                dispatch(errorAlert(...extractErrorText(error, alertMessages.USERS_SAVE_ERROR)));
            }
        );
    };
}

export const checkDealerLdapRole = () => dispatch => {
    return dispatch({ rboxWebsocket: true, command: rboxWebsocketCommands.RBOX_CHECK_DEALER_LDAP_ROLE, payload: { } });
};
