import { getAssociatedItems as restGetItems, } from '../api/restSettings';
import { extractErrorText } from './actionUtils';
import { errorAlert } from './alertsActions';
import alertMessages from '../intl/common/alertMessages';


export function fetchItemsPromise(textSearch) {
    return restGetItems(textSearch);
}

export function handleFetchItemsError(error) {
    return dispatch => {
        dispatch(errorAlert(...extractErrorText(error, alertMessages.ITEMS_FETCH_ERROR)));
    };
}

