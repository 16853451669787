//HDI Ticket statuses
export const RBOX_TICKET_STATUS_NOT_TODO = 'NOT_TODO';
export const RBOX_TICKET_STATUS_NEW = 'NEW';
export const RBOX_TICKET_STATUS_READ_BY_EDITOR = 'READ_BY_EDITOR';
export const RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR = 'FORWARDED_TO_EDITOR';
export const RBOX_TICKET_STATUS_FORWARDED_TO_DEALER = 'FORWARDED_TO_DEALER';
export const RBOX_TICKET_STATUS_FORWARDED_TO_GLPI = 'FORWARDED_TO_GLPI';
export const RBOX_TICKET_STATUS_SOLVING = 'SOLVING';
export const RBOX_TICKET_STATUS_SOLVED = 'SOLVED';
export const RBOX_TICKET_STATUS_CLOSING = 'CLOSING';
export const RBOX_TICKET_STATUS_CLOSED = 'CLOSED';
export const RBOX_TICKET_STATUS_REOPENED = 'REOPENED';
