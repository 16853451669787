import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { ActionSelector, StatusSelector } from '../../common/UserNotificationSelectors';
import { StyledDiv } from '../../common/StyledComponents';
import ButtonSection from '../../common/userNotification/ButtonSection';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import notificationMessages from '../../../intl/common/notificationMessages';
import formActionsMessages from '../../../intl/common/formActionsMessages';

class NotificationRboxForm extends Component {
    constructor(props) {
        super(props);
        const {initialValues, intl: {formatMessage}} = this.props;
        this.state = {
            selectedStatuses: (initialValues.statuses || []).map(status => ({
                value: status,
                label: formStatesMessages[status] ? formatMessage(formStatesMessages[status]) : status
            })),
            selectedActions: (initialValues.actions || []).map(action => ({
                value: action,
                label: formActionsMessages[action] ? formatMessage(formActionsMessages[action]) : action
            })),

        }
    }

    handleStatusChange = newOptions => {
        this.props.change(`statuses`, (newOptions || []).map(e => e.value));
        this.setState({
            selectedStatuses: (newOptions || []).map(status => ({
                value: status.value,
                label: status.label
            })),
        });
    };

    handleActionChange = newOptions => {
        this.props.change(`actions`, (newOptions || []).map(e => e.value));
        this.setState({
            selectedActions: (newOptions || []).map(action => ({
                value: action.value,
                label: action.label
            })),
        });
    };

    handleStatusAllClick = () => {
        this.props.change(`statuses`, (this.props.statusOptions || []).map(e => e.value));
        this.setState({selectedStatuses: this.props.statusOptions || []})
    };

    handleActionAllClick = () => {
        this.props.change(`actions`, (this.props.actionOptions || []).map(e => e.value));
        this.setState({selectedActions: this.props.actionOptions || []})
    };

    handleCancel = () => {
        const {initialValues, intl: {formatMessage}, reset} = this.props;
        reset();
        this.setState({
            selectedStatuses: (initialValues.statuses || []).map(status => ({
                value: status,
                label: formStatesMessages[status] ? formatMessage(formStatesMessages[status]) : status
            })),
            selectedActions: (initialValues.actions || []).map(action => ({
                value: action,
                label: formActionsMessages[action] ? formatMessage(formActionsMessages[action]) : action
            })),
        })
    };

    render() {
        const {form, statusOptions, actionOptions, handleSubmit, intl: {formatMessage}} = this.props;

        return (
            <StyledDiv className="border border-dark px-4 pt-3 pb-2 bg-light">
                {(statusOptions || []).length !== 0 &&
                <div>
                    <div className="row mb-3">
                        <div className="col">
                            <Field name={'statuses'}
                                   label={notificationMessages.STATUS_NOTIFICATION}
                                   component={StatusSelector}
                                   statuses={this.state.selectedStatuses}
                                   options={statusOptions}
                                   placeholder={formatMessage(notificationMessages.STATUS_PLACEHOLDER)}
                                   handleChange={this.handleStatusChange}
                                   handleAllClick={this.handleStatusAllClick}/>
                        </div>
                    </div>
                </div>
                }
                {(actionOptions || []).length !== 0 &&
                <div>
                    <div className="row mb-3">
                        <div className="col">
                            <Field name={'actions'}
                                   label={notificationMessages.ACTION_NOTIFICATION}
                                   component={ActionSelector}
                                   actions={this.state.selectedActions}
                                   options={actionOptions}
                                   placeholder={formatMessage(notificationMessages.ACTION_PLACEHOLDER)}
                                   handleChange={this.handleActionChange}
                                   handleAllClick={this.handleActionAllClick}/>
                        </div>
                    </div>
                </div>
                }
                <ButtonSection form={form}
                               onCancel={this.handleCancel}
                               onSubmit={handleSubmit}/>
            </StyledDiv>
        )
    }
}

NotificationRboxForm.propTypes = {
    handleSubmit: PropTypes.func,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    userDetail: PropTypes.object,
    initialValues: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default reduxForm({})(connect(null, mapDispatchToProps)(injectIntl(NotificationRboxForm)));


