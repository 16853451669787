import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    clearDealerRightsManagementPageData,
    clearEditorRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    resetManageUser,
    saveDealerResponsibilityManagementPageData,
    setDealerGroupsChanged,
    setInitialDealerGroupOptions,
    setManagedUser
} from '../../../actions/admin/actionAdminRightsManagement';
import SelectUser from '../right/SelectUser';
import Loader from '../../Loader';
import { clearAlerts } from '../../../actions/alertsActions';
import { handleFetchUsersError } from '../../../actions/actionRole';
import { handleUserSearch } from '../../../utils/userUtils';
import commonMessages from '../../../intl/common/commonMessages';
import PageHeader from '../../common/PageHeader';
import adminMessages from '../../../intl/admin/adminMessages';
import Form from './forms/Form';
import { dealerConstants } from '../../../constants/form/formDealerConstants';
import { dealerResponsibilityReqFields } from '../../../constants/requiredFields';
import { initLoadedResponsibility, transformUpdateResponsibilityForSave } from './Utils';
import { adminResponsibilityRights } from '../../../constants/adminRights';
import errorMessages from '../../../intl/common/errorMessages';
import styled from 'styled-components';

const Panel = styled.h1`
  font-size: 1.5em;
  text-align: center;
`;


class DealerResponsibilityRightsManagementPage extends Component {
    componentDidMount() {
        this.props.clearDealerRightsManagementPageData();
        this.props.resetManageUser();
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setDealerGroupsChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleGroupSelectionChange = newOptions => {
        this.setSelectedOptionsChanged(newOptions);
        this.props.setSelectedDealerGroupOptions(newOptions);
    };

    handleCancelClick = () => {
        this.props.setDealerGroupsChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearDealerRightsManagementPageData();
        this.props.fetchDealerRightsManagementPageData(user, this.props.domain);
        this.props.clearEditorRightsManagementPageData();
        this.props.fetchEditorRightsManagementPageData(user, this.props.domain);
    };

    handleSubmit = (values) => {
        const dataForSend = transformUpdateResponsibilityForSave(values);
        this.props.saveDealerResponsibilityManagementPageData(this.props.managedUser, this.props.domain, dataForSend);
    };

    render() {
        const { isLoading, managedUser, dealerData, domain, isDealer } = this.props;
        if (isLoading) {
            return <Loader/>;
        }

        const requiredFields = dealerResponsibilityReqFields[dealerConstants.DEALER_RESPONSIBILITY_FORM];
        const initializationValue = initLoadedResponsibility(dealerData);

        if (Object.keys(managedUser).length === 0) {
            return (
                <div>
                    <PageHeader title={
                        <FormattedMessage {...adminMessages.DEALER_RESPONSIBILITY}
                                          values={{
                                              domain: <FormattedMessage {...commonMessages[domain]}/>
                                          }}/>
                    }/>
                    <div className="mb-sm-4">
                        <SelectUser handleUserSelection={this.handleUserSelection}
                                    handleUserSearch={this.handleUserSearch}/>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <PageHeader title={
                    <FormattedMessage {...adminMessages.DEALER_RESPONSIBILITY}
                                      values={{
                                          domain: <FormattedMessage {...commonMessages[domain]}/>
                                      }}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {!isDealer &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.DEALER_ROLE_MISS} />
                </Panel>
                }
                {!isDealer && managedUser && managedUser.ipn && !managedUser.dealerNumber &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.DEALER_ROLE_NO_BIR} />
                </Panel>
                }
                { managedUser && managedUser.ipn && managedUser.dealerNumber && isDealer &&

                <Form form={dealerConstants.DEALER_RESPONSIBILITY_FORM}
                      initialValues={initializationValue}
                      userRights={adminResponsibilityRights}
                      enableReinitialize={true}
                      keepDirtyOnReinitialize={true}
                      reqFields={requiredFields}
                      handleSubmit={this.handleSubmit}/>
                }
            </div>
        );
    }
}

DealerResponsibilityRightsManagementPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    dealerData: PropTypes.object.isRequired,
    initialOptions: PropTypes.array.isRequired,
    selectedOptions: PropTypes.array,
    intl: PropTypes.object.isRequired,

    clearAlerts: PropTypes.func.isRequired,

    fetchEditorRightsManagementPageData: PropTypes.func.isRequired,
    fetchDealerRightsManagementPageData: PropTypes.func.isRequired,

    saveDealerResponsibilityManagementPageData: PropTypes.func.isRequired,
    setDealerGroupsChanged: PropTypes.func.isRequired,

    clearEditorRightsManagementPageData: PropTypes.func.isRequired,
    clearDealerRightsManagementPageData: PropTypes.func.isRequired,

    setInitialDealerGroupOptions: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    dealerData: state.adminRightsManagement.dealerData,
    isDealer: Object.values(state.adminRightsManagement.dealerData.groupPermission || {}).some(value => value),
    initialOptions: state.adminRightsManagement.initialDealerResponsibilityOptions,
    selectedOptions: state.adminRightsManagement.selectedDealerOptions
});

export default connect(mapStateToProps, {
    setManagedUser,
    fetchDealerRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    setDealerGroupsChanged,
    setInitialDealerGroupOptions,
    saveDealerResponsibilityManagementPageData,
    clearDealerRightsManagementPageData,
    clearEditorRightsManagementPageData,
    clearAlerts,
    handleFetchUsersError,
    resetManageUser
})(injectIntl(DealerResponsibilityRightsManagementPage));
