import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import { RequiredSpan } from './StyledComponents';
import { RenderAsyncSelectInput } from './FormField';
import { required, requiredArray } from './validationCommons';

const InputAsyncMultipleSelectField = (props) => {
    const { label, field, placeholder, intlMessages, isRequired, defaultValues, isMulti, isSearchable, isDisabled, isClearable, onChange, loadRecords, intl: { formatMessage } } = props;
    let validations = [];
    if (isRequired) {
        validations.push(required);
        validations.push(requiredArray);
    }
    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>
            }
            <Field name={field}
                   validate={validations}
                   component={RenderAsyncSelectInput}
                   onChange={onChange}
                   loadRecords={loadRecords}
                   isMulti={isMulti}
                   isSearchable={isSearchable}
                   isDisabled={isDisabled}
                   isClearable={isClearable}
                   defaultValues={defaultValues}
                   placeholder={formatMessage(intlMessages[placeholder])}/>
        </div>
    )
};
export default (injectIntl(InputAsyncMultipleSelectField));
