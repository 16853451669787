import styled from 'styled-components';
import { PrimaryIcon } from './Button';
import Picky from 'react-picky';

export const Label = styled.label`
    font-weight: bold;
`;

export const RequiredSpan = styled.span`
  &&{color: red};
`;

export const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

export const StyledDiv = styled.div`
  border-radius: 5px;
`;

export const RedButton = styled.button`
  background-color: #D8190C;
  cursor: pointer;
`;

export const StyledTr = styled.tr`
    :hover&&{
        background-color: white;
    };
`;

export const ReplyTd = styled.td`
    &&{
        padding: 0.2rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    };
`;

export const StyledThead = styled.thead`
  &&{background-color: #e9ecef;};
`;

export const StyledSpan = styled.span`
  &&{color: red};
`;

export const StyledTh = styled.th`
    &&{
        border-top: 0px;
        text-align: center
        white-space: nowrap;
    };
`;

export const StyledProblemDescTh = styled.th`
    &&{
        border-top: 0px;
        padding: 0px;
        text-align: center;
        white-space: nowrap;
    };
`;

export const StyledToolsTh = styled(StyledTh)`
  width: 55px;
`;


export const BorderlessPrimaryIcon = styled(PrimaryIcon)`
  border-radius: 0 0 0 0;
`;

export const StyledPicky = styled(Picky)`
    button:first-child{
        border-radius: 0.3em;
    };
`;

export const Wrapper = styled.div`
  padding-bottom: 30px;
`;
