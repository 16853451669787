import v4 from 'uuid/v4';
import get from 'get-value';
import { diff } from 'deep-object-diff';
import { moduleRoles } from '../../../utils/roles';
import { MEDIUM } from '../../../constants/form/levelConstants';
import { countries, countryToGroups } from '../../../constants/countries';
import listMessages from '../../../intl/rbox/listMessages';
import moment from 'moment';
import DatePickerTableComponent
    from '../../common/datePicker/DatePickerTableComponent';
import {FormattedDate, FormattedTime} from 'react-intl';
import Creatable from 'react-select/creatable';
import {
    getAllFormStates,
    getEditorTeamRoles,
    getImpact,
    getPriority,
    getUrgency
} from '../../../constants/Utils';
import formTicketValuesMessages
    from '../../../intl/common/formTicketValuesMessages';
import teamRoleMessages from '../../../intl/admin/teamRoleMessages';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import React from 'react';
import {styles} from './shared/styles';

export const getUserRole = roles => {
    return moduleRoles.isEditor(roles) ? 'EDITOR' : 'DEALER';
};

export const convertAssociatedItems = (items) => {
    return items.map(item => {
        return { id: item.value, name: item.label }
    });
}

export const convertAssociatedItemsToSelect = (items) => {
    return items.map(item => {
        return { value: item.id, label: item.name }
    });
}

export const initLoadedTicket = (ticketData) => {
    const { ...rest } = ticketData.ticket;
    const { associatedItems } = ticketData.ticket;
    return {
        ticketSection: {
            ...rest,
            associatedItems: convertAssociatedItemsToSelect(associatedItems || [])
        },

        messages: ticketData.messages,
        statusHistory: ticketData.logs,
        attachmentsSection: { attachments: ticketData.attachments }
    };
};

export const initNewTicket = (userDetail, userRoles) => {
    if ((!userDetail.dealerNumber || userDetail === '') && !userRoles.includes('RBOX_EDITOR_CREATOR')) {
        return undefined;
    }

    let country = userDetail.country;
    let group = userDetail.group;
    if (userRoles.includes('RBOX_EDITOR_CREATOR') && moduleRoles.isEditor(userDetail.roles)) {
        const roles = Object
            .keys(userDetail.roles.editor.groupPermission)
            .filter(i => userDetail.roles.editor.groupPermission[i] === true)
            .map(i => i);

        country = roles[0] || '';
        group = countryToGroups[country];
    }

    return {
        ticketSection: {
            priority: MEDIUM,
            //dealerNumber: userDetail.dealerNumber || '',
            //dealerName: userDetail.dealerName || '',
            country,
            group,
            email: userDetail.mail || '',
            telNo: userDetail.telNo || '',
            impact: MEDIUM,
            urgency: MEDIUM,
            associatedItems: [],
            subject: '',
            description: '',
            category: '1',
            itilCategory: '1'
        }
    };
};

export const transformNewTicketForSave = (values) => {
    const data = {};
    const { associatedItems, country } = values.ticketSection;

    data.ticket = {
        ...values.ticketSection,
        associatedItems: convertAssociatedItems(associatedItems),
        uuid: v4(),
        group: countryToGroups[country]
    };
    if (get(values, 'attachmentsSection.newAttachments', { default: false }) && values.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        values.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                ...attachment,
                uuid: v4(),
            })
        });
    }
    return data;
};

export const transformUpdateTicketForSave = (values) => {
    const { oldValues, newValues } = values;
    const difference = diff(oldValues, newValues);
    const data = {};

    data.ticket = {
        ...get(difference, 'ticketSection'),
        uuid: oldValues.ticketSection.uuid,
    };

    if (get(difference, 'ticketStation.country')) {
        data.ticket.group = countries[get(difference, 'ticketStation.country')];
    }

    if (get(data, 'ticket.associatedItems', { default: false })) {
        data.ticket.associatedItems = convertAssociatedItems(get(newValues, 'ticketSection.associatedItems'));
    }

    const newMessage = get(difference, 'messagesSection.newMessage');
    if (newMessage) {
        data.messages = {
            uuid: v4(),
            text: newMessage,
        };
    }

    if (get(difference, 'attachmentsSection.newAttachments', { default: false }) && difference.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        difference.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                uuid: v4(),
                ...attachment,
            })
        });
    }
    return data;
};

export const getColumnsLibrary = (intl, userRoles) => {
    const getFilter = (options, messages, filter, onChange) => (
        <select
            onChange={event => onChange(event.target.value)}
            style={{width: '100%', border: '1px solid #999999'}}
            value={filter ? filter.value : ''}>
            <option value="">{intl.formatMessage(listMessages.ALL)}</option>
            {options.map((key, index) =>
                <option key={index} value={key}>
                    {messages[key] ? intl.formatMessage(
                        messages[key]) : key}
                </option>)}
        </select>
    )

    const filterDatePicker = ({filter, onChange}) => {
        let value = filter && filter.value;
        if (typeof value === 'string') {
            value = moment(value).toDate();
        }
        return (
            <DatePickerTableComponent value={value || undefined}
                                      onChange={onChange} filter={filter}/>
        );
    }

    const cellDateTime = ({value}) => {
        return (
            <>
                <FormattedDate value={new Date(value)}/>{' '}
                <FormattedTime value={new Date(value)}/>
            </>
        );
    }

    const cellValue = (value, messages) => {
        return (
            <div>
                {messages[value]
                    ? intl.formatMessage(messages[value])
                    : value}
            </div>
        );
    }

    const formatAssociatedItemsLabel = (values = [], intl) => {
        return values.map(value => ({label: value.name, value: value.name}));
    };

    const sharedColumns = [
        {
            id: 'associatedItems',
            accessor: row =>
                <Creatable
                    value={formatAssociatedItemsLabel(
                        row.associatedItems || [])}
                    components={{DropdownIndicator: null}}
                    isMulti
                    isDisabled
                    isSearchable={false}
                    isClearable={false}
                    placeholder={false}
                    styles={styles}
                    name="colors"
                    className="basic-multi-select"
                    classNamePrefix="select"
                />,
            message: listMessages.TABLE_ASSOCIATED_ITEMS,
            sortable: false,
            width: 260
        },
        {
            id: 'subject',
            accessor: 'subject',
            message: listMessages.TABLE_SUBJECT,
        },
        {
            id: 'openingDateTime',
            accessor: 'openingDateTime',
            message: listMessages.TABLE_CREATION_TIME,
            Filter: filterDatePicker,
            Cell: e => cellDateTime(e),
        },
        {
            id: 'impact',
            accessor: 'impact',
            message: listMessages.TABLE_IMPACT,
            Filter: ({filter, onChange}) => getFilter(getImpact,
                formTicketValuesMessages, filter, onChange),
            Cell: e => cellValue(e.value, formTicketValuesMessages),
        },
        {
            id: 'urgency',
            accessor: 'urgency',
            message: listMessages.TABLE_URGENCY,
            Filter: ({filter, onChange}) => getFilter(getUrgency,
                formTicketValuesMessages, filter, onChange),
            Cell: e => cellValue(e.value, formTicketValuesMessages),
        },
        {
            id: 'priority',
            accessor: 'priority',
            message: listMessages.TABLE_PRIORITY,
            Filter: ({filter, onChange}) => getFilter(getPriority,
                formTicketValuesMessages, filter, onChange),
            Cell: e => cellValue(e.value, formTicketValuesMessages),
        },
        {
            id: 'assignedTo',
            accessor: 'assignedTo',
            message: listMessages.TABLE_LAST_ASSIGNED_TO,
            Filter: ({filter, onChange}) => getFilter(getEditorTeamRoles,
                teamRoleMessages, filter, onChange),
            Cell: e => cellValue(e.value, teamRoleMessages)
        },
        {
            id: 'status',
            accessor: 'status',
            message: listMessages.TABLE_STATUS,
            Filter: ({filter, onChange}) => getFilter(getAllFormStates,
                formStatesMessages, filter, onChange),
            Cell: e => cellValue(e.value, formStatesMessages),
        },
    ];

    const columnLibrary = {
        EDITOR: [
            {
                id: 'country',
                accessor: 'country',
                message: listMessages.TABLE_COUNTRY,
                Filter: ({filter, onChange}) => getFilter(
                    moduleRoles.availableCountriesEditor(userRoles),
                    countryGroupMessages, filter, onChange),
                Cell: e => cellValue(e.value, countryGroupMessages)
            },
            {
                id: 'creatorName',
                accessor: 'creatorName',
                message: listMessages.TABLE_CREATED_BY,
            },
            {
                id: 'lastEditor',
                accessor: 'lastEditor',
                message: listMessages.TABLE_LAST_EDITOR,
            },
            ...sharedColumns.slice(0, 3),
            {
                id: 'lastUpdate',
                accessor: 'lastUpdate',
                message: listMessages.TABLE_LAST_UPDATE,
                Filter: filterDatePicker,
                Cell: e => cellDateTime(e),
            },
            ...sharedColumns.slice(3)
        ],
        DEALER: [...sharedColumns]
    }
    return columnLibrary;
};

export const isVisibleForCountry = (userCountry) => {
    return userCountry === 'NL' || userCountry === 'BE' || userCountry === 'LU';
};



