import { defineMessages } from 'react-intl';
import { HIGH, LOW, MAJOR, MEDIUM, VERY_HIGH, VERY_LOW } from '../../constants/form/levelConstants';

export const messages = defineMessages({
    [VERY_LOW]: {
        id: 'form.level.veryLow',
        defaultMessage: 'Very Low'
    },
    [LOW]: {
        id: 'form.level.low',
        defaultMessage: 'Low'
    },
    [MEDIUM]: {
        id: 'form.level.medium',
        defaultMessage: 'Medium'
    },
    [HIGH]: {
        id: 'form.level.high',
        defaultMessage: 'High'
    },
    [VERY_HIGH]: {
        id: 'form.level.veryHigh',
        defaultMessage: 'Very High'
    },
    [MAJOR]: {
        id: 'form.level.major',
        defaultMessage: 'Major'
    },
});

export default messages;
