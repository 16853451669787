import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    RBOX_EXPORT_PATH,
    RBOX_NOTIFICATION_LIST_PATH,
    RBOX_NOTIFICATION_PATH,
    RBOX_PATH, RBOX_TICKET_HISTORY_PATH,
    RBOX_TICKET_LIST_PATH,
    RBOX_TICKET_NEW_PATH
} from '../../routes/paths';
import ListPage from './ticket/list/ListPage';
import { domains } from '../../constants/Utils';
import ViewTicketPage from './ticket/detail/ViewTicketPage';
import EditTicketPage from './ticket/detail/EditTicketPage';
import NewTicketPage from './ticket/detail/NewTicketPage';
import { RBOX_TICKET_FORM } from '../../constants/formConstants';
import NotificationListPage from "./ticket/notificationList/NotificationListPage";
import NotificationRboxPage from './notification/NotificationRboxPage';
import ExportPage from './export/ExportPage';
import HistoryPage from './ticket/history/HistoryPage';
import {isVisibleForCountry} from './ticket/Utils';

const FlexWrapper = styled.div`
    padding-left: 0;
    display:flex;
`;

class Rbox extends Component {

    render() {
        return (
            <FlexWrapper>
                <Switch>
                    <Route exact path={RBOX_PATH}
                           render={() => <Redirect to={RBOX_TICKET_LIST_PATH}/>}/>
                    <Route exact path={RBOX_TICKET_LIST_PATH}
                           render={() => <ListPage domain={domains}/>}/>

                    {isVisibleForCountry(this.props['userDetail']['country']) &&
                        <Route exact path={RBOX_TICKET_HISTORY_PATH}
                               render={() => <HistoryPage domain={domains}/>}/>
                    }

                    <Route exact path={RBOX_NOTIFICATION_LIST_PATH}
                           render={() => <NotificationListPage domain={domains}/>}/>
                    <Route exact path={RBOX_EXPORT_PATH}
                           component={ExportPage}/>
                    <Route exact path={RBOX_PATH + '/:view(view)/:ticketUuid(.*)'}
                           component={ViewTicketPage}/>
                    <Route exact path={RBOX_PATH + '/:edit(edit)/:ticketUuid(.*)'}
                           component={EditTicketPage}/>>
                    <Route exact path={RBOX_TICKET_NEW_PATH}
                           render={() => <NewTicketPage formType={RBOX_TICKET_FORM}/>}/>
                    <Route exact path={RBOX_NOTIFICATION_PATH}
                           component={NotificationRboxPage}/>
                </Switch>
            </FlexWrapper>
        );
    }
}

Rbox.propTypes = {
    userDetail: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    isToggled: state.menu.isToggled,
    userDetail: state.profile.userDetail,
});

export default connect(mapStateToProps, null)(Rbox);
