import { put, select, takeEvery } from 'redux-saga/effects';
import { rboxWebsocketCommands } from '../constants/rboxWebsocketCommands';
import { errorAlert } from '../actions/alertsActions';
import { extractErrorText } from '../actions/actionUtils';
import { cloneDeep } from 'lodash';
import {editorActionRoles, editorTeamRoles, getDealerRole} from '../constants/Utils';
import {
    validateAdminRoles, validateCentralData,
    validateDealerRoles,
    validateEditorActionData,
    validateEditorData, validateEditorTeamRoleData
} from '../api/validation/role';
import get from 'get-value';
import {
    RBOX_ADMIN_GROUPS_CHANGED,
    RBOX_ADMIN_RIGHTS_FETCH_SUCCESS, RBOX_CENTRAL_GROUPS_CHANGED, RBOX_CENTRAL_RIGHTS_FETCH_SUCCESS,
    RBOX_DEALER_GROUPS_CHANGED,
    RBOX_DEALER_RIGHTS_FETCH_SUCCESS,
    RBOX_EDITOR_ACTION_RIGHTS_FETCH_SUCCESS,
    RBOX_EDITOR_ACTIONS_CHANGED,
    RBOX_EDITOR_GROUPS_CHANGED,
    RBOX_EDITOR_RIGHTS_FETCH_SUCCESS, RBOX_EDITOR_TEAM_ROLE_CHANGED, RBOX_EDITOR_TEAM_ROLE_FETCH_SUCCESS,
    RBOX_INITIAL_ADMIN_GROUP_OPTIONS,
    RBOX_INITIAL_CENTRAL_GROUP_OPTIONS,
    RBOX_INITIAL_DEALER_GROUP_OPTIONS,
    RBOX_INITIAL_DEALER_RESPONSIBILITY_OPTIONS,
    RBOX_INITIAL_EDITOR_ACTION_OPTIONS,
    RBOX_INITIAL_EDITOR_GROUP_OPTIONS, RBOX_INITIAL_EDITOR_TEAM_ROLE_OPTIONS,
    RBOX_RIGHTS_ERROR,
    RBOX_SELECTED_ADMIN_GROUP_OPTIONS,
    RBOX_SELECTED_CENTRAL_GROUP_OPTIONS,
    RBOX_SELECTED_DEALER_GROUP_OPTIONS,
    RBOX_SELECTED_EDITOR_ACTION_OPTIONS,
    RBOX_SELECTED_EDITOR_GROUP_OPTIONS, RBOX_SELECTED_EDITOR_TEAM_ROLE_OPTIONS
} from '../actions/admin/actionAdminRightsManagement';
import alertMessages from '../intl/common/alertMessages';
import { countries } from '../constants/countries';

function* handleFetchAdminData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `correlationId`, { default: undefined });
        const { correlationId } = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyAdminData = {};
        Object.keys(countries).forEach(groupKey => emptyAdminData[countries[groupKey]] = false);
        const { error } = validateAdminRoles(cloneDeep(action.payload.groupPermission));
        if (error && action.payload.groupPermission) {
            yield put({ type: RBOX_RIGHTS_ERROR, payload: { correlationId } });
            yield put(errorAlert(`Retrieved invalid admin rights from backend`, [error.toString()]));
        } else {
            const finalResult = (action.payload.groupPermission ? Object.assign(emptyAdminData, action.payload.groupPermission) : emptyAdminData);
            yield put({
                type: RBOX_ADMIN_RIGHTS_FETCH_SUCCESS,
                payload: { finalResult, correlationId },
            });

            const result = Object.keys(finalResult)
                .filter(group => finalResult[group] === true)
                .map(group => ({ value: group }));
            yield put({
                type: RBOX_SELECTED_ADMIN_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_INITIAL_ADMIN_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_ADMIN_GROUPS_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({ type: RBOX_RIGHTS_ERROR });
        yield put(errorAlert(...extractErrorText(e, `Cannot fetch Rights Management admin data`)));
    }
}

function* handleFetchEditorData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `editorCorrelationId`, { default: undefined });
        const { correlationId } = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const loggedAdminCountries = get(yield select(state => state.profile), `userDetail.roles.admin.groupPermission`, { default: {} });
        const emptyEditorData = Object.keys(loggedAdminCountries)
            .filter(groupKey => loggedAdminCountries[groupKey] === true)
            .reduce((acc, groupKey) => ({
                ...acc,
                [groupKey]: false,
            }), {});
        const { error } = validateEditorData(cloneDeep(action.payload.groupPermission));
        if (error && action.payload.groupPermission) {
            yield put({ type: RBOX_RIGHTS_ERROR, payload: { correlationId } });
            yield put(errorAlert(alertMessages.RIGHTS_EDITOR_RETRIEVED_ERROR, [error.toString()]));
        } else {
            const filteredResult = Object.keys(action.payload.groupPermission || {})
                .filter(countryIso => emptyEditorData[countryIso] !== undefined)
                .reduce((acc, countryIso) => ({
                    ...acc,
                    [countryIso]: action.payload.groupPermission[countryIso],
                }), {});
            const finalResult = (action.payload.groupPermission ? filteredResult : emptyEditorData);
            yield put({
                type: RBOX_EDITOR_RIGHTS_FETCH_SUCCESS,
                payload: { finalResult, correlationId },
            });

            const result = Object.keys(finalResult)
                .filter(countryIso => finalResult[countryIso] === true)
                .map(countryIso => ({ value: countryIso }));
            yield put({
                type: RBOX_SELECTED_EDITOR_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_INITIAL_EDITOR_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_EDITOR_GROUPS_CHANGED,
                payload: false
            });
            yield put({
                type: RBOX_EDITOR_ACTIONS_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({ type: RBOX_RIGHTS_ERROR });
        yield put(errorAlert(...extractErrorText(e, alertMessages.RIGHTS_EDITOR_FETCH_ERROR)));
    }
}

function* handleFetchEditorActionData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `editorActionCorrelationId`, { default: undefined });
        const { correlationId } = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyEditorData = {};
        Object.keys(editorActionRoles).forEach(groupKey => emptyEditorData[editorActionRoles[groupKey]] = false);
        const { error } = validateEditorActionData(cloneDeep(action.payload.actionPermission));
        if (error && action.payload.actionPermission) {
            yield put({ type: RBOX_RIGHTS_ERROR, payload: { correlationId } });
            yield put(errorAlert(`Retrieved invalid editor rights from backend`, [error.toString()]));
        } else {
            const finalResult = (action.payload.actionPermission ? Object.assign(emptyEditorData, action.payload.actionPermission) : emptyEditorData);
            yield put({
                type: RBOX_EDITOR_ACTION_RIGHTS_FETCH_SUCCESS,
                payload: { finalResult, correlationId },
            });

            const result = Object.keys(finalResult)
                .filter(group => finalResult[group] === true)
                .map(group => ({ value: group }));
            yield put({
                type: RBOX_SELECTED_EDITOR_ACTION_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_INITIAL_EDITOR_ACTION_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_EDITOR_ACTIONS_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({ type: RBOX_RIGHTS_ERROR });
        yield put(errorAlert(...extractErrorText(e, `Cannot fetch Rights Management editor data`)));
    }
}

function* handleFetchEditorTeamRoleData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `editorTeamRoleCorrelationId`, { default: undefined });
        const { correlationId } = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyEditorData = {};
        Object.keys(editorTeamRoles).forEach(groupKey => emptyEditorData[editorTeamRoles[groupKey]] = false);
        const { error } = validateEditorTeamRoleData(cloneDeep(action.payload.teamPermission));
        if (error && action.payload.teamPermission) {
            yield put({ type: RBOX_RIGHTS_ERROR, payload: { correlationId } });
            yield put(errorAlert(`Retrieved invalid editor team rights from backend`, [error.toString()]));
        } else {
            const finalResult = (action.payload.teamPermission ? Object.assign(emptyEditorData, action.payload.teamPermission) : emptyEditorData);
            yield put({
                type: RBOX_EDITOR_TEAM_ROLE_FETCH_SUCCESS,
                payload: { finalResult, correlationId },
            });

            const result = Object.keys(finalResult)
                .filter(group => finalResult[group] === true)
                .map(group => ({ value: group }));
            yield put({
                type: RBOX_SELECTED_EDITOR_TEAM_ROLE_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_INITIAL_EDITOR_TEAM_ROLE_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_EDITOR_TEAM_ROLE_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({ type: RBOX_RIGHTS_ERROR });
        yield put(errorAlert(...extractErrorText(e, `Cannot fetch Team Role Management editor data`)));
    }
}

function* handleFetchDealerData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `dealerCorrelationId`, { default: undefined });
        const { correlationId } = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyDealerData = getDealerRole()
            .reduce((acc, formKey) => ({
                ...acc,
                [formKey]: false
            }), {});

        const { error } = validateDealerRoles(cloneDeep(action.payload.groupPermission));
        const responsibilityPermission = get(action,  'payload.responsibilityPermission', { default: [] } );
        if (error && action.payload.groupPermission) {
            yield put({ type: RBOX_RIGHTS_ERROR, payload: { correlationId } });
            yield put(errorAlert(`Retrieved invalid dealer rights from backend`, [error.toString()]));
        } else {
            const groupPermission = (action.payload.groupPermission ? Object.assign(emptyDealerData, action.payload.groupPermission) : emptyDealerData);

            yield put({
                type: RBOX_DEALER_RIGHTS_FETCH_SUCCESS,
                payload: { groupPermission, correlationId, responsibilityPermission },
            });

            const result = Object.keys(groupPermission)
                .filter(group => groupPermission[group] === true)
                .map(group => ({ value: group }));
            yield put({
                type: RBOX_SELECTED_DEALER_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_INITIAL_DEALER_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_INITIAL_DEALER_RESPONSIBILITY_OPTIONS,
                payload: cloneDeep(responsibilityPermission)
            });
            yield put({
                type: RBOX_DEALER_GROUPS_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({ type: RBOX_RIGHTS_ERROR });
        yield put(errorAlert(...extractErrorText(e, `Cannot fetch Rights Management data`)));
    }
}

function* handleFetchCentralData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `centralCorrelationId`, { default: undefined });
        const { correlationId } = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const loggedAdminCountries = get(yield select(state => state.profile), `userDetail.roles.admin.groupPermission`, { default: {} });
        const emptyCentralData = Object.keys(loggedAdminCountries)
            .filter(groupKey => loggedAdminCountries[groupKey] === true)
            .reduce((acc, groupKey) => ({
                ...acc,
                [groupKey]: false,
            }), {});
        const { error } = validateCentralData(cloneDeep(action.payload.groupPermission));
        if (error && action.payload.groupPermission) {
            yield put({ type: RBOX_RIGHTS_ERROR, payload: { correlationId } });
            yield put(errorAlert(alertMessages.RIGHTS_CENTRAL_RETRIEVED_ERROR, [error.toString()]));
        } else {
            const filteredResult = Object.keys(action.payload.groupPermission || {})
                .filter(countryIso => emptyCentralData[countryIso] !== undefined)
                .reduce((acc, countryIso) => ({
                    ...acc,
                    [countryIso]: action.payload.groupPermission[countryIso],
                }), {});
            const finalResult = (action.payload.groupPermission ? filteredResult : emptyCentralData);
            yield put({
                type: RBOX_CENTRAL_RIGHTS_FETCH_SUCCESS,
                payload: { finalResult, correlationId },
            });

            const result = Object.keys(finalResult)
                .filter(countryIso => finalResult[countryIso] === true)
                .map(countryIso => ({ value: countryIso }));
            yield put({
                type: RBOX_SELECTED_CENTRAL_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_INITIAL_CENTRAL_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: RBOX_CENTRAL_GROUPS_CHANGED,
                payload: false
            });
            // yield put({
            //     type: RBOX_EDITOR_ACTIONS_CHANGED,
            //     payload: false
            // });
        }
    } catch (e) {
        yield put({ type: RBOX_RIGHTS_ERROR });
        yield put(errorAlert(...extractErrorText(e, alertMessages.RIGHTS_CENTRAL_FETCH_ERROR)));
    }
}

function* handleFetchCheckDealerData() {
    yield window.location.reload();
}

function* rightsManagementSaga() {
    yield takeEvery(rboxWebsocketCommands.RBOX_ADMIN_ROLE_SEND, handleFetchAdminData);
    yield takeEvery(rboxWebsocketCommands.RBOX_CENTRAL_ROLE_SEND, handleFetchCentralData);
    yield takeEvery(rboxWebsocketCommands.RBOX_EDITOR_ROLE_SEND, handleFetchEditorData);
    yield takeEvery(rboxWebsocketCommands.RBOX_EDITOR_ACTION_ROLE_SEND, handleFetchEditorActionData);
    yield takeEvery(rboxWebsocketCommands.RBOX_EDITOR_TEAM_ROLE_SEND, handleFetchEditorTeamRoleData);
    yield takeEvery(rboxWebsocketCommands.RBOX_DEALER_ROLE_SEND, handleFetchDealerData);
    yield takeEvery(rboxWebsocketCommands.RBOX_DEALER_ROLE_CHECK_SEND, handleFetchCheckDealerData);
}

export default rightsManagementSaga;
