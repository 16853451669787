import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import formMessages from '../../../../../../../intl/rbox/formMessages';
import countryMessages from '../../../../../../../intl/common/countryGroupMessages';
import { ticketConstants } from '../../../../../../../constants/form/formTicketConstants';
import { InputFieldWithValidation } from '../../../../../../common/InputFieldWithValidation';
import InputSelectFieldWithValidation from '../../../../../../common/InputSelectFieldWithValidation';
import { TextareaFieldWithValidation } from '../../../../../../common/TextareaFieldWithValidation';
import { getImpact, getPriority, getUrgency } from '../../../../../../../constants/Utils';
import { change, formValueSelector, getFormInitialValues } from 'redux-form';
import InputAsyncMultipleSelectField from '../../../../../../common/InputAsyncMultipleSelectField';
import formTicketValuesMessages from '../../../../../../../intl/common/formTicketValuesMessages';
import countryGroupMessages from '../../../../../../../intl/common/countryGroupMessages';
import get from 'get-value';
import teamRoleMessages from '../../../../../../../intl/admin/teamRoleMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const TicketSection = (props) => {
    const { readOnly, reqFields, userRights, viewType, handleLoadItems, change, selectAssociatedItems, roles, isDealer } = props;

    const handleChange = (event, value) => {
        const result = value ? value : [];
        change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_ASSOCIATED_ITEMS}`, [...result]);
    }

    const getAvailableCountries = (items) => {
        const available = get(items, 'editor.groupPermission', { default: {}});

        return Object
            .keys(available)
            .filter(i => available[i] === true)
            .map(i => i);
    }

    return (
        <>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                {viewType !== 'NEW' && <div className="form-row">
                    <div className="col-xl-4 col-lg-4 col-md-12 pb-2">
                        <InputFieldWithValidation label={formMessages[ticketConstants.FORM_LABEL_CREATOR_NAME]}
                                                  field={ticketConstants.FORM_FIELD_CREATOR_NAME}
                                                  isDisabled
                                                  isRequired={reqFields.includes(ticketConstants.FORM_FIELD_CREATOR_NAME) && !readOnly}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 pb-2">
                        <InputFieldWithValidation label={formMessages[ticketConstants.FORM_LABEL_CREATOR_IPN]}
                                                  field={ticketConstants.FORM_FIELD_CREATOR_IPN}
                                                  isDisabled
                                                  isRequired={reqFields.includes(ticketConstants.FORM_FIELD_CREATOR_IPN) && !readOnly}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 pb-2 float-right text-right">
                        <InputSelectFieldWithValidation label={formMessages[ticketConstants.FORM_LABEL_COUNTRY]}
                                                        field={ticketConstants.FORM_FIELD_COUNTRY}
                                                        intlMessages={countryMessages}
                                                        isDisabled
                                                        isRequired={reqFields.includes(ticketConstants.FORM_FIELD_COUNTRY) && !readOnly}
                                                        isSmall/>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 pb-2 offset-lg-2 float-right text-right">
                        <InputSelectFieldWithValidation label={formMessages[ticketConstants.FORM_LABEL_ASSIGNED_TO]}
                                                        field={ticketConstants.FORM_FIELD_ASSIGNED_TO}
                                                        intlMessages={teamRoleMessages}
                                                        isDisabled
                                                        isRequired={reqFields.includes(ticketConstants.FORM_FIELD_ASSIGNED_TO) && !readOnly}
                                                        isSmall/>
                    </div>
                </div>
                }
                <div className="form-row">
                    {!isDealer && viewType === 'NEW' && <div className="col-xl-12 col-lg-12 col-md-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[ticketConstants.FORM_LABEL_COUNTRY]}
                                                        field={ticketConstants.FORM_FIELD_COUNTRY}
                                                        intlMessages={countryGroupMessages}
                                                        options={getAvailableCountries(roles)}
                                                        isRequired={reqFields.includes(ticketConstants.FORM_FIELD_COUNTRY) && !readOnly}
                                                        isDisabled={!userRights.canEditHeader || readOnly}
                                                        isSmall/>
                    </div>
                    }
                    <div className="col-xl-4 col-lg-4 col-md-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[ticketConstants.FORM_LABEL_IMPACT]}
                                                        field={ticketConstants.FORM_FIELD_IMPACT}
                                                        intlMessages={formTicketValuesMessages}
                                                        options={getImpact}
                                                        isRequired={reqFields.includes(ticketConstants.FORM_FIELD_IMPACT) && !readOnly}
                                                        isDisabled={!userRights.canEditHeader || readOnly}
                                                        isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[ticketConstants.FORM_LABEL_URGENCY]}
                                                        field={ticketConstants.FORM_FIELD_URGENCY}
                                                        intlMessages={formTicketValuesMessages}
                                                        options={getUrgency}
                                                        isRequired={reqFields.includes(ticketConstants.FORM_FIELD_URGENCY) && !readOnly}
                                                        isDisabled={!userRights.canEditHeader || readOnly}
                                                        isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[ticketConstants.FORM_LABEL_PRIORITY]}
                                                        field={ticketConstants.FORM_FIELD_PRIORITY}
                                                        intlMessages={formTicketValuesMessages}
                                                        options={getPriority}
                                                        isRequired={reqFields.includes(ticketConstants.FORM_FIELD_PRIORITY) && !readOnly}
                                                        isDisabled={!userRights.canEditHeader || readOnly}
                                                        isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 pb-2">
                        <InputAsyncMultipleSelectField label={formMessages[ticketConstants.FORM_LABEL_ASSOCIATED_ITEMS]}
                                                       field={ticketConstants.FORM_FIELD_ASSOCIATED_ITEMS}
                                                       isRequired={reqFields.includes(ticketConstants.FORM_FIELD_ASSOCIATED_ITEMS) && !readOnly}
                                                       isDisabled={!userRights.canEditHeader || readOnly}
                                                       isMulti={true}
                                                       intlMessages={formMessages}
                                                       message={ticketConstants.FORM_LABEL_ASSOCIATED_ITEMS}
                                                       onChange={handleChange}
                                                       loadRecords={handleLoadItems}
                                                       defaultValues={selectAssociatedItems}
                                                       isClearable={!readOnly}
                                                       placeholder={ticketConstants.ASSOCIATED_ITEMS_PLACEHOLDER}
                                                       isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col pb-2">
                        <InputFieldWithValidation
                            label={formMessages[ticketConstants.FORM_LABEL_SUBJECT]}
                            field={ticketConstants.FORM_FIELD_SUBJECT}
                            isDisabled={!userRights.canEditHeader || readOnly}
                            isRequired={reqFields.includes(ticketConstants.FORM_FIELD_SUBJECT) && !readOnly}
                            isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-sm-12 pb-2">
                        <TextareaFieldWithValidation
                            label={formMessages[ticketConstants.FORM_LABEL_DESCRIPTION]}
                            field={ticketConstants.FORM_FIELD_DESCRIPTION}
                            isRequired={reqFields.includes(ticketConstants.FORM_FIELD_DESCRIPTION) && !readOnly}
                            rows={10}
                            isDisabled={!userRights.canEditHeader || readOnly}
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

const mapStateToProps = (state, props) => ({
    roles: state.profile.userDetail.roles,
    isDealer: state.profile.userRoles.includes('RBOX_DEALER'),
    initialValues: getFormInitialValues(props.form)(state),
    selectAssociatedItems: formValueSelector(props.form)(state, `${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_ASSOCIATED_ITEMS}`),

});

export default connect(mapStateToProps, mapDispatchToProps)(TicketSection);
