import React, { Component } from 'react';
import { change, FieldArray, formValueSelector } from 'redux-form'
import { FormattedMessage } from 'react-intl';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import { BorderlessPrimaryIcon, StyledDiv } from '../../../../../common/StyledComponents';
import ResponsibilityTableHeader from './ResponsibilityTableHeader';
import ResponsibilityTableBody from './ResponsibilityTableBody';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { warnAlert } from '../../../../../../actions/alertsActions';
import { dealerConstants } from '../../../../../../constants/form/formDealerConstants';

class ResponsibilityItemsSection extends Component {

    renderOrders = ({ fields }) => {
        const { userRights, readOnly, form, reqFields, records } = this.props;
        console.log('records', records);
        return (
            <>
                <div className="table-responsive">
                    <table className="table table-hover mb-0">
                        <ResponsibilityTableHeader userRights={userRights} readOnly={readOnly} form={form}
                                                   reqFields={reqFields}/>
                        <tbody>
                        {fields.map((order, index) => <ResponsibilityTableBody key={index} form={form} index={index}
                                                                               record={order}
                                                                               userRights={userRights}
                                                                               readOnly={readOnly}
                                                                               handleRemoveResponsibility={() => {
                                                                                   fields.remove(index);
                                                                                   this.forceUpdate();
                                                                               }}
                                                                               reqFields={reqFields}
                                                                               />)}
                        </tbody>
                    </table>
                </div>
                {userRights.canEditItems && !readOnly &&
                <BorderlessPrimaryIcon type="button" className="btn float-right"
                                       onClick={() => fields.push({ index: fields.length })}
                                       disabled={fields.length >= 100}>
                    <FormattedMessage {...buttonMessages.ADD}/>
                </BorderlessPrimaryIcon>
                }
            </>
        )
    };

    render() {
        return (
            <>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    <div className="d-flex justify-content-md-center">
                        <FieldArray name={dealerConstants.FORM_FIELDS_RESPONSIBILITY_ITEMS} component={this.renderOrders}/>
                    </div>
                </StyledDiv>
            </>
        )
    }
}

const mapStateToProps = (state, props) => ({
    records: formValueSelector(props.form)(state, `${dealerConstants.FORM_DEALER_RESPONSIBILITY_INFO_SECTION}.${dealerConstants.FORM_FIELDS_RESPONSIBILITY_ITEMS}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            warnAlert
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibilityItemsSection);
