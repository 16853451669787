import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { clearAlerts } from '../../../../actions/alertsActions';
import historyMessages from '../../../../intl/rbox/historyMessages';
import Loader from '../../../Loader';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    getPreferences,
    updateUserListPreference,
} from '../../../../actions/rbox/actionPreferences';
import { getUserRole, getColumnsLibrary } from '../Utils';
import HistoryTable from '../history/HistoryTable';
import ColumnSelector from '../shared/ColumnSelector';

const HistoryPage = ({
    clearAlerts,
    getPreferences,
    updateUserListPreference,
    loggedUser,
    preferences,
    intl,
    userRoles,
}) => {
    const [ratchet, setRatchet] = useState(false);
    const [ticketId, setTicketId] = useState('');
    const [columnLibrary, setColumnLibrary] = useState(
        getColumnsLibrary(intl, userRoles)
    );

    useEffect(() => {
        getPreferences(loggedUser.ipn);
    }, [getPreferences, loggedUser.ipn]);

    const handleEditClick = ({ id }) => {
        setRatchet(true);
        setTicketId(id);
    };

    if (preferences.isLoading) {
        return <Loader />;
    }

    if (ratchet) {
        return <Redirect to={`/ticket/edit/${ticketId}`} />;
    }

    return (
        <div className="container-fluid">
            <PageHeader
                title={<FormattedMessage {...historyMessages.TITLE_HISTORY} />}
            />
            <ColumnSelector
                userRoles={userRoles}
                intl={intl}
                preferences={preferences}
                columnLibrary={columnLibrary}
                loggedUser={loggedUser}
                updateUserListPreference={updateUserListPreference}
            />
            <HistoryTable
                columns={columnLibrary[getUserRole(userRoles)].filter(
                    (item) =>
                        !get(preferences, 'menuList', { default: [] }).includes(
                            item.id
                        )
                )}
                handleEditClick={handleEditClick}
                toolButtonsVisible={false}
            />
        </div>
    );
};

HistoryPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    getPreferences: PropTypes.func.isRequired,
    updateUserListPreference: PropTypes.func.isRequired,
    domain: PropTypes.string.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    preferences: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail,
    preferences: state.preferences,
    userRoles: state.profile.userDetail.roles,
});

export default checkRoles(
    withRouter(
        connect(mapStateToProps, {
            clearAlerts,
            updateUserListPreference,
            getPreferences,
        })(injectIntl(HistoryPage))
    ),
    ['RBOX_EDITOR', 'RBOX_DEALER']
);
