import { fetchItemsPromise } from '../actions/actionItems';

export function handleItemSearch(inputValue, debouncedCallback, errorCallback) {
    fetchItemsPromise(inputValue).then(
        ({data}) => debouncedCallback(
            data.sort((item1, item2) => item1.name.localeCompare(item2.name))
                .map(mapItemToSelectionOption)
        ),
        error => errorCallback(error)
    );
}

export function mapItemToSelectionOption(item) {
    return {value: item.itemId, label: item.name};
}

