import { ALL_COUNTRIES_VALUE } from './Utils';
import { ticketConstants } from './form/formTicketConstants';
import { dealerConstants } from './form/formDealerConstants';

export const intlFieldMapping = {
    [ticketConstants.FORM_FIELD_MESSAGE]: ticketConstants.FORM_LABEL_MESSAGE,
    [ticketConstants.FORM_FIELD_DESCRIPTION]: ticketConstants.FORM_LABEL_DESCRIPTION,
    [ticketConstants.FORM_FIELD_SUBJECT]: ticketConstants.FORM_LABEL_SUBJECT,
    [ticketConstants.FORM_FIELD_IMPACT]: ticketConstants.FORM_LABEL_IMPACT,
    [ticketConstants.FORM_FIELD_URGENCY]: ticketConstants.FORM_LABEL_URGENCY,
    [ticketConstants.FORM_FIELD_PRIORITY]: ticketConstants.FORM_LABEL_PRIORITY,
    [ticketConstants.FORM_FIELD_ASSOCIATED_ITEMS]: ticketConstants.FORM_LABEL_ASSOCIATED_ITEMS,
    [ticketConstants.FORM_FIELD_STATUS]: ticketConstants.FORM_LABEL_STATUS,
    [ticketConstants.FORM_FIELD_COUNTRY]: ticketConstants.FORM_LABEL_COUNTRY,

    [dealerConstants.FORM_FIELD_DEALER_NUMBER]: dealerConstants.FORM_LABEL_DEALER_NUMBER,
    [dealerConstants.FORM_FIELD_EMAIL]: dealerConstants.FORM_LABEL_EMAIL,
    [dealerConstants.FORM_FIELDS_RESPONSIBILITY_ITEMS]: dealerConstants.FORM_LABEL_RESPONSIBILITY_ITEMS,

    [ALL_COUNTRIES_VALUE]: ALL_COUNTRIES_VALUE,
};

