export const ticketConstants = {
    //Ticket Section
    FORM_TICKET_SECTION: 'ticketSection',
    //Fields
    FORM_FIELD_CREATOR_IPN: 'creatorIpn',
    FORM_FIELD_CREATOR_NAME: 'creatorName',
    FORM_FIELD_DEALER_NO: 'dealerNumber',
    FORM_FIELD_IMPACT: 'impact',
    FORM_FIELD_URGENCY: 'urgency',
    FORM_FIELD_PRIORITY: 'priority',
    FORM_FIELD_ASSOCIATED_ITEMS: 'associatedItems',
    FORM_FIELD_COUNTRY: 'country',
    FORM_FIELD_STATUS: 'status',
    FORM_FIELD_DESCRIPTION: 'description',
    FORM_FIELD_SUBJECT: 'subject',
    FORM_FIELD_ASSIGNED_TO: 'assignedTo',
    //Labels
    FORM_LABEL_CREATOR_IPN: 'CREATOR_IPN',
    FORM_LABEL_CREATOR_NAME: 'CREATOR_NAME',
    FORM_LABEL_IMPACT: 'IMPACT',
    FORM_LABEL_URGENCY: 'URGENCY',
    FORM_LABEL_PRIORITY: 'PRIORITY',
    FORM_LABEL_ASSOCIATED_ITEMS: 'ASSOCIATED_ITEMS',
    FORM_LABEL_DEALER_NO: 'DEALER_NO',
    FORM_LABEL_COUNTRY: 'COUNTRY',
    FORM_LABEL_STATUS: 'STATUS',
    FORM_LABEL_DESCRIPTION: 'DESCRIPTION',
    FORM_LABEL_SUBJECT: 'SUBJECT',
    FORM_LABEL_ASSIGNED_TO: 'ASSIGNED_TO',

    //Messages section
    FORM_NOTES_SECTION: 'messagesSection',
    //Fields
    FORM_FIELD_MESSAGE: 'newMessage',
    //Label
    FORM_LABEL_MESSAGE: 'NOTE',
    FORM_LABEL_NOTES_HISTORY: 'NOTES_HISTORY',

    //Attachments section
    FORM_ATTACHMENTS_SECTION: 'attachmentsSection',
    //Fields
    FORM_FIELD_NEW_ATTACHMENTS: 'newAttachments',
    FORM_FIELD_ATTACHMENTS: 'attachments',

    ASSOCIATED_ITEMS_PLACEHOLDER: 'ASSOCIATED_ITEMS_PLACEHOLDER',
};
