import get from 'get-value';

export function isEmptyObject(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export const initLoadedResponsibility = (dealerData) => {
    const { responsibilityPermission = [] } = dealerData;
    console.log(responsibilityPermission);

    return {
        responsibilitySection: {
            responsibilityItems: [
                ...responsibilityPermission
            ]
        }
    };
};

export const transformUpdateResponsibilityForSave = (values) => {
    console.log('Transform to save', { values });
    const { oldValues, newValues } = values;
    const data = {};

    const newItems = get(newValues, 'responsibilitySection.responsibilityItems');
    const oldItems = get(oldValues, 'responsibilitySection.responsibilityItems');

    console.log('get', { newItems, oldItems});
    data.list = newItems.map(({ dealerNumber, email }) => ({ dealerNumber, email }));
    console.log('Save to profile', { data });

    const oldBirList = oldItems.map(({ dealerNumber }) => dealerNumber);
    const newBirList = newItems.map(({ dealerNumber }) => dealerNumber);

    data.removedList = oldBirList.filter(old => !newBirList.includes(old));

    console.log('Final data', { data });
    return data;
};

