import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchTicket, resetTicket, saveTicket } from '../../../../actions/rbox/actionTicket';
import Loader from '../../../Loader';
import TicketForm from './forms/TicketForm';
import { getUserRole, initNewTicket, transformNewTicketForSave } from '../Utils';
import { createRights } from '../../../../constants/ticketRights';
import { createReqFields } from '../../../../constants/requiredFields';
import PageHeader from '../../../common/PageHeader';
import get from 'get-value';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import formMessages from '../../../../intl/rbox/formMessages';
import { FormattedMessage } from 'react-intl';
import UploadingAttachments from './UploadingAttachments';
import { handleItemSearch } from '../../../../utils/itemUtils';
import { handleFetchItemsError } from '../../../../actions/actionItems';
import NoBirComponent from '../../../common/NoBirComponent';

class NewTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketSubmittedAndShouldRedirect: false,
            options: []
        };
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetTicket();
    }

    handleSubmit = (values) => {
        const dataForSend = transformNewTicketForSave(values.newValues);
        this.props.saveTicket(dataForSend);
        this.setState({ ticketSubmittedAndShouldRedirect: true });
    };

    handleLoadRecords = (values, callback) => {
        handleItemSearch(values, callback, this.props.handleFetchItemsError);
    }

    render() {
        const {
            ticketData,
            userDetail,
            userRoles,
            formType,
            attachmentsData
        } = this.props;

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingAttachments attachments={attachmentsData.attachmentsForUpload}
                                         attachmentsUrl={ticketData.newAttachments}
                                         correlationId={ticketData.correlationId}
                                         isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                         formType={formType}/>
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', { default: false })) {
            return <Redirect to={{
                pathname: `/ticket/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (ticketData.isTicketCreating
            || ticketData.isTicketLoading
            || !formType
            || this.state.ticketSubmittedAndShouldRedirect
        ) {
            return <Loader/>;
        }

        let initializationValue = initNewTicket(userDetail, userRoles);
        if (!initializationValue) {
            return <NoBirComponent formType={formType}/>;
        }
        const requiredFields = createReqFields[getUserRole(this.props.loggedUser.roles)][formType];

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={<FormattedMessage {...formMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <TicketForm
                            viewType={'NEW'}
                            form={formType}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            userRights={createRights}
                            enableReinitialize={true}
                            handleSubmit={this.handleSubmit}
                            handleLoadItems={this.handleLoadRecords}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

NewTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    ticketData: PropTypes.object.isRequired,
    fetchTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any,
    resetTicket: PropTypes.func.isRequired,
    saveTicket: PropTypes.func.isRequired,
    isLoadingProfile: PropTypes.bool.isRequired,
    attachmentsData: PropTypes.object.isRequired,
    handleFetchItemsError: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail,
    clearAlerts: PropTypes.func.isRequired,
    ticketData: state.ticket,
    userDetail: state.profile.userDetail,
    userRoles: state.profile.userRoles,
    isLoadingProfile: state.profile.isLoading,
    attachmentsData: state.rboxAttachment,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchTicket,
    saveTicket,
    resetTicket,
    handleFetchItemsError
})(NewTicketPage)), ['RBOX_DEALER', 'RBOX_EDITOR_CREATOR']);

