import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FieldArray, formValueSelector } from 'redux-form';
import { validTicketFiles } from '../../../../../../common/validationCommons';
import { connect } from 'react-redux';
import { downloadAttachment } from '../../../../../../../actions/rbox/actionAttachments';
import RenderDropZone from '../../../../../../common/RenderDropZone';
import UploadedAttachments from './UploadedAttachments';
import formMessages from '../../../../../../../intl/rbox/formMessages';
import { ticketConstants } from '../../../../../../../constants/form/formTicketConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const AttachmentSection = (props) => {
    const { attachments = {}, userRights, form, readOnly } = props;

    return (
        <>
            {((userRights.canAddAttach && !readOnly) || (attachments && Object.keys(attachments).length > 0)) &&
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.ATTACHMENTS_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {(attachments && attachments.length > 0) && <UploadedAttachments
                        attachments={attachments} label={formMessages.ATTACHMENTS_SECTION} form={form}/>
                    }
                    {userRights.canAddAttach && !readOnly && <FieldArray
                        name={ticketConstants.FORM_FIELD_NEW_ATTACHMENTS}
                        component={RenderDropZone}
                        validate={validTicketFiles}/>
                    }
                </StyledDiv>
            </>
            }
        </>
    )
};

const mapStateToProps = (state, initialProps) => ({
    attachments: formValueSelector(initialProps.form)(state, `${ticketConstants.FORM_ATTACHMENTS_SECTION}.${ticketConstants.FORM_FIELD_ATTACHMENTS}`),
    ticketId: formValueSelector(initialProps.form)(state, `${ticketConstants.FORM_TICKET_SECTION}.uuid`),
});

export default connect(mapStateToProps, {
    downloadAttachment,
})(AttachmentSection);
