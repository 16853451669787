import {
    rboxTicketWebsocketCommands
} from '../../constants/rboxTicketWebsocketCommands';
import {extractErrorText} from '../actionUtils';
import {uploadRboxAttachment} from '../../api/restRboxAttachments';
import {errorAlert} from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';

export const RBOX_ATTACHMENTS_UPLOADING = 'RBOX_ATTACHMENTS_UPLOADING';
export const RBOX_ATTACHMENTS_UPLOADING_ERROR = 'RBOX_ATTACHMENTS_UPLOADING_ERROR';

export const startUploadAttachments = () => dispatch => {
    return dispatch({type: RBOX_ATTACHMENTS_UPLOADING});
};

export const downloadAttachment = (uuid, ticketId, name) => dispatch => {
    return dispatch({
        type: rboxTicketWebsocketCommands.RBOX_DOWNLOAD_PRESIGNED_URL,
        rboxWebsocket: true,
        command: rboxTicketWebsocketCommands.RBOX_DOWNLOAD_PRESIGNED_URL,
        payload: {
            payload: {
                attachment: {
                    uuid,
                    ticketId,
                    name
                }
            }
        }
    });
};

export const uploadAttachments = (attachments = [], correlationId) => dispatch => {
    const promises = [];
    const savedAttachments = [];
    attachments.forEach(attachment => {
        promises.push(
            uploadRboxAttachment(attachment.url, attachment.localUrl, attachment.name, attachment.contentType).then(
                () => {
                    window.URL.revokeObjectURL(attachment.localUrl);
                    savedAttachments.push(attachment);
                }, error => {
                    dispatch({type: RBOX_ATTACHMENTS_UPLOADING_ERROR});
                    dispatch(errorAlert(...extractErrorText(error, alertMessages.FILE_UPLOAD_ERROR)));
                }
            )
        )
    });
    return Promise.all(promises).then(() => {
            if (savedAttachments.length > 0) {
                dispatch({
                    type: rboxTicketWebsocketCommands.RBOX_CREATE_ATTACHMENTS,
                    rboxWebsocket: true,
                    command: rboxTicketWebsocketCommands.RBOX_CREATE_ATTACHMENTS,
                    payload: {
                        payload: {
                            attachments: savedAttachments,
                            correlationId,
                            uuid: savedAttachments[0].ticketId,
                        }
                    }
                })
            } else {
                dispatch({
                    type: RBOX_ATTACHMENTS_UPLOADING_ERROR,
                })
            }
        }
    )
};
