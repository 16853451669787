import { rboxWebsocketCommands } from '../../constants/rboxWebsocketCommands';

export const ADMIN_NOTIFICATION_SELECT_GROUP = 'ADMIN_NOTIFICATION_SELECT_GROUP';
export const ADMIN_NOTIFICATION_FETCH = 'ADMIN_NOTIFICATION_FETCH';
export const ADMIN_NOTIFICATION_UPDATE = 'ADMIN_NOTIFICATION_UPDATE';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_NOTIFICATION_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchNotification = (group) => dispatch => {
    return dispatch({
        type: ADMIN_NOTIFICATION_FETCH,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_NOTIFICATIONS_GET,
        payload: { group }
    });
};

export const updateNotification = (notification) => dispatch => {
    return dispatch({
        type: ADMIN_NOTIFICATION_UPDATE,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_NOTIFICATIONS_SET,
        payload: { notification }
    });
};

