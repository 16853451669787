import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { HOME_PATH, ADMINISTRATION_PATH, RBOX_PATH } from '../../../routes/paths';
import { PRIMARY_DARK_GREY, PRIMARY_YELLOW } from '../../../theme/colors';
import { closeSupportModal, saveUserSettings, showSupportModal } from '../../../actions/actionSupport';
import { moduleRoles } from '../../../utils/roles';
import commonMessages from '../../../intl/common/commonMessages';
import menuMessages from '../../../intl/layout/menuMessages';
import { domains } from '../../../constants/Utils';
import { FormattedMessage } from 'react-intl';
import SupportModal from './SupportModal';


const TopNavigation = styled.nav`
    font-family: sans-serif;
    font-weight: bold;
    padding: 1px 10px;
    background-color: ${PRIMARY_DARK_GREY};
`;

const UserNav = styled.div`
    font-family: sans-serif;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.85);
    margin: 5px 0px 5px 5px;
    display: block;
    padding: .5rem 0rem .5rem 1rem;
`;

const MenuLink = styled(NavLink)`
    :visited,
    :link {
        color: rgba(255, 255, 255, 0.85);
    }
    :active,
    :hover {
        background-color: ${PRIMARY_YELLOW};
        color: black;
    }
    transition: all 0.5s ease;

    && {
        padding: 0.6em 1em;
        margin: 5px;
    }

    &.${'nav-item-active'} {
        background-color: ${PRIMARY_YELLOW};
        color: black;
    }
`;

const CollapseButton = styled.button`
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.7);
    border-radius: .25em;
`;

const SupportButton = styled.div`
    cursor: pointer;
    :visited {
        color: rgba(255, 255, 255, 0.85);
    }
    :active,
    :hover {
        background-color: ${PRIMARY_YELLOW};
        color: black;
    }
    transition: all 0.5s ease;

    && {
        margin: 5px;
        display: block;
        padding: .5rem;
        border-radius: .25rem;
    }
    color: white;
`;

function TopBar({ userDetail: { roles, name, group }, isModalOpen, showSupportModal, closeSupportModal, language, locale, saveUserSettings }) {
    return (
        <TopNavigation className="navbar navbar-expand-md nav-pills">
            <MenuLink exact className="nav-link" to={HOME_PATH} activeClassName="nav-item-active">
                <FontAwesomeIcon icon="home" size="lg"/>
            </MenuLink>
            <CollapseButton className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <FontAwesomeIcon icon="bars" size="lg"/>
            </CollapseButton>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav mr-auto">
                    {(moduleRoles.isAdmin(roles) || moduleRoles.isPowerUser(roles)) &&
                    <li className="nav-item">
                        <MenuLink className="nav-link" to={ADMINISTRATION_PATH} activeClassName="nav-item-active">
                            <FormattedMessage {...menuMessages.ADMINISTRATION}/>
                        </MenuLink>
                    </li>}
                    {(moduleRoles.isDealer(roles) || moduleRoles.isEditor(roles)) &&
                        <li className="nav-item">
                            <MenuLink className="nav-link" to={RBOX_PATH} activeClassName="nav-item-active">
                                <FormattedMessage {...menuMessages.RBOX}/>
                            </MenuLink>
                        </li>
                    }
                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <UserNav>
                            {name}
                            {(moduleRoles.isAdmin(roles, domains)) && <span> - <FormattedMessage {...commonMessages.ADMIN}/></span>}
                            {(moduleRoles.isEditor(roles)) && <span> - <FormattedMessage {...commonMessages.EDITOR}/></span>}
                            {(moduleRoles.isDealer(roles)) && <span> - <FormattedMessage {...commonMessages.DEALER}/></span>}
                        </UserNav>
                    </li>
                    <li className="nav-item">
                        <SupportButton onClick={showSupportModal}>
                            <FontAwesomeIcon icon="cog" size="lg"/>
                        </SupportButton>
                    </li>
                </ul>
            </div>
            {isModalOpen && <SupportModal show={isModalOpen}
                                          closeModal={closeSupportModal}
                                          initialValues={{
                                              language: language.indexOf('-') >= 0 ? language.substring(0, language.indexOf('-')) : language,
                                              locale,
                                          }}
                                          saveModal={(value) => {
                                              saveUserSettings({
                                                  language: value.language,
                                                  locale: value.locale,
                                              });
                                          }}
            />}
        </TopNavigation>
    );
}

TopBar.propTypes = {
    userDetail: PropTypes.object.isRequired,
    saveUserSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    userDetail: state.profile.userDetail,
    isModalOpen: state.supportModal.isModalOpen,
    language: state.intl.language,
    locale: state.intl.locale,
});

export default withRouter(connect(mapStateToProps, {
    closeSupportModal,
    showSupportModal,
    saveUserSettings,
})(TopBar));
