import axios from 'axios';

export const uploadRboxAttachment = (url, fileUrl, name, contentType) => {
    const config = {
        headers: { 'Content-Type': contentType },
    };
    return axios.get(fileUrl, {
        responseType: "blob"
    }).then(response => {
        return axios.create().put(url, response.data, config)
    });
};
