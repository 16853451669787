import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import { getAllFormActions, getAllFormStates } from '../../../constants/Utils';
import {
    fetchRboxUserNotificationPreferences,
    updateRboxUserNotificationPreference
} from '../../../actions/rbox/actionPreferences';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import notificationMessages from '../../../intl/common/notificationMessages';
import NotificationRboxForm from './NotificationRboxForm';
import Loader from '../../Loader';
import formActionsMessages from '../../../intl/common/formActionsMessages';

class NotificationRboxPage extends Component {
    componentDidMount() {
        this.props.fetchRboxUserNotificationPreferences();
    }

    handleSubmit = (values) => {
        const preferences = {
            statuses: get(values, `statuses`, []),
            actions: get(values, `actions`, []),
        };
        this.props.updateRboxUserNotificationPreference(preferences);
    };

    compare = (arr1, arr2) => {
        const newArray = [];
        arr1.forEach((e1) => arr2.forEach((e2) => {
            if (e1 !== e2) {
                newArray.push(e1)
            }
        }));
        return newArray
    };

    render() {
        const { intl: {formatMessage}, isLoadingPreferences, statuses, actions} = this.props;

        if (isLoadingPreferences) {
            return <Loader/>;
        }

        const statusOptions = (getAllFormStates || []).map(status => ({
            value: status,
            label: formStatesMessages[status] ? formatMessage(formStatesMessages[status]) : status
        }));

        const actionOptions = (getAllFormActions || []).map(action => ({
            value: action,
            label: formActionsMessages[action] ? formatMessage(formActionsMessages[action]) : action
        }));

        const initialValues = {
            statuses,
            actions
        };

        return (
            <div>
                <PageHeader title={<FormattedMessage {...notificationMessages.TITLE}/>}/>
                 <>
                    <div className="container-fluid">
                        <NotificationRboxForm initialValues={initialValues}
                                                   enableReinitialize={true}
                                                   onSubmit={this.handleSubmit}
                                                   statusOptions={statusOptions}
                                                   actionOptions={actionOptions}
                                                   form={'NotificationRboxForm'}/>
                    </div>
                </>
            </div>
        )
    }
}

NotificationRboxPage.propTypes = {
    handleSubmit: PropTypes.func,
    userDetail: PropTypes.object,
};

const mapStateToProps = (state) => ({
    isLoadingPreferences: state.preferences.isLoading,
    statuses: state.preferences.statuses,
    actions: state.preferences.actions,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchRboxUserNotificationPreferences,
    updateRboxUserNotificationPreference
})(injectIntl(NotificationRboxPage))), ['RBOX_DEALER', 'RBOX_EDITOR']);
