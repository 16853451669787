import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import Table from '../../../common/table/TicketTable';
import { debounce } from 'throttle-debounce';
import { Link } from 'react-router-dom';
import moment from 'moment';
import notificationListMessages from '../../../../intl/rbox/listMessages';
import { fetchListPageData } from '../../../../actions/rbox/actionNotificationListPage';

class NotificationTable extends React.Component {
    constructor(props) {
        super(props);
        //const urlParams = new URLSearchParams(props.location.search);
        const loadedState = this.loadStateFromSessionStorage({
            listNotificationDefaultPageSize: 10,
            listNotificationDefaultSort: [{ id: 'ticketNumber', desc: true }],
            listNotificationDefaultFilters: [],
        });
        this.state = {
            ...loadedState
        }
        this.filtering = false;
    }

    handleFetchDataDebounced = state => {
        debounce(1000, this.handleFetchData(state));
    };

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage.bind(this)
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage.bind(this)
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^listNotification*/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage() {
        for (let key in this.state) {
            if (this.state.hasOwnProperty(key) && key.match(/^listNotification*/)) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = state => {
        this.props.fetchListPageData(state.pageSize, state.sorted, state.cursor, state.isNextFromPivot, this.normalizeFiltered(state));
        this.filtering = false;
        this.setState({
            listNotificationDefaultPageSize: state.pageSize,
            listNotificationDefaultSort: state.sorted,
            listNotificationDefaultFilters: state.filtered
        });
    };

    fetchStrategy = state => {
        this.filtering ? this.handleFetchDataDebounced(state) : this.handleFetchData(state);
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const { columns, data, pages, isLoading, loggedUser, isNextPage, isNextFromPivot } = this.props;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'ticketNumber',
                                    accessor: 'ticketNumber',
                                    message: notificationListMessages.TABLE_TICKET_NUMBER,
                                    Cell: (row) => <Link
                                        to={`view/${row.original.id}`}>{row.value}</Link>,
                                },
                                ...columns]}
                            toolButtons={[]}
                            isLoading={isLoading}
                            data={data}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            loggedUser={loggedUser}
                            buttonVisibility={[]}
                            handleOnFilteredChange={() => {
                                this.filtering = true;
                            }}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={this.state.listNotificationDefaultFilters}
                            defaultSorting={this.state.listNotificationDefaultSort}
                            defaultPageSize={this.state.listNotificationDefaultPageSize}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

NotificationTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    pages: PropTypes.number.isRequired,
    fetchListPageData: PropTypes.func.isRequired,
    columns: PropTypes.array,
    userRoles: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    handleEditClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.notificationListTicket.isTableLoading,
    data: state.notificationListTicket.listData,
    isNextPage: state.notificationListTicket.isNextPage,
    isNextFromPivot: state.notificationListTicket.isNextFromPivot,
    pages: state.notificationListTicket.pages,
    userRoles: state.profile.userDetail.roles,
    loggedUser: state.profile.userDetail,
});

export default connect(mapStateToProps, {
    fetchListPageData,
})(NotificationTable);
