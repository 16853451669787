import get from 'get-value';
import {
    ADMIN_NOTIFICATION_SELECT_GROUP,
    ADMIN_NOTIFICATION_FETCH,
    ADMIN_NOTIFICATION_UPDATE
} from '../../actions/admin/actionAdminNotification';
import {rboxWebsocketCommands} from '../../constants/rboxWebsocketCommands';

export const adminNotification = (state = {
    isLoading: true,
    emailData: {},
    selectedCountryGroup: ''
}, action) => {
    switch (action.type) {
        case ADMIN_NOTIFICATION_SELECT_GROUP: {
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        }
        case ADMIN_NOTIFICATION_FETCH:
            return Object.assign({}, state, {isLoading: true, emailData: {}});
        case ADMIN_NOTIFICATION_UPDATE: {
            return Object.assign({}, state, {isLoading: true, emailData: {}});
        }

        case rboxWebsocketCommands.RBOX_NOTIFICATIONS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                emailData: get(action, 'payload.notification', {default: {}}),
                correlationId
            });
        }

        case rboxWebsocketCommands.RBOX_NOTIFICATIONS_ERROR:
        case rboxWebsocketCommands.RBOX_NOTIFICATIONS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, emailData: {}, correlationId});
        }
        default:
            return state;
    }
};

