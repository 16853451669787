import React from 'react';
import { dealerConstants } from '../../../../../../constants/form/formDealerConstants';
import { StyledSpan, StyledTh, StyledThead, StyledToolsTh, } from '../../../../../common/StyledComponents';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../intl/rbox/formMessages';
import PropTypes from 'prop-types';

const ResponsibilityTableHeader = (props) => {
    const { userRights, readOnly, reqFields } = props;
    return (
        <StyledThead>
            <tr>
                <StyledTh>
                    <ValidFormattedMessage message={dealerConstants.FORM_LABEL_DEALER_NUMBER} intlMessages={formMessages}/>
                    {reqFields.includes(dealerConstants.FORM_FIELD_DEALER_NUMBER) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={dealerConstants.FORM_LABEL_EMAIL} intlMessages={formMessages}/>
                    {reqFields.includes(dealerConstants.FORM_FIELD_EMAIL) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                {userRights.canEditItems && !readOnly && <StyledToolsTh/>}
            </tr>
        </StyledThead>
    )
}

ResponsibilityTableHeader.propTypes = {
    readOnly: PropTypes.bool,
    form: PropTypes.string.isRequired,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

export default ResponsibilityTableHeader;
