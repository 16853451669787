import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    RBOX_EXPORT_PATH,
    RBOX_NOTIFICATION_LIST_PATH,
    RBOX_NOTIFICATION_PATH, RBOX_TICKET_HISTORY_PATH,
    RBOX_TICKET_LIST_PATH,
    RBOX_TICKET_NEW_PATH
} from '../../../../routes/paths';
import menuMessages from '../../../../intl/layout/menuMessages';
import { moduleRoles } from '../../../../utils/roles';

const history = {
    label: <FormattedMessage {...menuMessages.RBOX_HISTORY}/>,
    to: RBOX_TICKET_HISTORY_PATH
};

const newTicket = {
    label: <FormattedMessage {...menuMessages.RBOX_NEW}/>,
    to: RBOX_TICKET_NEW_PATH
};

const list = {
    label: <FormattedMessage {...menuMessages.RBOX_LIST}/>,
    to: RBOX_TICKET_LIST_PATH
};

const notification = {
    label: <FormattedMessage {...menuMessages.NOTIFICATION}/>,
    to: RBOX_NOTIFICATION_PATH
};

export const getRboxMenuEditor = (roles, isHistoryVisible) => {
    let result = [];

    if (moduleRoles.isEditor(roles)) {
        if (moduleRoles.hasEditorActionRole(roles, 'CREATE_TICKET')) {
            result.push(newTicket);
        }

        result.push(list);

        if (isHistoryVisible) {
            result.push(history);
        }
        result.push(
            {
                label: <FormattedMessage {...menuMessages.RBOX_NOTIFICATION_LIST}/>,
                to: RBOX_NOTIFICATION_LIST_PATH
            },
            notification,
            {
                label: <FormattedMessage {...menuMessages.RBOX_EXPORT}/>,
                to: RBOX_EXPORT_PATH
            }
        );
    }
    return result;
}

export const getRboxMenuDealer = (isHistoryVisible) => {
    const menu = [
        newTicket,
        list,
        notification
    ];

    if (isHistoryVisible) {
        menu.splice(2, 0, history);
    }
    return menu;
}



