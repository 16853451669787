import {axiosInstance} from './rest';

export async function getUserRole(ipn) {
    return axiosInstance.get(`rbox/roles/${ipn}`);
}

export async function saveUser() {
    return axiosInstance.post(`rbox/roles`);
}

export async function getUsers(textSearch) {
    let text_search = '';
    if (textSearch !== undefined && textSearch !== null) {
        text_search = textSearch;
    }
    return axiosInstance.get(`rbox/roles`, {params: {text_search}});
}
