import { FormattedMessage } from 'react-intl';
import listMessages from '../../../../intl/rbox/listMessages';
import { getUserRole } from '../Utils';
import get from 'get-value';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';

const StyledPicky = styled(Picky)`
    button:first-child {
        border-radius: 0.3em;
    }
`;
const ColumnSelector = ({
    columnLibrary,
    userRoles,
    preferences,
    updateUserListPreference,
    loggedUser,
    intl,
}) => {
    const selectMultipleOption = (value) => {
        updateUserListPreference(
            loggedUser.ipn,
            columnLibrary[getUserRole(userRoles)]
                .filter((item) => !value.some((value) => value.id === item.id))
                .map((column) => column.id)
        );
    };
    return (
        <div className="d-flex justify-content-end mb-3">
            <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                <span className="font-weight-bold">
                    <FormattedMessage {...listMessages.VISIBLE_COLUMNS} />
                    {':'}
                </span>
                <StyledPicky
                    value={columnLibrary[getUserRole(userRoles)]
                        .filter(
                            (item) =>
                                !get(preferences, 'menuList', {
                                    default: [],
                                }).includes(item.id)
                        )
                        .map((col) => {
                            return {
                                id: col.id,
                                message: intl.formatMessage(col.message),
                            };
                        })}
                    options={columnLibrary[getUserRole(userRoles)].map(
                        (col) => {
                            return {
                                id: col.id,
                                message: intl.formatMessage(col.message),
                            };
                        }
                    )}
                    onChange={selectMultipleOption}
                    open={false}
                    valueKey="id"
                    labelKey="message"
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={false}
                    dropdownHeight={600}
                />
            </div>
        </div>
    );
};

ColumnSelector.propTypes = {
    columnLibrary: PropTypes.object.isRequired,
    userRoles: PropTypes.array.isRequired,
    preferences: PropTypes.object.isRequired,
    updateUserListPreference: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
};

export default ColumnSelector;
