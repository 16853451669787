import { rboxTicketWebsocketCommands } from '../../constants/rboxTicketWebsocketCommands';
import { validateNewTicket, validateUpdateTicket } from '../../api/validation/ticket';
import { errorAlert } from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';

export const updateTicket = (data) => dispatch => {
    const { error } = validateUpdateTicket(data);

    if (error) {
        dispatch(errorAlert(alertMessages.TICKET_UPDATE_SEND_ERROR, [error.toString()]));
        dispatch({ type: rboxTicketWebsocketCommands.TICKET_SAVE_UPDATE_ERROR });
    }
    return dispatch({
        type: rboxTicketWebsocketCommands.RBOX_TICKET_UPDATE_PENDING,
        rboxWebsocket: true,
        command: rboxTicketWebsocketCommands.RBOX_UPDATE_TICKET,
        payload: {
            payload: {
                ...data
            }
        }
    });
};

export const saveTicket = (data) => dispatch => {
    const { error } = validateNewTicket(data);
    if (error) {
        dispatch(errorAlert(alertMessages.TICKET_NEW_SEND_ERROR, [error.toString()]));
        dispatch({ type: rboxTicketWebsocketCommands.TICKET_SAVE_NEW_ERROR });
        return;
    } else {
        return dispatch({
            type: rboxTicketWebsocketCommands.RBOX_TICKET_CREATE_PENDING,
            rboxWebsocket: true,
            command: rboxTicketWebsocketCommands.RBOX_CREATE_TICKET,
            payload: {
                payload: {
                    ...data,
                }
            }
        });
    }
};

export const fetchTicket = (uuid) => dispatch => {
    return dispatch({
        type: rboxTicketWebsocketCommands.RBOX_TICKET_DETAIL_PENDING,
        rboxWebsocket: true,
        command: rboxTicketWebsocketCommands.RBOX_GET_TICKET,
        payload: {
            ticket: {
                uuid,
            }
        }
    });
};

export const resetTicket = () => dispatch => {
    return dispatch({
        type: rboxTicketWebsocketCommands.RBOX_TICKET_RESET,
    });
};

