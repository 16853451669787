import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { startUploadAttachments, uploadAttachments } from '../../../../actions/rbox/actionAttachments';
import Loader from '../../../Loader';
import formMessages from '../../../../intl/rbox/formMessages';
import PageHeader from '../../../common/PageHeader';

const UploadingAttachments = (props) => {
    const { isAttachmentUploading, correlationId, attachmentsUrl, attachments } = props;

    const uploadAttachments = () => {
        const merge = [];
        attachmentsUrl.forEach(attachment => {
            const data = attachments.find(att => att.uuid === attachment.uuid);
            merge.push({
                ...attachment,
                ...data,
            })
        });
        props.uploadAttachments(merge, correlationId);
    };

    if (!isAttachmentUploading) {
        props.startUploadAttachments();
        uploadAttachments();
    }

    return (
        <div className="container-fluid">
            <PageHeader title={<FormattedMessage {...formMessages.TITLE}/>}/>
            <div className="container-fluid">
                <div className="col-12">
                    <FormattedMessage {...formMessages.UPLOADING_ATTACHMENTS}/>
                </div>
                <Loader/>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, {
    startUploadAttachments,
    uploadAttachments,
})(UploadingAttachments);

