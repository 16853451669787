import { defineMessages } from 'react-intl';
import { ticketConstants } from '../../constants/form/formTicketConstants';
import { dealerConstants } from '../../constants/form/formDealerConstants';

const messages = defineMessages({
    TITLE: {
        id: 'rbox.type.rbox',
        defaultMessage: 'Ticket'
    },
    UPLOADING_ATTACHMENTS: {
        id: 'rbox.ticket.attachments.uploadingAttachments',
        defaultMessage: 'Ticket was saved, uploading attachments...'
    },
    [ticketConstants.ASSOCIATED_ITEMS_PLACEHOLDER]: {
        id: 'rbox.ticket.associatedItems.placeholder',
        defaultMessage: 'Start typing...'
    },
    ATTACHMENTS_SECTION: {
        id: 'rbox.ticket.header.attachments',
        defaultMessage: 'Attachments'
    },
    NOTE_SECTION: {
        id: 'rbox.ticket.header.notes',
        defaultMessage: 'Notes'
    },
    [ticketConstants.FORM_LABEL_DESCRIPTION]: {
        id: 'rbox.ticket.table.description',
        defaultMessage: 'Description'
    },
    [ticketConstants.FORM_LABEL_NOTES_HISTORY]: {
        id: 'rbox.ticket.label.notesHistory',
        defaultMessage: 'Notes history'
    },
    [ticketConstants.FORM_LABEL_MESSAGE]: {
        id: 'rbox.ticket.label.note',
        defaultMessage: 'New note'
    },
    [ticketConstants.FORM_LABEL_STATUS]: {
        id: 'rbox.ticket.label.status',
        defaultMessage: 'Status'
    },
    [ticketConstants.FORM_FIELD_DESCRIPTION]: {
        id: 'rbox.ticket.label.description',
        defaultMessage: 'Problem description'
    },
    [ticketConstants.FORM_LABEL_CREATOR_IPN]: {
        id: 'rbox.ticket.label.creatorIpn',
        defaultMessage: 'Creator Ipn'
    },
    [ticketConstants.FORM_LABEL_PRIORITY]: {
        id: 'rbox.ticket.label.priority',
        defaultMessage: 'Priority'
    },
    [ticketConstants.FORM_LABEL_URGENCY]: {
        id: 'rbox.ticket.label.urgency',
        defaultMessage: 'Urgency'
    },
    [ticketConstants.FORM_LABEL_IMPACT]: {
        id: 'rbox.ticket.label.impact',
        defaultMessage: 'Impact'
    },
    [ticketConstants.FORM_LABEL_DEALER_NO]: {
        id: 'rbox.ticket.label.dealerNumber',
        defaultMessage: 'Dealer No'
    },
    [ticketConstants.FORM_LABEL_COUNTRY]: {
        id: 'rbox.ticket.label.country',
        defaultMessage: 'Country'
    },
    [ticketConstants.FORM_LABEL_ASSIGNED_TO]: {
        id: 'rbox.ticket.label.assignedTo',
        defaultMessage: 'Assigned To'
    },
    [ticketConstants.FORM_LABEL_CREATOR_NAME]: {
        id: 'rbox.ticket.label.creatorName',
        defaultMessage: 'Creator Name'
    },
    [ticketConstants.FORM_LABEL_ASSOCIATED_ITEMS]: {
        id: 'rbox.ticket.label.associatedItems',
        defaultMessage: 'Associated Items'
    },
    [ticketConstants.FORM_LABEL_SUBJECT]: {
        id: 'rbox.ticket.label.subject',
        defaultMessage: 'Subject'
    },
    [dealerConstants.FORM_LABEL_DEALER_NUMBER]: {
        id: 'rbox.dealer.label.dealerNumber',
        defaultMessage: 'Dealer number'
    },
    [dealerConstants.FORM_LABEL_EMAIL]: {
        id: 'rbox.dealer.label.email',
        defaultMessage: 'Custom email'
    },
    [dealerConstants.FORM_LABEL_REPLY_HISTORY]: {
        id: 'reset',
        defaultMessage: 'Reset form'
    }

});

export default messages;
