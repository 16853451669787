import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { clearAlerts } from '../../../../actions/alertsActions';
import ListTable from './ListTable';
import listMessages from '../../../../intl/rbox/listMessages';
import Loader from '../../../Loader';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import { getPreferences, updateUserListPreference } from '../../../../actions/rbox/actionPreferences';
import { getUserRole, getColumnsLibrary } from '../Utils';
import ColumnSelector from '../shared/ColumnSelector';

class ListPage extends Component {
    constructor(props) {
        super(props);
        const {intl, userRoles} = props;
        this.state = {
            ratchet: false,
            ticketId: '',
            columnLibrary: getColumnsLibrary(intl, userRoles)
        };
    }

    componentDidMount() {
        this.props.getPreferences(this.props.loggedUser.ipn);
    }

    handleEditClick = ({id}) => {
        this.setState({ratchet: true, ticketId: id});
    };

    render() {
        const {preferences} = this.props;
        const {columnLibrary, ticketId} = this.state;

        if (preferences.isLoading) {
            return <Loader/>
        }

        if (this.state.ratchet) {
            return <Redirect to={`/ticket/edit/${ticketId}`}/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader
                    title={<FormattedMessage {...listMessages.TITLE}/>}/>
                <ColumnSelector
                    userRoles={this.props.loggedUser.roles}
                    intl={this.props.intl}
                    preferences={this.props.preferences}
                    columnLibrary={columnLibrary}
                    loggedUser={this.props.loggedUser}
                    updateUserListPreference={this.props.updateUserListPreference}
                />
                <ListTable
                    columns={columnLibrary[getUserRole(
                        this.props.loggedUser.roles)].filter(
                        item => !get(preferences, 'menuList',
                            {default: []}).includes(item.id))}
                    handleEditClick={this.handleEditClick}
                    toolButtonsVisible={true}
                />
            </div>
        );
    }
}

ListPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    getPreferences: PropTypes.func.isRequired,
    updateUserListPreference: PropTypes.func.isRequired,
    domain: PropTypes.string.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    preferences: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    preferences: state.preferences,
    userRoles: state.profile.userDetail.roles,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateUserListPreference,
    getPreferences,
})(injectIntl(ListPage))), ['RBOX_EDITOR', 'RBOX_DEALER']);

