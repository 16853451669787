import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { addLocaleData } from 'react-intl';
import thunk from 'redux-thunk';
import enLocaleData from 'react-intl/locale-data/en';
import frLocaleData from 'react-intl/locale-data/fr';
import deLocaleData from 'react-intl/locale-data/de';
import initialState from './initialState';
import history from '../history.js';
import { routerMiddleware } from 'react-router-redux';
import metisMenuReducer from 'react-metismenu/lib/reducers';
import { createRboxSocketMiddleware, } from './socketMiddleware';
import { intl } from '../reducers/intlReducer';
import { menu } from '../reducers/menuReducer';
import { profile } from '../reducers/roleReducer';
import { adminRightsManagement } from '../reducers/admin/adminRightsManagementReducer';
import { alerts } from '../reducers/alertReducer';
import { websocket } from '../reducers/websocketReducer';
import { supportModal } from '../reducers/supportReducer';
import { reducer as form } from 'redux-form';
import createSagaMiddleware from 'redux-saga'
import rightsManagementSaga from '../sagas/rightsManagementSaga';
import { countrySettings, lockTicketCheck, ticket } from '../reducers/ticket/ticketReducer';
import { listTicket } from '../reducers/ticket/listReducer';
import { notificationListTicket } from '../reducers/ticket/notificationListReducer';
import { rboxAttachment } from '../reducers/ticket/attachmentReducer';
import { preferences } from '../reducers/preferencesReducer';
import supportModalSaga from '../sagas/supportModalSaga';
import ticketSaga from '../sagas/ticket/ticketSaga';
import { adminNotification } from '../reducers/admin/adminNotificationReducer';
import {exportTickets} from '../reducers/ticket/exportReducer';
import {historyTicket} from '../reducers/ticket/historyReducer';

/**
 * Configuration of store, reducers, middlewares, enhancers management.
 */
const configureStore = (initialStoreContent = {}) => {
    const persistedState = Object.assign(initialState, initialStoreContent);
    const middlewares = process.env.NODE_ENV !== 'production' ?
        [require('redux-immutable-state-invariant').default(), thunk] :
        [thunk];
    const enhancers = [];
    const sagaMiddleware = createSagaMiddleware();

    enhancers.push(window.devToolsExtension ? window.devToolsExtension() : f => f);

    const appReducers = combineReducers({
        intl,
        menu,
        profile,
        alerts,
        websocket,
        form,
        adminRightsManagement,
        supportModal,
        listTicket,
        historyTicket,
        exportTickets,
        notificationListTicket,
        ticket,
        lockTicketCheck,
        countrySettings,
        rboxAttachment,
        preferences,
        adminNotification,
        metisMenuStore: metisMenuReducer,
    });

    const store = createStore(
        appReducers,
        persistedState,
        compose(
            applyMiddleware(
                ...middlewares,
                createRboxSocketMiddleware(),
                routerMiddleware(history),
                sagaMiddleware
            ),
            ...enhancers
        )
    );

    addLocaleData(enLocaleData);
    addLocaleData(frLocaleData);
    addLocaleData(deLocaleData);

    sagaMiddleware.run(rightsManagementSaga);
    sagaMiddleware.run(supportModalSaga);
    sagaMiddleware.run(ticketSaga);

    return store;
};

export default configureStore;
