import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    ADMIN_RBOX_AUTH_MANAGEMENT: {
        id: 'admin.sideMenu.rbox.authorizationManagement',
        defaultMessage: 'Authorization management'
    },
    ADMINISTRATION: {
        id: 'topMenu.administration',
        defaultMessage: 'Administration'
    },
    ADMIN_NOTIFICATION: {
        id: 'rbox.sideMemu.adminNotification',
        defaultMessage: 'E-mail Notifications'
    },
    RBOX: {
        id: 'topMenu.rbox',
        defaultMessage: 'R-Box'
    },
    RBOX_LIST: {
        id: 'rbox.sideMenu.list.incidents',
        defaultMessage: 'Ticket list'
    },
    RBOX_HISTORY: {
        id: 'rbox.sideMenu.history.incidents',
        defaultMessage: 'Ticket history'
    },
    RBOX_NOTIFICATION_LIST: {
        id: 'rbox.sideMenu.list.notifications',
        defaultMessage: 'Notification list'
    },
    RBOX_EXPORT: {
        id: 'spareParts.sideMenu.export',
        defaultMessage: 'Export'
    },
    NOTIFICATION: {
        id: 'menu.notification',
        defaultMessage: 'E-mail Notification Setting'
    },
    RBOX_NEW: {
        id: 'rbox.sideMenu.new',
        defaultMessage: 'New Ticket'
    },
    ADMIN_RBOX_AUTH_MANAGEMENT_ADMIN: {
        id: 'admin.sideMenu.rbox.authorizationManagement.admin',
        defaultMessage: 'Admin'
    },
    ADMIN_RBOX_AUTH_MANAGEMENT_CENTRAL: {
        id: 'admin.sideMenu.rbox.authorizationManagement.central',
        defaultMessage: 'Central Editor'
    },
    ADMIN_RBOX_AUTH_MANAGEMENT_EDITOR: {
        id: 'admin.sideMenu.rbox.authorizationManagement.editor',
        defaultMessage: 'Editor'
    },
    ADMIN_RBOX_AUTH_MANAGEMENT_EDITOR_ROLE: {
        id: 'admin.sideMenu.rbox.authorizationManagement.editor.role',
        defaultMessage: 'Roles'
    },
    ADMIN_RBOX_AUTH_MANAGEMENT_EDITOR_ACTION: {
        id: 'admin.sideMenu.rbox.authorizationManagement.editor.action',
        defaultMessage: 'Actions'
    },
    ADMIN_RBOX_AUTH_MANAGEMENT_EDITOR_TEAM_ROLE: {
        id: 'admin.sideMenu.rbox.authorizationManagement.editor.teamRole',
        defaultMessage: 'Team Roles'
    },
    ADMIN_RBOX_AUTH_MANAGEMENT_DEALER: {
        id: 'dealer.sideMenu.rbox.authorizationManagement.dealer',
        defaultMessage: 'Dealer'
    },
    ADMIN_RBOX_AUTH_MANAGEMENT_DEALER_ROLE: {
        id: 'dealer.sideMenu.rbox.authorizationManagement.dealer.role',
        defaultMessage: 'Roles'
    },
    ADMIN_RBOX_AUTH_MANAGEMENT_DEALER_RESPONSIBILITY: {
        id: 'dealer.sideMenu.rbox.authorizationManagement.dealer.responsibility',
        defaultMessage: 'Responsibility'
    },
});


export default messages;
