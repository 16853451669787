import { rboxTicketWebsocketCommands, } from '../../constants/rboxTicketWebsocketCommands';
import get from 'get-value';

export const notificationListTicket = (state = {
        correlationId: null,
        isLoading: false,
        isTableLoading: false,
        listData: [],
        pages: 0,
        isNextFromPivot: true,
        isNextPage: false,
    }, action) => {
        switch (action.type) {
            case rboxTicketWebsocketCommands.RBOX_NOTIFICATION_LIST_RESET: {
                return Object.assign({}, state, { listData: [] })
            }
            case rboxTicketWebsocketCommands.RBOX_NOTIFICATION_LIST_PENDING: {
                return Object.assign({}, state, { isTableLoading: true });
            }
            case rboxTicketWebsocketCommands.RBOX_NOTIFICATION_LIST_SEND: {
                const { correlationId } = action.payload;
                if (correlationId < state.correlationId) {
                    return state;
                } else {
                    return Object.assign({}, state, {
                        isTableLoading: false,
                        listData: get(action, 'payload.result.data', { default: [] }),
                        pages: get(action, 'payload.result.pages', { default: 0 }),
                        isNextFromPivot: get(action, 'payload.result.isNextFromPivot', false),
                        isNextPage: get(action, 'payload.result.isNextPage', false),
                        correlationId
                    });
                }
            }
            case rboxTicketWebsocketCommands.RBOX_NOTIFICATION_LIST_SEND_ERROR: {
                const { correlationId } = action.payload;
                if (correlationId < state.correlationId) {
                    return state;
                } else {
                    return Object.assign({}, state, { isTableLoading: false, correlationId });
                }
            }
            default:
                return state;
        }
    }
;
