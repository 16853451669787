import { defineMessages } from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.title',
        defaultMessage: 'Administration module'
    },
    EDITOR_ROLE: {
        id: 'admin.title.auth.mng.editor.role',
        defaultMessage: 'Authorization Management - {domain} - Editor countries'
    },
    EDITOR_ACTION: {
        id: 'admin.title.auth.mng.editor.action',
        defaultMessage: 'Authorization Management - {domain} - Editor actions'
    },
    EDITOR_TEAM: {
        id: 'admin.title.auth.mng.editor.team',
        defaultMessage: 'Authorization Management - {domain} - Editor team roles'
    },
    CENTRAL_ROLE: {
        id: 'admin.title.auth.mng.central.role',
        defaultMessage: 'Authorization Management - {domain} - Central Editor countries'
    },
    ADMIN: {
        id: 'admin.title.auth.mng.admin',
        defaultMessage: 'Authorization Management - {domain} - Admin'
    },
    DEALER_ROLE: {
        id: 'admin.title.auth.mng.dealer.role',
        defaultMessage: 'Authorization Management - {domain} - Dealer role'
    },
    DEALER_RESPONSIBILITY: {
        id: 'admin.title.auth.mng.dealer.responsibility',
        defaultMessage: 'Authorization Management - {domain} - Dealer responsibility'
    },

});

export default messages;
