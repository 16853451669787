import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import get from 'get-value';
import {SubmissionError} from 'redux-form';
import moment from 'moment';
import ExportForm from './ExportForm';
import {createExport, downloadExport, fetchExport} from '../../../actions/rbox/actionExport';
import Loader from '../../Loader';
import {
    RBOX_EXPORT_FORM_FIELD_PFX_NUMBER,
    RBOX_EXPORT_FORM_FIELD_SFX_NUMBER,
    RBOX_EXPORT_FORM_FIELD_CHARSET,
    RBOX_EXPORT_FORM_FIELD_TYPE,
    XLS
} from '../../../constants/exportConstants';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';

class ExportPage extends Component {
    componentDidMount() {
        this.props.fetchExport();
    }

    handleSubmit = (values) => {
        const temp = JSON.parse(JSON.stringify((({dealer = {}}) => {
            const {dealerNumber, dealerName} = dealer;
            return ({
                dealerNumber: dealerNumber ? dealerNumber.trim() : undefined,
                dealerName: dealerName ? dealerName.trim() : undefined
            });
        })(values)));

        const exportFilter = {};
        exportFilter.filter = [];
        for (let key in temp) {
            if (temp.hasOwnProperty(key)) {
                exportFilter.filter.push({id: key, value: temp[key]})
            }
        }

        if (values.group) {
            exportFilter.group = values.group;
        }
        if (values.from) {
            exportFilter.from = moment(values.from).add(12, 'hours').toISOString().substr(0, 10);
        }
        if (values.to) {
            exportFilter.to = moment(values.to).add(12, 'hours').toISOString().substr(0, 10);
        }
        exportFilter.type = values.type;
        if (!exportFilter.type) {
            exportFilter.type = XLS;
        }
        exportFilter.charset = values.charset;
        this.props.createExport(exportFilter);
    };

    downloadExport = () => {
        this.props.downloadExport(this.props.exportRecord);
    };

    render() {
        const {isLoadingExport, charSets} = this.props;

        if (isLoadingExport) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage id="rbox.export.header" defaultMessage="Export"/>}/>
                <ExportForm onSubmit={this.handleSubmit}
                                 downloadExport={this.downloadExport}
                                 form={'rboxExportForm'}
                                 initialValues={{
                                     [RBOX_EXPORT_FORM_FIELD_TYPE]: XLS,
                                     [RBOX_EXPORT_FORM_FIELD_CHARSET]: charSets[0]
                                 }}
                />
            </div>
        );
    }
}

ExportPage.propTypes = {
    isLoadingExport: PropTypes.bool.isRequired,
    exportRecord: PropTypes.object.isRequired,
    fetchExport: PropTypes.func.isRequired,
    createExport: PropTypes.func.isRequired,
    downloadExport: PropTypes.func.isRequired,
    charSets: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    isLoadingExport: state.exportTickets.isLoadingExport,
    exportRecord: state.exportTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
});

export default checkRoles(connect(mapStateToProps, {
    fetchExport,
    createExport,
    downloadExport,
})(injectIntl(ExportPage)), ['RBOX_EDITOR']);
