import {defineMessages} from 'react-intl';

const messages = defineMessages({
    NEW: {
        id: 'export.new',
        defaultMessage: 'New'
    },
    PROCESSING: {
        id: 'export.processing',
        defaultMessage: 'Processing'
    },
    ERROR: {
        id: 'export.error',
        defaultMessage: 'Error'
    },
    NO_GROUP: {
        id: 'export.noGroup',
        defaultMessage: 'Error - User group missing'
    },
    TO_MANY: {
        id: 'export.toMany',
        defaultMessage: 'Error - To many records'
    },
    FINISH: {
        id: 'export.finish',
        defaultMessage: 'Finish'
    },
});

export default messages;
