import get from 'get-value';
import {rboxTicketWebsocketCommands} from '../../constants/rboxTicketWebsocketCommands';

export const exportTickets = (state = {
    isLoadingExport: false,
    exportRecord: {},
    correlationId: null,
}, action) => {
    switch (action.type) {
        case rboxTicketWebsocketCommands.RBOX_EXPORT_GET: {
            return Object.assign({}, state, { isLoadingExport: true });
        }
        case rboxTicketWebsocketCommands.RBOX_EXPORT_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            const exportRecord = get(action, 'payload.exportRecord', { default: {} });
            return Object.assign({}, state, {
                isLoadingExport: false,
                exportRecord,
                correlationId,
            });
        }
        case rboxTicketWebsocketCommands.RBOX_EXPORT_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoadingExport: false });
        }
        case rboxTicketWebsocketCommands.RBOX_EXPORT_CREATE: {
            const exportRecord = {
                created: new Date(),
                status: 'NEW'
            };
            return Object.assign({}, state, { exportRecord });
        }
        case rboxTicketWebsocketCommands.RBOX_EXPORT_STATUS:
        case rboxTicketWebsocketCommands.RBOX_EXPORT_ERROR: {
            const {correlationId, exportRecord} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { exportRecord, correlationId });
        }
        default:
            return state;
    }
};
