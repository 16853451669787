export const dealerConstants = {
    //Ticket Section
    FORM_DEALER_RESPONSIBILITY_INFO_SECTION: 'responsibilitySection',
    DEALER_RESPONSIBILITY_FORM: 'dealerResponsibilityForm',
    FORM_FIELD_DEALER_NUMBER: 'dealerNumber',
    FORM_FIELD_EMAIL: 'email',
    FORM_LABEL_DEALER_NUMBER: 'DEALER_NUMBER',
    FORM_LABEL_EMAIL: 'EMAIL',
    FORM_FIELDS_RESPONSIBILITY_ITEMS: 'responsibilityItems',
    FORM_LABEL_RESPONSIBILITY_ITEMS: 'FORM_LABEL_RESPONSIBILITY_ITEMS',
    FORM_LABEL_REPLY_HISTORY: 'FORM_LABEL_REPLY_HISTORY'

};
