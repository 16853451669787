import { rboxTicketWebsocketCommands } from '../../constants/rboxTicketWebsocketCommands';

export const fetchHistoryPageData =
    (pageSize, sorted, cursor, isNextFromPivot, filtered) => (dispatch) => {
        dispatch({
            type: rboxTicketWebsocketCommands.RBOX_TICKET_HISTORY_PENDING,
            rboxWebsocket: true,
            command: rboxTicketWebsocketCommands.RBOX_HISTORY_RECEIVE,
            payload: {
                pageSize: pageSize,
                sorted: sorted,
                cursor,
                isNextFromPivot,
                filtered: filtered,
            },
        });
    };
