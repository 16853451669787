import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    RBOX_WARN: {
        id: 'warnings.hdi.',
        defaultMessage: 'RBOX Warning'
    },
    ATTACHMENTS_SIZE: {
        id: 'warnings.bigAttachmentsSize',
        defaultMessage: 'Attachments size exceeded {filesSize}MB, please take a notice that larger files can take a while to upload'
    },
});

export default messages;
