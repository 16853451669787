import React from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import Table from '../../../common/table/TicketTable';
import { IconEdit, PrimaryIcon } from '../../../common/Button';
import { debounce } from 'throttle-debounce';
import { Link } from 'react-router-dom';
import moment from 'moment';
import listMessages from '../../../../intl/rbox/listMessages';
import { moduleRoles } from '../../../../utils/roles';

class TicketTable extends React.Component {
    constructor(props) {
        super(props);
        const loadedState = this.loadStateFromSessionStorage({
            listTicketDefaultPageSize: 20,
            listTicketDefaultSort: [{ id: 'ticketNumber', desc: true }],
            listTicketDefaultFilters: [],
        });

        this.state = {
            ...loadedState,
        };

        this.filtering = false;
        this.handleFetchDataDebounced = debounce(1000, this.handleFetchData);
    }

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage.bind(this)
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage.bind(this)
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults).reduce((acc, key) => {
            if (
                sessionStorage.hasOwnProperty(key) &&
                key.match(new RegExp(`^${this.props.keyToMatch}(.*)`))
            ) {
                let value = sessionStorage.getItem(key);
                value = JSON.parse(value);
                return {
                    ...acc,
                    [key]: value,
                };
            }
            return acc;
        }, defaults);
    }

    saveStateToSessionStorage() {
        for (let key in this.state) {
            if (this.state.hasOwnProperty(key) && key.match(
                new RegExp(`^${this.props.keyToMatch}(.*)`))) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = (state) => {
        this.props.fetchData(
            state.pageSize,
            state.sorted,
            state.cursor,
            state.isNextFromPivot,
            this.normalizeFiltered(state)
        );
        this.filtering = false;
        this.setState({
            listTicketDefaultPageSize: state.pageSize,
            listTicketDefaultSort: state.sorted,
            listTicketDefaultFilters: state.filtered,
        });
    };

    fetchStrategy = (state) => {
        this.filtering
            ? this.handleFetchDataDebounced(state)
            : this.handleFetchData(state);
    };

    normalizeFiltered = (state) => {
        return state.filtered
            .filter((filterItem) => filterItem.value)
            .map((filterItem) => {
                if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value)
                            .add(12, 'hours')
                            .toISOString()
                            .substr(0, 10),
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const {
            columns,
            data,
            pages,
            isLoading,
            handleEditClick,
            isNextPage,
            isNextFromPivot,
        } = this.props;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'ticketNumber',
                                    accessor: 'ticketNumber',
                                    message: listMessages.TABLE_TICKET_NUMBER,
                                    Cell: (row) => (
                                        <Link to={`view/${row.original.id}`}>
                                            {row.value}
                                        </Link>
                                    ),
                                },
                                ...columns,
                            ]}
                            toolButtons={
                                this.props.toolButtonsVisible
                                    ? [
                                          {
                                              visibility: [],
                                              buttonType: 'edit',
                                              userRole:
                                                  moduleRoles.isTeamEditor(
                                                      this.props.userRoles
                                                  )
                                                      ? moduleRoles.getEditorTeam(
                                                            this.props.userRoles
                                                        )
                                                      : moduleRoles.isDealer(
                                                            this.props.userRoles
                                                        )
                                                      ? 'DEALER'
                                                      : 'EDITOR',
                                              handleClick: handleEditClick,
                                              component: (
                                                  <PrimaryIcon
                                                      className="btn btn-sm pr-2 mr-2"
                                                      title="Edit"
                                                      type="button"
                                                      key="editButton"
                                                  >
                                                      <IconEdit />
                                                  </PrimaryIcon>
                                              ),
                                          },
                                      ]
                                    : []
                            }
                            isLoading={isLoading}
                            data={data}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.filtering = true;
                            }}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={
                                this.state.listTicketDefaultFilters
                            }
                            defaultSorting={this.state.listTicketDefaultSort}
                            defaultPageSize={
                                this.state.listTicketDefaultPageSize
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

TicketTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    pages: PropTypes.number.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    columns: PropTypes.array,
    userRoles: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    toolButtonsVisible: PropTypes.bool.isRequired,
    keyToMatch: PropTypes.string.isRequired
};

export default TicketTable;
