import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    TITLE: {
        id: 'notification.title',
        defaultMessage: 'E-mail Notification Setting'
    },
    STATUS_NOTIFICATION: {
        id: 'notification.status.notification',
        defaultMessage: 'Status Notifications'
    },
    ACTION_NOTIFICATION: {
        id: 'notification.action.notification',
        defaultMessage: 'Action Notifications'
    },
    STATUS_PLACEHOLDER: {
        id: 'notification.status.placeholder',
        defaultMessage: 'Select Statuses...'
    },
    ACTION_PLACEHOLDER: {
        id: 'notification.action.placeholder',
        defaultMessage: 'Select Actions...'
    },
    SUBJECT_PLACEHOLDER: {
        id: 'notification.subject.placeholder',
        defaultMessage: 'Subject Segments...'
    },
});

export default messages;
