import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    FORWARDED_TO_EDITOR: {
        id: 'button.forwardToEditor',
        defaultMessage: 'Forward to Editor'
    },
    TAKE_BACK: {
        id: 'button.takeBack',
        defaultMessage: 'Take back'
    },
    READ_BY_EDITOR: {
        id: 'button.readByEditor',
        defaultMessage: 'Take a ticket'
    },
    FORWARDED_TO_GLPI: {
        id: 'button.forwardToGlpi',
        defaultMessage: 'Forward to Econocom'
    },
    FORWARDED_TO_DEALER: {
        id: 'button.forwardToDealer',
        defaultMessage: 'Forward to Dealer'
    },
    SOLVE: {
        id: 'button.solve',
        defaultMessage: 'Solve'
    },
    SAVE: {
        id: 'button.save',
        defaultMessage: 'Save'
    },
    REOPEN: {
        id: 'button.reopen',
        defaultMessage: 'Reopen'
    },
    ACCEPT: {
        id: 'button.accept',
        defaultMessage: 'Accept'
    },
    RESET: {
        id: 'button.reset',
        defaultMessage: 'Reset'
    },
    CANCEL: {
        id: 'button.cancel',
        defaultMessage: 'Cancel'
    },
    BACK: {
        id: 'button.back',
        defaultMessage: 'Back'
    },
    EDIT: {
        id: 'button.edit',
        defaultMessage: 'Edit'
    },
    PREVIOUS: {
        id: 'button.previous',
        defaultMessage: 'Previous'
    },
    NEXT: {
        id: 'button.next',
        defaultMessage: 'Next'
    },
    CLOSE: {
        id: 'button.close',
        defaultMessage: 'Close'
    },
    ADD: {
        id: 'button.add',
        defaultMessage: 'Add'
    },
    LOAD: {
        id: 'button.load',
        defaultMessage: 'Load'
    },
    SEARCH: {
        id: 'button.search',
        defaultMessage: 'Search'
    },
    ALL: {
        id: 'button.all',
        defaultMessage: 'All'
    },
    CLEAR_ATTACHMENTS: {
        id: 'button.clearAttachments',
        defaultMessage: 'Clear attachments'
    },
    NO: {
        id: 'button.no',
        defaultMessage: 'No'
    },
    YES: {
        id: 'button.yes',
        defaultMessage: 'Yes'
    },
    FIRST: {
        id: 'button.first',
        defaultMessage: 'First'
    },
    LAST: {
        id: 'button.last',
        defaultMessage: 'Last'
    },
    CLEAR_ATTACHMENT: {
        id: 'button.clearAttachment',
        defaultMessage: 'Clear Attachment'
    },
    DOWNLOAD: {
        id: 'button.download',
        defaultMessage: 'Download'
    },
    EXPORT: {
        id: 'button.export',
        defaultMessage: 'Export'
    },
});

export default messages;
