import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'mainPage.title.welcome',
        defaultMessage: 'Welcome'
    },
    NO_RIGHTS: {
        id: 'welcome.noRights',
        defaultMessage: 'Your user has no rights for the application. Please contact your admin to assign some rights for you.'
    }
});

export default messages;
