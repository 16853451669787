import Joi from 'joi';
import {
    RBOX_TICKET_STATUS_CLOSED,
    RBOX_TICKET_STATUS_CLOSING,
    RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
    RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
    RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
    RBOX_TICKET_STATUS_NEW,
    RBOX_TICKET_STATUS_READ_BY_EDITOR,
    RBOX_TICKET_STATUS_SOLVED,
    RBOX_TICKET_STATUS_SOLVING,
} from '../../constants/form/statusConstants';

export function validateNewTicket(ticketData) {
    return Joi.validate(
        ticketData,
        Joi.object().required().keys({
            ticket: getNewTicketFormat(),
            newAttachments: Joi.array().items(getNewAttachment()),
        }))
}

export function validateUpdateTicket(ticketData) {
    return Joi.validate(
        ticketData,
        Joi.object().required().keys({
            ticket: getUpdateTicketFormat().allow(),
            newAttachments: Joi.array().items(getNewAttachment()),
            messages: setFollowup().allow(),
        }))
}

function getNewAttachment() {
    return Joi.object().keys({
        uuid: Joi.string().required(),
        name: Joi.string().required(),
        size: Joi.number().required(),
        contentType: Joi.string().allow(['', null]),
        localUrl: Joi.string().required()
    });
}

function setFollowup() {
    return Joi.object().keys({
        text: Joi.string().allow(['', null]),
        uuid: Joi.string()
    });
}

function getNewTicketFormat() {
    return Joi.object().required().keys({
        priority: getValidPriority().required(),
        impact: getValidImpact().required(),
        urgency: getValidUrgency().required(),
        associatedItems: Joi.array().items(getValidAssociatedItem().required()),
        description: Joi.string().required(),
        subject: Joi.string().required(),
        dealerName: Joi.string().allow(['',null]),
        dealerNumber: Joi.string().allow(['',null]),
        status: Joi.string(),
        uuid: Joi.string(),
        email: Joi.string().allow(['',null]),
        telNo: Joi.string().allow(['',null]),
        country: Joi.string().allow(['',null]),
        group: Joi.string().allow(['',null]),
        category: Joi.string().allow('1').required(),
        itilCategory: Joi.string().allow('1').required()
    });
}

function getUpdateTicketFormat() {
    return Joi.object().keys({
        status: getValidStatus(),
        assignedTo: Joi.string(),
        uuid: Joi.string().allow(),
        country: Joi.string().allow(['', null]),
        group: Joi.string().allow(['', null]),
        sfxNumber: Joi.number(),
        pfxNumber: Joi.number(),
        priority: getValidPriority(),
        impact: getValidImpact(),
        urgency: getValidUrgency(),
        associatedItems: Joi.array().items(getValidAssociatedItem()),
        description: Joi.string().allow(['', null]),
        subject: Joi.string().allow(['', null]),
        category: Joi.string().allow('1'),
        itilCategory: Joi.string().allow('1'),
    });
}

function getValidAssociatedItem() {
    return Joi.object().keys({
        id: Joi.number(),
        name: Joi.string()
    })
}

function getValidPriority() {
    return Joi.string()
        .valid('1', '2', '3', '4', '5','6');
}

function getValidImpact() {
    return Joi.string()
        .valid('1', '2', '3', '4', '5');
}

function getValidUrgency() {
    return Joi.string()
        .valid('1', '2', '3', '4', '5');
}

function getValidStatus() {
    return Joi.string()
        .valid(
            RBOX_TICKET_STATUS_NEW,
            RBOX_TICKET_STATUS_READ_BY_EDITOR,
            RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
            RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
            RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
            RBOX_TICKET_STATUS_CLOSING,
            RBOX_TICKET_STATUS_SOLVING,
            RBOX_TICKET_STATUS_CLOSED,
            RBOX_TICKET_STATUS_SOLVED
        );
}
