import React from 'react';
import { FormattedMessage } from 'react-intl';
import buttonMessages from '../../intl/common/buttonMessages';
import { PrimaryButton } from './Button';
import Select from 'react-select';
import { Label } from './StyledComponents';

export const StatusSelector = (props) => {
    const {label, options, placeholder, handleChange, handleAllClick, statuses} = props;
    return (
        <div>
            <div className="row my-2">
                <div className="col">
                    <Label>
                        <FormattedMessage {...label}/>
                    </Label>
                    <PrimaryButton type="button" className="btn btn-sm ml-3"
                                   onClick={handleAllClick}>
                        <FormattedMessage {...buttonMessages.ALL}/>
                    </PrimaryButton>
                </div>
            </div>
            <Select isMulti isSearchable
                    value={statuses}
                    options={options}
                    placeholder={placeholder}
                    onChange={handleChange}/>
        </div>
    )
};

export const ActionSelector = (props) => {
    const {label, options, placeholder, handleChange, handleAllClick, actions} = props;
    return (
        <div>
            <div className="row my-2">
                <div className="col">
                    <Label>
                        <FormattedMessage {...label}/>
                    </Label>
                    <PrimaryButton type="button" className="btn btn-sm ml-3"
                                   onClick={handleAllClick}>
                        <FormattedMessage {...buttonMessages.ALL}/>
                    </PrimaryButton>
                </div>
            </div>
            <Select isMulti isSearchable
                    value={actions}
                    options={options}
                    placeholder={placeholder}
                    onChange={handleChange}/>
        </div>
    )
};

