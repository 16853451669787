import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RBOX_TICKET_FORM, RBOX_TICKET_FORM_EDIT } from '../../../../constants/formConstants';
import TicketForm from './forms/TicketForm';

import { initLoadedTicket } from '../Utils';
import { editReqFields } from '../../../../constants/requiredFields';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import { FormattedMessage } from 'react-intl';
import formMessages from '../../../../intl/rbox/formMessages';
import { handleItemSearch } from '../../../../utils/itemUtils';
import { moduleRoles } from '../../../../utils/roles';
import { handleFetchItemsError } from '../../../../actions/actionItems';
import { countryFlow, editTicketRights } from '../../../../constants/ticketRights';

class EditLoadTicketDataPage extends Component {

    handleLoadRecords = (values, callback) => {
        handleItemSearch(values, callback, this.props.handleFetchItemsError);
    }

    render() {
        const {
            ticketData,
            userRoles,
            handleSubmit,
            formType,
        } = this.props;

        const initializationValue = initLoadedTicket(ticketData);
        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TITLE}/>{' - '}{ticketData.ticket.ticketNumber}
                    </>
                }/>
                <div className="container-fluid">
                    <div className="col-12">
                        {(formType === RBOX_TICKET_FORM || formType === RBOX_TICKET_FORM_EDIT) &&
                        <TicketForm
                            form={formType}
                            viewType={'EDIT'}
                            initialValues={initializationValue}
                            userRights={editTicketRights[countryFlow(ticketData.ticket.country)][moduleRoles.isTeamEditor(userRoles) ? moduleRoles.getEditorTeam(userRoles)[0].group : (moduleRoles.isEditor(userRoles) ? 'EDITOR' : 'DEALER')][ticketData.ticket.status]}
                            enableReinitialize={true}
                            reqFields={editReqFields[moduleRoles.isEditor(userRoles) ? 'EDITOR' : 'DEALER'][formType]}
                            handleLoadItems={this.handleLoadRecords}
                            availableStatus={ticketData.ticket.availableStatus}
                            availableTeam={ticketData.ticket.availableEditorTeams}
                            handleSubmit={handleSubmit}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

EditLoadTicketDataPage.propTypes = {
    ticketData: PropTypes.object.isRequired,
    userRoles: PropTypes.object.isRequired,
    location: PropTypes.object,
    handleFetchItemsError: PropTypes.func,
};

const mapStateToProps = (state) => ({
    userRoles: state.profile.userDetail.roles,
    ticketData: state.ticket,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    handleFetchItemsError
})(EditLoadTicketDataPage)), ['RBOX_DEALER', 'RBOX_EDITOR', 'RBOX_EDITOR_CREATOR']);
