import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchTicket, resetTicket, updateTicket } from '../../../../actions/rbox/actionTicket';
import Loader from '../../../Loader';
import {
    FORM_BUTTON_BACK,
    FORM_BUTTON_READ_BY_EDITOR,
    RBOX_TICKET_FORM_EDIT
} from '../../../../constants/formConstants';

import { transformUpdateTicketForSave } from '../Utils';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import UploadingAttachments from './UploadingAttachments';
import { lockTicket, resetRedirectAfterLockTicket, unlockTicket } from '../../../../actions/rbox/actionLockTicket';
import EditLoadTicketDataPage from './EditLoadTicketDataPage';

class EditTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketSubmittedAndShouldRedirect: false
        };
    }

    componentWillUnmount() {
        const {uuid, redirect} = this.props.lockTicketData;
        if (!redirect) {
            this.props.unlockTicket(uuid);
        }
        this.props.resetRedirectAfterLockTicket();
    }

    componentDidMount() {
        this.props.resetTicket();
        this.props.lockTicket(this.props.match.params.ticketUuid);

        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchTicket(this.props.match.params.ticketUuid);
        }
    }

    handleSubmit = (values) => {
        const { submitBy } = values;

        const dataForSend = transformUpdateTicketForSave(values);
        this.props.updateTicket(dataForSend);
        if (!([FORM_BUTTON_BACK, FORM_BUTTON_READ_BY_EDITOR].includes(submitBy))) {
            this.setState({ ticketSubmittedAndShouldRedirect: true });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.ticketSubmittedAndShouldRedirect && !this.state.ticketSubmittedAndShouldRedirect
            && prevProps.ticketData.isTicketCreating && !this.props.ticketData.isTicketCreating
            && this.props.ticketData.isTicketLoading) {
            this.props.lockTicket(this.props.match.params.ticketUuid);
        }
    }

    render() {
        const {
            ticketData,
            attachmentsData,
            lockTicketData,
        } = this.props;

        if (lockTicketData.redirect) {
            return <Redirect to={`/ticket/view/${lockTicketData.uuid}`}/>;
        }

        let formType = false;
        if (get(ticketData, 'ticket.status', { default: false })) {
            formType = RBOX_TICKET_FORM_EDIT;
        }

        if (get(ticketData, 'ticket.category') === "2") {
            return <Redirect to={`/ticket/view/${lockTicketData.uuid}`}/>;
        }

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingAttachments attachments={attachmentsData.attachmentsForUpload}
                                         attachmentsUrl={ticketData.newAttachments}
                                         correlationId={ticketData.correlationId}
                                         isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                         formType={formType}/>
        }

        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !formType
            || lockTicketData.isTicketLocking
        ) {
            return <Loader/>;
        }

        if ((this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', { default: false }))) {
            return <Redirect to={{
                pathname: `/ticket/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        return (
            <EditLoadTicketDataPage formType={formType} handleSubmit={this.handleSubmit}/>
        )
    }
}

EditTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    resetTicket: PropTypes.func.isRequired,
    fetchTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateTicket: PropTypes.func.isRequired,
    ticketData: PropTypes.object.isRequired,
    location: PropTypes.object,
    attachmentsData: PropTypes.object.isRequired,
    resetRedirectAfterLockTicket: PropTypes.func.isRequired,
    lockTicket: PropTypes.func.isRequired,
    unlockTicket: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    lockTicketData: state.lockTicketCheck,
    ticketData: state.ticket,
    userDetail: state.profile.userDetail,
    attachmentsData: state.rboxAttachment,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchTicket,
    updateTicket,
    resetTicket,
    resetRedirectAfterLockTicket,
    lockTicket,
    unlockTicket
})(EditTicketPage)), ['RBOX_DEALER', 'RBOX_EDITOR', 'RBOX_EDITOR_CREATOR']);
