import { rboxWebsocketCommands } from '../../constants/rboxWebsocketCommands';

export const RBOX_UPDATE_USER_LIST_PREFERENCE = 'RBOX_UPDATE_USER_LIST_PREFERENCE';
export const RBOX_FETCH_PREFERENCES_PENDING = 'RBOX_FETCH_PREFERENCES_PENDING';
export const RBOX_UPDATE_USER_NOTIFICATION_LIST_PREFERENCE = 'RBOX_UPDATE_USER_NOTIFICATION_LIST_PREFERENCE';
export const RBOX_FETCH_PREFERENCES = 'RBOX_FETCH_PREFERENCES';
export const RBOX_USER_NOTIFICATION_PREFERENCE_FETCH = 'RBOX_USER_NOTIFICATION_PREFERENCE_FETCH';
export const RBOX_USER_NOTIFICATION_PREFERENCE_UPDATE = 'RBOX_USER_NOTIFICATION_PREFERENCE_UPDATE';

export const getPreferences = (ipn) => dispatch => {
    return dispatch({
        type: RBOX_FETCH_PREFERENCES_PENDING,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_PREFERENCE_GET,
        payload: { ipn }
    });
};

export const updateUserListPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: RBOX_UPDATE_USER_LIST_PREFERENCE,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_USER_LIST_PREFERENCE_SET,
        payload: { preferences: { ipn, hiddenColumns: preferences } }
    });
};

export const updateUserNotificationListPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: RBOX_UPDATE_USER_NOTIFICATION_LIST_PREFERENCE,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_USER_NOTIFICATION_LIST_PREFERENCE_SET,
        payload: { preferences: { ipn, hiddenColumns: preferences } }
    });
};

export const fetchRboxUserNotificationPreferences = () => dispatch => {
    return dispatch({
        type: RBOX_USER_NOTIFICATION_PREFERENCE_FETCH,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_USER_NOTIFICATION_PREFERENCE_GET,
    });
};

export const updateRboxUserNotificationPreference = (preferences) => dispatch => {
    return dispatch({
        type: RBOX_USER_NOTIFICATION_PREFERENCE_UPDATE,
        rboxWebsocket: true,
        command: rboxWebsocketCommands.RBOX_USER_NOTIFICATION_PREFERENCE_SET,
        payload: {preferences}
    });
};

