import { connect } from 'react-redux';
import TicketTable from '../shared/TicketTable';
import { fetchHistoryPageData } from '../../../../actions/rbox/actionHistoryPage';

const mapStateToProps = (state) => ({
    isLoading: state.historyTicket.isTableLoading,
    data: state.historyTicket.listData,
    isNextPage: state.historyTicket.isNextPage,
    isNextFromPivot: state.historyTicket.isNextFromPivot,
    pages: state.historyTicket.pages,
    userRoles: state.profile.userDetail.roles,
    loggedUser: state.profile.userDetail,
    keyToMatch: 'historyTicket'
});

export default connect(mapStateToProps, {
    fetchData: fetchHistoryPageData,
})(TicketTable);
