import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import get from 'get-value';
import { rboxTicketWebsocketCommands } from '../../constants/rboxTicketWebsocketCommands';

export function* handleTicketError(action) {
    const stateCorrelationId = get(yield select(state => state.ticket), `correlationId`, {default: undefined});
    const {correlationId} = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('/ticket/list'));
    yield put({type: rboxTicketWebsocketCommands.RBOX_TICKET_RESET});
};

function* ticketSaga() {
    yield takeEvery(rboxTicketWebsocketCommands.RBOX_TICKET_UPDATE_ERROR, handleTicketError);
    yield takeEvery(rboxTicketWebsocketCommands.RBOX_TICKET_CREATE_ERROR, handleTicketError);
    yield takeEvery(rboxTicketWebsocketCommands.RBOX_TICKET_SEND_ERROR, handleTicketError);
}

export default ticketSaga;
