import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE_HISTORY: {
        id: 'rbox.history.title',
        defaultMessage: 'Ticket history'
    }
});

export default messages;
