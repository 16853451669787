import React from 'react';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { TextareaFieldWithValidation } from '../../../../../../common/TextareaFieldWithValidation';
import { change, getFormInitialValues } from 'redux-form';
import { connect } from 'react-redux';
import ValidFormattedMessage from '../../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../../intl/rbox/formMessages';
import { ticketConstants } from '../../../../../../../constants/form/formTicketConstants';
import get from 'get-value';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const MessagesSection = props => {
    const { userRights, readOnly, initialValues, reqFields } = props;

    const messages = get(initialValues, 'messages');

    return (
        <>
            {((userRights.canAddNotes && !readOnly) || (messages && (messages.length > 0))) &&
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.NOTE_SECTION}/>
                </h3>
                    {(messages && (messages.length > 0)) &&
                    <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    <div >
                        <div className="border-bottom border-bottom-secondary"
                             style={{ backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0' }}>
                            <label className="col-form-label pl-0 font-weight-bold ml-3">
                                <ValidFormattedMessage message={ticketConstants.FORM_LABEL_NOTES_HISTORY}
                                                       intlMessages={formMessages}/>
                            </label>
                        </div>
                        {messages.slice(0).reverse().map((note, index) =>
                            <div key={index}>
                                <div className="row m-0 align-items-stretch"
                                     style={{ backgroundColor: '#f8f9fa' }}>
                                    <div className='col-sm-12 col-md-6'>
                                        <span
                                            className="font-weight-bold">{note.creatorName + ' (' + note.creatorIpn + ')'}</span><br/>
                                        <FormattedDate value={new Date(note.created)}/> <FormattedTime
                                        value={new Date(note.created)}/>
                                    </div>
                                </div>
                                <div className="row m-0 align-items-stretch">
                                    <div className="col border-bottom py-3" style={{whiteSpace: "pre-wrap"}}>
                                        {note.text}
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                    </StyledDiv>
                    }
                {userRights.canAddNotes && !readOnly && <hr /> }
                {userRights.canAddNotes && !readOnly &&
                    <StyledDiv className="border border-secondary bg-white overflow-hidden">
                        <div className="col-sm-12 px-4 pb-4 pt-2">
                            <div className="form-row">
                                <div className="col-sm-12 pb-2">
                                    <label className="col-form-label pl-0 font-weight-bold">
                                        <ValidFormattedMessage message={ticketConstants.FORM_LABEL_MESSAGE}
                                                               intlMessages={formMessages}/>
                                    </label>
                                    <TextareaFieldWithValidation
                                        field={ticketConstants.FORM_FIELD_MESSAGE}
                                        rows={6}
                                        isRequired={reqFields.includes(ticketConstants.FORM_FIELD_MESSAGE) && !readOnly}
                                    />
                                </div>
                            </div>
                        </div>
                    </StyledDiv>
                }
            </>}
        </>
    )
};

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesSection);
