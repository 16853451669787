import { rboxTicketWebsocketCommands } from '../../constants/rboxTicketWebsocketCommands';

export const RBOX_RESET_REDIRECT = 'RBOX_RESET_REDIRECT';
export const RBOX_SET_LOCK_TICKET = 'RBOX_SET_LOCK_TICKET';

export const lockTicket = uuid => dispatch =>
    dispatch({
        type: RBOX_SET_LOCK_TICKET,
        rboxWebsocket: true,
        command: rboxTicketWebsocketCommands.RBOX_SET_LOCK,
        payload: {
            payload: {
                uuid
            }
        }
    });

export const unlockTicket = uuid => dispatch =>
    dispatch({
        type: rboxTicketWebsocketCommands.RBOX_SET_UNLOCK,
        rboxWebsocket: true,
        command: rboxTicketWebsocketCommands.RBOX_SET_UNLOCK,
        payload: {
            payload: {
                uuid
            }
        }
    });

export const resetRedirectAfterLockTicket = () => dispatch => dispatch({ type: RBOX_RESET_REDIRECT });
