import { Log, UserManager } from 'oidc-client';
import { HOME_PATH } from '../routes/paths';
import { initAxios } from '../api/rest';

export const REFERRER_LOCAL_STORAGE = 'dog.rbox.referrer';
export const REDIR_RATCHET_LOCAL_STORAGE = 'dog.rbox.redirection.ratchet';
export const REDIR_TIME_SESSION_STORAGE = 'dog.rbox.redirection.time';

const userManagerSettings = () => {
    return {
        client_id: process.env.REACT_APP_IDP_CLIENT_ID,
        redirect_uri: `${window.location.origin}/`,
        // silent_redirect_uri: `${window.location.origin}/silent-renewal.html`,
        automaticSilentRenew: false,
        monitorSession: false,
        authority: process.env.REACT_APP_IDP_AUTHORITY,
        response_type: 'code',
        scope: window.location.hostname === 'dog-rbox.dcs2.renault.com' ? process.env.REACT_APP_IDP_SCOPE_DCS : process.env.REACT_APP_IDP_SCOPE_EDH,
        acr_values: window.location.hostname === 'dog-rbox.dcs2.renault.com' ? 'secure/name/x509/uri/RNETandTWIN' : 'secure/name/x509/uri',
        metadata: {
            issuer: process.env.REACT_APP_IDP_ISSUER,
            authorization_endpoint: process.env.REACT_APP_IDP_AUTHORIZATION_ENDPOINT,
            token_endpoint: process.env.REACT_APP_IDP_TOKEN_ENDPOINT,
            userinfo_endpoint: process.env.REACT_APP_IDP_USERINFO_ENDPOINT,
            jwks_uri: process.env.REACT_APP_IDP_JWKS_URI,
            registration_endpoint: process.env.REACT_APP_IDP_REGISTRATION_ENDPOINT,
        },
    }
};

const singletonUserManager = ( function() {
    Log.logger = console;
    if (process.env.NODE_ENV === 'development') {
        Log.level = Log.DEBUG;
    } else {
        Log.level = Log.WARN;
    }
    const mgr = new UserManager(userManagerSettings());
    mgr.events.addUserLoaded(function(user){
        initAxios(user);
    });

    return mgr;
}());

export const authenticateAndRedirect = async () => {
    await checkRedirectionLoop();
    localStorage.setItem(REFERRER_LOCAL_STORAGE, window.location.pathname + window.location.search);
    await createUserManager().clearStaleState();
    return await createUserManager().signinRedirect();
};

const checkRedirectionLoop = async () => {
    if (sessionStorage.hasOwnProperty(REDIR_TIME_SESSION_STORAGE)) {
        let value = JSON.parse(sessionStorage.getItem(REDIR_TIME_SESSION_STORAGE));
        if(value.timestamp + 900000 < new Date().getTime()){
            sessionStorage.setItem(REDIR_TIME_SESSION_STORAGE, JSON.stringify({timestamp: new Date().getTime(), count: 0}))
        } else if ( value.count < 3) {
            sessionStorage.setItem(REDIR_TIME_SESSION_STORAGE, JSON.stringify({timestamp: value.timestamp, count: value.count + 1}))
        } else {
            alert("Too many IDP requests, please wait a minute and try again. If the problem still persists, contact your support.");
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    } else {
        sessionStorage.setItem(REDIR_TIME_SESSION_STORAGE, JSON.stringify({timestamp: new Date().getTime(), count: 0}))
    }
};

export const treatEmptyIdpToken = async () => {
    const redirectionRatchet = sessionStorage.getItem(REDIR_RATCHET_LOCAL_STORAGE);
    if(!redirectionRatchet){
        console.log("Invalid token returned from IDP, reauthenticate...");
        sessionStorage.setItem(REDIR_RATCHET_LOCAL_STORAGE, true);
        await createUserManager().removeUser();
        return authenticateAndRedirect();
    } else {
        alert("Incorrect IDP token received. Please contact your support for further investigation.");
    }
};

export const processAuthenticationRedirect = async () => {
    const user = await createUserManager().signinRedirectCallback();
    const lastVisitedPath = localStorage.getItem(REFERRER_LOCAL_STORAGE) || HOME_PATH;
    return { user, lastVisitedPath };
};

export const getAuthenticatedUser = async () => await createUserManager().getUser();

const createUserManager = () => {
    return singletonUserManager;
};
